import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { validatePassword } from 'src/app/utils/validate-password';

@Component({
  selector: 'app-user-reset',
  templateUrl: './user-reset.component.html',
  styleUrls: []
})
export class UserResetComponent implements OnInit {
  token: string;
  pw: string;
  pwRepeat: string;
  state = 'unsent';
  error = false;
  loading = false;
  errors: string[] | undefined;

  constructor(
    private route: ActivatedRoute, private userService: UserService, private router: Router
    ) { }

  ngOnInit(): void {
    console.log(this);
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
    });
  }

  updatePassword(): void {
    this.loading = true;
    this.userService.updatePasswordByToken(this.pw, this.token).subscribe(res => {
      this.state = 'sent';
      this.error = false;
      this.loading = false;
      setTimeout(() => {
        this.router.navigate([`login`]);
      }, 1000);
    }, error => {
      this.state = 'unsent';
      this.error = true;
      this.loading = false;
    });
  }

  passwordValid(): boolean {
    const validate = validatePassword(this.pw, this.pwRepeat);
    this.errors = validate.errors;
    return validate.valid;
  }
}
