import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { AssignmentChildrenStatus } from 'src/app/models/status/status.enum';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { BrokerTenderService } from 'src/app/services/broker-tender.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PermissionService } from 'src/app/services/permission.service';
import { SupplierTenderService } from 'src/app/services/supplier-tender.service';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { ErrorModalComponent } from '../../../shared/error-modal/error-modal/error-modal.component';

@Component({
  selector: 'app-supplier-tender-list',
  templateUrl: './supplier-tender-list.component.html',

  styles: [
  ]
})

export class SupplierTenderListComponent implements OnInit {
  public organizationId: any;
  user: UserAuthenticateResponse;
  isBrokerUser: boolean;
  customers: any = [];
  tenders: [] = [];
  statusRules: any[];
  selectedOrganizationID: number;
  updateStatusDto: { assignmentChildTypeName: any; assignmentChildID: any; currentStatus: any; newStatusID: number; };

  constructor(
    private supplierTenderService: SupplierTenderService,
    private brokerTenderService: BrokerTenderService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private auth: AuthService,
    private permissionService: PermissionService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUserFromStorage();

    const storedOrganizationID = localStorage.getItem('selectedCustomerOrganizationID');
    if (storedOrganizationID) {
      this.selectedOrganizationID = +storedOrganizationID;
    } else {
      this.selectedOrganizationID = 0;
    }

    if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
      this.isBrokerUser = true;
    }

    this.organizationId = this.route.snapshot.paramMap.get("organizationID");
    if (this.isBrokerUser) {
      // this.getCustomers();
      this.organizationService.getOrganizationsByType(OrganizationType.customer).subscribe(
        (response: any) => {
          this.customers = response.organizations;
          this.customers = sortAlphabetically(this.customers, 'organizationName');
          this.selectedOrganizationID = this.selectedOrganizationID === 0 ? this.customers[0].organizationID : this.selectedOrganizationID;
          this.getTenders();
        },
        (_error) => {
        }
      );
    }
    else if(Number(this.user.organizationTypeID) === OrganizationType.supplier)
    {
      this.getTenders();
    }
  }

  getTenders() {
    
    if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
    this.supplierTenderService.getSupplierTenders(this.selectedOrganizationID).subscribe((res) => {
      let temp: [] = res.supplierTenders;
      temp.forEach(element => {
        this.tenders.push(element)
      });
    })
  }
  else if (Number(this.user.organizationTypeID) === OrganizationType.supplier){
    this.supplierTenderService.supplierTenderListBySupplier(parseInt(this.user.organizationID,10)|| 0).subscribe((res) => {
      let temp: [] = res.supplierTenders;
      temp.forEach(element => {
        this.tenders.push(element)
      });
    })
  }
}

  onChange() {
    localStorage.setItem('selectedCustomerOrganizationID', this.selectedOrganizationID.toString());
    this.getTenders();
    this.tenders=[];
  }

}