<div *ngIf="processing==false">
  
  <!-- <li *ngFor="let user of recipients">
    <p>{{user.email}}</p> -->

<p-card>
   <div *ngIf="eventID === 4">
      <div class="p-fluid p-grid">
        <div class="p-field p-col-6">
          <div class="p-field">
            <label>
              Region
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Ange vilken region annonsen ska tillhöra"></i>
              </span>
            </label>
          </div>
          <select [(ngModel)]="selectedRegionID" class="p-inputtext" style="appearance: auto">
            <option></option>
            <option [ngValue]=2>Väst</option>
            <option [ngValue]=3>Öst</option>
            <option [ngValue]=4>Mitt</option>
            <option [ngValue]=5>Norr</option>
            <option [ngValue]=6>Syd</option>
            <option [ngValue]=1>Övrigt</option>
          </select>
        </div>
      </div>
    </div>

   <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>

    <!-- <div class="p-fluid p-grid">
      <div class="p-field p-col-6">
      <label for="recipients">Mottagare
      </label>
      <input id="recipients" formControlName="recipients" type="text" [disableControl]="processing"
          pInputText>
      </div>
  </div> -->

    <div class="p-fluid p-grid">
      <div class="p-field p-col-6">
        <div class="p-field">
          <label>Ämne</label>
        </div>
        <input type="text" id="notificationSubject" name="notificationSubject" formControlName="notificationSubject" pInputText>
      </div>
    </div>

    <div *ngIf="enterReceivers" class="p-fluid">
      <div class="p-inputgroup p-col-4">
        <input type="text" pInputText placeholder="Ny epostaddress"
            class="lg-text-input" id="addItemInput" [(ngModel)]="newItem"
        />
        <button pButton class="btn-primary" (click)="addItem(newItem)">
            Lägg till
        </button>
    </div>
    <br><br>
    <!-- <app-receiver-item *ngFor="let receiver of receivers" [receiver]="receiver"
    (onDeleteReceiver)="deleteReceiver(receiver)"></app-receiver-item> -->
    
    <!-- <div *ngIf="receivers.length > 0" class="p-grid p-mt-4">
        <div class="p-col-6">
        <button pButton type="button"
            class="p-button p-button-raised p-text-uppercase" label="Skicka epost" (click)="postToEmails()"></button>
        </div>
    </div> -->
    </div>

      <div *ngIf="required" class="p-fluid">
        <div class="p-field p-col-6">
          <div class="p-field">
            <label>Ange förslag om datum och tid för intervju (ersätter information_text i mallen)</label>
          </div>
          <div *ngIf="(processing) || (interviewtime == null) || (interviewtime.value == '')">
            <p style="color:red">Ange tid för intervju</p>
          </div>
            <input type="text" id="interviewtime" name="interviewtime" formControlName="interviewtime" pInputText>
        </div>
      </div>
    <div class="p-field">

      <label>
        Beskrivning
      <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
        pTooltip="placeholder text"></i>
      </span>
    </label>
    <p-editor formControlName="notificationContent" [style]="{'height':'320px'}">
      <!-- <p-header>
        <span class="ql-formats">
          <button class="ql-bold" aria-label="Bold"></button>
          <button class="ql-italic" aria-label="Italic"></button>
          <button class="ql-underline" aria-label="Underline"></button>
          <button class="ql-strike" aria-label="Strike"></button>
        </span>
        <span class="ql-formats">
          <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
            <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
            <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)"></option>
          </select>
          <span class="ql-format-separator"></span>
          <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)">
            <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
            <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)"></option>
          </select>
        </span>
        <span class="ql-formats">
          <button aria-label="Link" class="ql-link"></button>
          <button aria-label="Image" class="ql-image"></button>
        </span>
      </p-header> -->
    </p-editor>
    </div>
    <div class="p-grid p-mt-4">
      <div class="p-col-6">
        <button pButton type="button"  
        class="p-button p-button-raised p-text-uppercase" label="Avbryt" (click)="onCancel()"></button>
      </div>
      <div *ngIf="required" class="p-col-6">
        <button pButton type="submit" iconPos="right" style="float: right;"
          class="p-button p-button-raised p-text-uppercase" label="Skicka epost" [disabled]="(processing) || (interviewtime == null) || (interviewtime.value == '')"></button>
      </div>
      <div *ngIf="!required" class="p-col-6">
        <button pButton type="submit"  iconPos="right" style="float: right;"
          class="p-button p-button-raised p-text-uppercase" label="Skicka epost" [disabled]="processing"></button>
      </div>
    </div>
    </form>
  </p-card>
</div>
