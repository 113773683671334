<div *ngIf="isBrokerUser">
    <p-dropdown 
    [options]="customers"
    optionLabel="organizationName"
    [(ngModel)]="selectedOrganizationID"
    (onChange)="onChange()"
    optionValue="organizationID"
    placeholder="Välj en organisation">

    </p-dropdown>
</div>
<p-contextMenu #cm [model]="items"></p-contextMenu>
<p-card header="Arkiverade avtal ">
    <p-table [value]="contracts" sortMode="multiple" (onLazyLoad)="loadContracts($event)" [paginator]="true"
        [rows]="this.pageSize" [totalRecords]="pageTotal" [loading]="loading" [(contextMenuSelection)]="selectedContract"
        [contextMenu]="cm" dataKey="contractID"
        [globalFilterFields]="['contractName', 'customerContractReference', 'supplierContractReference','contractDate', 'endDate', 'expiration']">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 18%" pSortableColumn="contractName">Namn <p-sortIcon field="contractName"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="customerContractReference">Kundens referens <p-sortIcon field="customerContractReference"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="supplierContractReference">Leverantörens referens <p-sortIcon field="supplierContractReference"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="contractDate">Avtalsdatum <p-sortIcon field="contractDate"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="endDate">Slutdatum <p-sortIcon field="endDate"rowspan="2"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="endDate">Avslutas <p-sortIcon field="expiration"rowspan="2"></p-sortIcon>
                </th>
                <th style="width: 8rem" class="p-text-right"></th>
                <th></th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="contractName">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="customerContractReference">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="supplierContractReference">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="contractDate">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="endDate"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="expiration"></p-columnFilter>
                </th>
                <th>

                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contract>
             <tr [pContextMenuRow]="contract" (click)="openContractPage(contract)" style="cursor: pointer;">
                <td>{{contract.contractName}}</td>
                <td>{{contract.customerContractReference}}</td>
                <td>{{contract.supplierContractReference}}</td>
                <td>{{contract.contractDate  | date: 'dd/MM/yyyy'}}</td>
                <td>{{contract.endDate | date: 'dd/MM/yyyy'}}</td>
                <td [ngStyle]="{'background-color':colourBackground(contract.contractExpiresInDays)}"><b [ngStyle]="{'color':colourText(contract.contractExpiresInDays)}">Avtalet löper ut om {{contract.contractExpiresInDays}} dagar</b>
                </td>
                <td>
                    <button
                        pButton
                        label="Återskapa"
                      
                        (click)="onRestoreContractClicked($event, contract)"
                    ></button>
                </td>
                
                    
                
            </tr>
        </ng-template>
    </p-table>
</p-card>
