import { CreateUserToAssignmentDTO } from './../../../models/assignment/assignment.model';
import { User } from 'src/app/models/user/user.model';
import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AssignmentService } from 'src/app/services/assignment.service';
import { ErrorModalComponent } from 'src/app/components/shared/error-modal/error-modal/error-modal.component';
import { OrganizationService } from 'src/app/services/organization.service';
import { deepCloneObject } from 'src/app/utils/clone';


@Component({
  selector: 'app-user-to-assignment',
  templateUrl: './user-to-assignment.component.html',
  styles: [
  ]
})
export class UserToAssignmentComponent implements OnInit {
  dialogRef: DynamicDialogRef;
  usersToAssignment: CreateUserToAssignmentDTO[] = [];
  users: User[] = [];
  picked: User[] = [];
  initialPicked: User[] = [];
  totalRecords: number = 0;
  pageLinks: number = 0;
  isCustomer: boolean;
  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef,
     private assignmentService: AssignmentService,
     private orgService: OrganizationService,
     private dialogService: DialogService) { 
  }

  ngOnInit(): void {
    this.initialPicked = deepCloneObject(this.config.data.picked)

    this.isCustomer = this.config.data.isCustomer;

    this.users = this.config.data.users;

    if(this.config.data.assignmentId)
      this.assignmentService.getUsers(this.config.data.assignmentId).subscribe(res => {
        this.picked = res.userToAssignmentUsers;
        console.log(this.picked);
      })
    else{
      this.picked = this.config.data.picked;
    }

    this.filterAddedUsers();
    
    this.totalRecords=this.users.length;
  
  }

  filterAddedUsers() {
    this.users.forEach((element, i)  => {
      const condition = this.initialPicked.find(x => Number(x.userID) === element.userID);
        if (condition){
        this.users = this.users.filter(x => x.userID !== Number(condition.userID));
      }
    });
  }

  setAddedUsersAsResponsible() {
    
  }

  checkIfUserIsIncluded(user: User){
    const remainingArr = this.usersToAssignment.filter(data => data.userID != user.userID);
    if(remainingArr==this.usersToAssignment){
      return true;
    }
    return false;
  }

  addUser(user: User): void {
    this.users = this.users.filter(data => data.userID != user.userID);
    user.isResponsible = false;
    this.picked.push(user);
  }

  removeUser(user: User): void {
    this.picked = this.picked.filter(data => data.userID != user.userID);
    this.users.push(user);
  }

  errorCheck(): string{
    var responsibleBroker = 0;
    var responsibleCustomer = 0;

    this.picked.forEach(user => {
      if(user.organizationTypeID == 1 && user.isResponsible){
        responsibleBroker++;
      }
      else if(user.organizationTypeID == 2 && user.isResponsible){
        responsibleCustomer++;
      }
    })

    if(this.isCustomer){
      if(responsibleCustomer > 0){
        return '';
      }
      else{
        return 'Du behöver välja minst en ansvarig användare hos kund';
      }
    }
    else{
      if(responsibleBroker > 0 && responsibleCustomer > 0){
        return '';
      }
      else{
        return 'Det behöver välja minst en mäklare och en kund som kontaktpersoner';
      }
    }
    
  }

  accept() {
    if(this.errorCheck().length > 0){
      this.dialogService.open(ErrorModalComponent,
        {
        header: "Något gick fel",
        data: {
          message: this.errorCheck()
        }
      })
    }
    else{
      this.usersToAssignment.length = 0;
    
      for(var i = 0; i < this.picked.length; i++){
        if(this.picked[i].isResponsible !== undefined){
          this.usersToAssignment.push({'userID': this.picked[i].userID, 'isResponsible': this.picked[i].isResponsible});
        }else{
          this.usersToAssignment.push({'userID': this.picked[i].userID, 'isResponsible': false});
        }
      }
      this.ref.close(this.usersToAssignment);
    }


  }

  setAsResponsible(userUpdated: User, responsible: boolean){
    const result = this.picked.find((user) => {
      return user.userID === userUpdated.userID;
    });

    if (result !== undefined) {
      result.isResponsible = responsible;
    }
  }

  deny() {
    this.ref.close(this.initialPicked);
  }
}
