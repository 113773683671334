<p-card [style]="{'margin-bottom': '25px'}" header="Konsult Uppgifter">
  <div *ngIf="!processing">
    <div class="card-section" style="display: flex; justify-content: start; font-size: 16px;">
        <div style="width: 400px;">
          <p>Namn: <b>{{ consultant.firstName + " " + consultant.lastName }}</b></p>
          <p>E-mail: <b>{{ consultant.email }} </b></p>
          <p>Telefonnummer: <b>{{ consultant.phoneNumber }}</b></p>
          <!-- <p>SupplierID: <b>{{ consultant.supplierID }} </b></p> -->
          <!-- <app-create-consultant [supplierId]="consultant.supplierID"></app-create-consultant> -->
        </div>
      </div>
    <button pButton label="Redigera" (click)="openEditConsultant()"></button>
  </div>
  <div *ngIf="processing">
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>
</p-card>