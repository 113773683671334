import { AssignmentTemplatesComponent } from './components/assignment-templates/assignment-templates/assignment-templates.component';
import { EmailModalComponent } from './components/email-modal/email-modal/email-modal.component';
import { ResumeViewComponent } from './components/resume-view/resume-view/resume-view.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./components/auth/login/login.component";
import { OrganizationComponent } from "./components/organization/organization/organization.component";
import { AuthGuard } from "./guards/auth.guard";
import { OrganizationsComponent } from "./components/organization/organizations/organizations.component";
import { UserComponent } from "./components/user/user/user.component";
import { RolesComponent } from "./components/role/roles/roles.component";
import { RoleComponent } from "./components/role/role/role.component";
import { DashboardComponent } from "./components/dashboard/dashboard/dashboard.component";
import { SystemComponent } from "./components/system/system/system.component";
import { CheckOrgNumberComponent } from "./components/user/apply-user/apply-check-org-number.component";
import { ApplyUserSupplierComponent } from "./components/user/apply-user/apply-user-supplier.component";
import { UserProfileComponent } from "./components/user/user-profile/user-profile.component";
import { RegisterUserComponent } from "./components/user/register-user/register-user.component";
import { CreateRoleComponent } from "./components/role/create-role/create-role.component";
import { CreateUserComponent } from "./components/user/create-user/create-user.component";
import { EditRoleComponent } from "./components/role/edit-role/edit-role.component";
import { CreateOrganizationComponent } from './components/organization/create-organization/create-organization.component';
import { OrganizationUsersComponent } from './components/organization/organization-users/organization-users.component';
import { CreateBrokerCallOffComponent } from "./components/assignment-children/broker-call-off/create-broker-call-off/create-broker-call-off.component";
import { CreateCustomerCallOffComponent } from "./components/assignment-children/customer-call-off/create-customer-call-off/create-customer-call-off/create-customer-call-off.component";
import { BrokerCallOffComponent } from './components/assignment-children/broker-call-off/broker-call-off.component';
import { BrokerCallOffListComponent } from './components/assignment-children/broker-call-off/broker-call-off-list/broker-call-off-list.component';
import { TenderBoardComponent } from './components/assignment-board/tender-board/tender-board.component';
import { CreateBrokerTenderComponent } from './components/assignment-children/broker-tender/create-broker-tender/create-broker-tender.component';
import { CreateSupplierTenderComponent } from './components/assignment-children/supplier-tender/create-supplier-tender/create-supplier-tender.component';
import { CreateSupplierTenderRedirectComponent } from './components/assignment-children/supplier-tender/create-supplier-tender-redirect/create-supplier-tender-redirect.component';
import { CreateConsultantComponent } from './components/assignment-children/create-consultant/create-consultant.component';
import { UserToAssignmentComponent } from './components/user-to-assignment/user-to-assignment/user-to-assignment.component';
import { OrganizationTemplateComponent } from './components/organization/organization-templates/organization-template.component';
import { OrganizationTemplateCreateComponent } from './components/organization/organization-templates/organization-template-create.component';
import { ContractListComponent } from './components/contract/contract-list/contract-list.component';
import { CreateContractComponent } from './components/contract/create-contract/create-contract.component';
import { CallOffBoardComponent } from './components/assignment-board/call-off-board/call-off-board.component';
import { ContractComponent } from './components/contract/contract/contract.component';
import { UserResetComponent } from './components/user/user-reset/user-reset.component';
import { ManageResumesComponent } from './components/resume-view/manage-resumes/manage-resumes.component';
import { SharedResumeCreateComponent } from './components/resume-view/shared-resume-create/shared-resume-create.component';
import { SharedResumePageComponent } from './components/resume-view/shared-resume-page/shared-resume-page.component';
import { UnsubscribeComponent } from './components/shared/subscription/unsubscribe.component';
import { UnsubscribeEnterEmailComponent } from './components/shared/subscription/unsubscribe-enter-email.component';
import { BrokerCallOffArchiveListComponent } from './components/assignment-children/broker-call-off/broker-call-off-archive-list/broker-call-off-archive-list.component';
import { TenderArchiveComponent } from './components/assignment-board/tender-archive/tender-archive.component';
import { OrganizationConsultantDetailsComponent } from './components/organization/organization-consultants/organization-consultant-details/organization-consultant-details.component';
import { ContractArchiveComponent } from './components/contract/contract-archive/contract-archive.component';
import { SubscribeComponent } from './components/shared/subscription/subscribe.component';
import { SignContractExtensionComponent } from './components/contract/sign-contract-extension/sign-contract-extension.component';
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service';
import { SupplierTenderListComponent } from './components/assignment-children/supplier-tender/supplier-tender-list/supplier-tender-list.component';

const routes: Routes = [
  { path: 'broker-call-off/:brokerCallOffID', component: BrokerCallOffComponent, canActivate: [AuthGuard] },
  { path: 'broker-call-off-list', component: BrokerCallOffListComponent, canActivate: [AuthGuard] },
  { path: 'broker-tender-board/:brokerCallOffID', component: TenderBoardComponent, canActivate: [AuthGuard] },
  { path: 'supplier-tender-board/:brokerCallOffID', component: TenderBoardComponent, canActivate: [AuthGuard] },
  { path: 'supplier-tender-list/:organizationID', component: SupplierTenderListComponent, canActivate: [AuthGuard] },
  { path: "call-off/archive/:organizationID", component: BrokerCallOffArchiveListComponent, canActivate: [AuthGuard] },
  { path: "call-off/archive", component: BrokerCallOffArchiveListComponent, canActivate: [AuthGuard] },
  { path: 'tender-archive/:organizationID', component: TenderArchiveComponent, canActivate: [AuthGuard] },
  { path: 'call-off-board/:organizationID', component: CallOffBoardComponent, canActivate: [AuthGuard] },
  { path: "call-off/createCallOff", component: CreateBrokerCallOffComponent, canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuardService] },
  { path: "call-off/createCustomerCallOff", component: CreateCustomerCallOffComponent, canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuardService] },
  { path: "call-off/editCallOff/:callOffID", component: CreateBrokerCallOffComponent, canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuardService] },
  { path: "create-contract/:brokerTenderID", component: CreateContractComponent, canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuardService] },
  { path: "create-contract/edit/:contractID", component: CreateContractComponent, canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuardService] },
  { path: "contract-list", component: ContractListComponent, canActivate: [AuthGuard] },
  { path: "contract-archive", component: ContractArchiveComponent, canActivate: [AuthGuard] },
  { path: "contract/:contractID", component: ContractComponent, canActivate: [AuthGuard] },
  { path: "sign-contract-extension", component: SignContractExtensionComponent},
  { path: "login/sign-contract-extension", component: SignContractExtensionComponent},
  { path: "manage/resumes", component: ManageResumesComponent, canActivate: [AuthGuard] },
  { path: "shared-resume-create", component: SharedResumeCreateComponent, canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuardService] },
  { path: "shared-resume-edit/:sharedResumeId", component: SharedResumeCreateComponent, canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuardService] },
  { path: 'organization', component: OrganizationComponent, canActivate: [AuthGuard] },
  { path: 'organization/create', component: CreateOrganizationComponent, canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuardService] },
  { path: 'organization/edit/:organizationId', component: CreateOrganizationComponent, canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuardService] },
  { path: 'organization-template', component: OrganizationTemplateComponent, canActivate: [AuthGuard] },
  { path: 'organization-template-create', component: OrganizationTemplateCreateComponent, canActivate: [AuthGuard] },
  { path: 'organization-template-create/:organizationTemplateId', component: OrganizationTemplateCreateComponent, canActivate: [AuthGuard] },
  { path: 'consultant/create', component: CreateConsultantComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardService] },
  { path: 'organization/:organizationId', component: OrganizationComponent, canActivate: [AuthGuard] },
  { path: 'organizations/users', component: OrganizationUsersComponent, canActivate: [AuthGuard] },
  { path: 'organizations', component: OrganizationsComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'role/create', component: CreateRoleComponent, canActivate: [AuthGuard] },
  { path: 'role/edit/:roleId', component: EditRoleComponent, canActivate: [AuthGuard] },
  { path: 'role/:roleId', component: RoleComponent, canActivate: [AuthGuard] },
  { path: 'roles', component: RolesComponent, canActivate: [AuthGuard] },
  { path: 'broker-tender-create/:supplierID', component: CreateBrokerTenderComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardService] },
  { path: 'supplier-tender-create/:assignmentID/:brokerCallOffID', component: CreateSupplierTenderComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardService] },
  { path: 'system', component: SystemComponent, canActivate: [AuthGuard] },
  { path: 'user/create', component: CreateUserComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardService] },
  { path: 'user/edit/:userId', component: CreateUserComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardService] },
  { path: 'user/profile', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'user/:userId', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'user/profile', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'users', component: OrganizationUsersComponent, canActivate: [AuthGuard] },
  { path: 'email-modal', component: EmailModalComponent, canActivate: [AuthGuard] },
  { path: 'user-to-assignment', component: UserToAssignmentComponent, canActivate: [AuthGuard] },
  { path: 'assignment-templates', component: AssignmentTemplatesComponent, canActivate: [AuthGuard] },
  { path: 'subscription/unsubscribe/:token/:email', component: UnsubscribeComponent, canActivate: [AuthGuard] },
  { path: 'subscription/unsubscribe-enter-email', component: UnsubscribeEnterEmailComponent, canActivate: [AuthGuard] },
  { path: 'subscription/subscribe/:token/:email', component: SubscribeComponent, canActivate: [AuthGuard] },
  { path: 'organization/consultant/:consultantId', component: OrganizationConsultantDetailsComponent },
  { path: 'resume/resume-received/:token/:supplierTenderID', component: ResumeViewComponent },
  { path: 'resume/resume-received', component: ResumeViewComponent },
  { path: 'shared-resume-page/:token/:fileId/:sharedResumeId', component: SharedResumePageComponent },
  { path: 'user/register', component: RegisterUserComponent },
  { path: 'login/subscription/subscribe/:token/:email', component: SubscribeComponent },
  { path: 'login/subscription/unsubscribe/:token/:email', component: UnsubscribeComponent },
  { path: 'login/subscription/unsubscribe-enter-email', component: UnsubscribeEnterEmailComponent},
  { path: 'login/createSupplierTenderRedirect', component: CreateSupplierTenderRedirectComponent },
  { path: 'login/user/check-org-number', component: CheckOrgNumberComponent },
  { path: 'login/user/check-org-number/apply', component: ApplyUserSupplierComponent },
  { path: 'login/reset', component: UserResetComponent },
  { path: 'login/user/reset-password', component: UserResetComponent },
  { path: 'login', component: LoginComponent },

  { path: '', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { } 
