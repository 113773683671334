import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../../../services/auth.service";
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { DialogService } from "primeng/dynamicdialog";
import { MenuItem } from "primeng/api";
import { Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ModalHandler } from 'src/app/components/shared/ModalHandler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-login',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderLoginComponent implements OnInit, OnDestroy {

  currentUser$: Observable<any>;
  items: MenuItem[] = [];
  rightItems: MenuItem[] = [];
  display: boolean = false;
  user: UserAuthenticateResponse;
  subscription: Subscription;
  supportEmail: string = environment.supportEmail;

  constructor(private router: Router, public authService: AuthService, private dialogService: DialogService, private location: Location, private modalHandler: ModalHandler) {
    this.currentUser$ = this.authService.userToken;
  }

  ngOnInit(): void {
    let updated = false;
    this.subscription = this.currentUser$.subscribe(user => {
      this.updateMenuItems();
      this.authService.checkIfUserIsAuthorizedForPage();
      updated = true;
    });
    if (updated == false) {
      this.updateMenuItems();
      this.authService.checkIfUserIsAuthorizedForPage();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateMenuItems(): void {
    this.user = this.authService.getUserFromStorage();
    this.rightItems = [
      {
        label: "Prenumeration",
        icon: "pi pi-fw pi-bell",
        styleClass: "p-menuitem-link p-ripple",
        command: () => this.showSubscriptionModal()
      },
      {
        label: "Support",
        icon: "pi pi-fw pi-comments",
        styleClass: "p-menuitem-link p-ripple",
        command: () => this.showDialog()
      }
    ]

    this.items = [
      {
        styleClass: 'menuitem-logo',
        label: 'Swedish Consulting Group BRM',
        icon: 'menubar-logo'
      }
    ];
  }

  showDialog() {
    this.display = true;
  }

  public showSubscriptionModal() {
    this.modalHandler.showSubscriptionModal();
  }

  onOK() {
    this.display = false;
  }
}