import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { FileService } from 'src/app/services/file-service';
import { ResumeService } from 'src/app/services/resume.service';
import { ResumeEditComponent } from '../../resume-edit/resume-edit.component';
import { AuthService } from 'src/app/services/auth.service';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';

@Component({
  selector: 'app-organization-consultant-resumes',
  templateUrl: './organization-consultant-resumes.component.html',
  styleUrls: ["./organization-consultant-resumes.css"
  ]
})
export class OrganizationConsultantResumesComponent implements OnInit {
  


  resumes: any = [];  
  pdffile: any = null;
  @Input() consultantID: number;
  processing = true;
  user: any;
  organizationTypeNbr: any;
  state: string = '';

  constructor(
    private resumeService: ResumeService,
    private dialogService: DialogService,
    private fileService: FileService,
    private authService: AuthService
    ) { }

  ngOnInit(): void {
    this.getResumes(this.consultantID)

    this.user = this.authService.getUserFromStorage();
    this.organizationTypeNbr = Number(this.user.organizationTypeID);
    if (this.organizationTypeNbr === OrganizationType.broker) {
      this.state = 'broker';
    }
  }

  private getResumes(consultantId: number) {
    this.resumeService.getResumesBySupplierTenderId(consultantId).subscribe((res) => {
      this.resumes = res.consultantToResumes;
      this.processing = false;
    });
  }

  
  openEditResume(resumeID: number) {
    this.dialogService.open(ResumeEditComponent,{header: "",data: {
      id: resumeID,
    }}).onClose.subscribe((res) => {
      if (res === true) {
        this.getResumes(this.consultantID);
      }
    })
  }

  SelectResume(fileID: string) {
    this.fileService.getFileById(fileID)
      .subscribe(res => {
        this.pdffile = res;
      });
  }

}
