<div class="p-col-12 p-lg-12">
    <!-- <div class="p-mt-4"> -->
        <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col">
            <p-card header="Tillgängliga användare">
                <p-table #dt [value]="users" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[5,10,15,20]" [totalRecords]="totalRecords"
                [globalFilterFields]="['id', 'fullName', 'email']">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:5rem">Namn</th>
                            <th style="width:5rem">Epost</th>
                            <th style="width:3rem"></th>
                        </tr>
                        <!-- <tr>
                            <th style="width:5rem">
                                <input style="width:4rem" pInputText type="text" (input)="dt.filter($any($event.target).value, 'fullName', 'contains')">
                            </th>
                            <th style="width:5rem">
                                <input style="width:4rem" pInputText type="text" (input)="dt.filter($any($event.target).value, 'email', 'contains')">
                            </th>
                            <th></th>
                        </tr> -->
                    </ng-template>
                    <ng-template pTemplate="body" let-user>
                        <tr>
                            <td pSortableColumn="fullName">{{user.fullName}}</td>
                            <td pSortableColumn="email">{{user.email}}</td>
                            <td class="p-text-right">
                                <button
                                    pButton
                                    icon="pi pi-arrow-right"
                                    iconPos="right"
                                    pTooltip="Välj som ansvarig"
                                    label="Lägg till"
                                    tooltipPosition="top"
                                    type="button"
                                    (click)="addUser(user)"
                                    ></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                
               
            </p-card>

</div>
<!-- <div class="p-mt-4"> -->
    <div class="p-field p-col">

<p-card header="Ansvariga för avrop">
    
    <p-table #dt [value]="picked" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[5,10,15,20]"
    [globalFilterFields]="['id', 'fullName', 'email']">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:5rem">Namn</th>
                <th style="width:5rem">Epost</th>
                <th style="width:3rem">Kontaktperson</th>
                <th style="width:3rem"></th>
            </tr>

        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td pSortableColumn="fullName">{{user.fullName}}</td>
                <td pSortableColumn="email">{{user.email}}</td>
                <td>

                    <div class="field-checkbox" *ngIf="(user.isResponsible==true) && (user.isResponsible!=null)">
                        <input id="activated" type="checkbox" [checked]="true" (click)="setAsResponsible(user, false)">
                        <label for="activated"></label>
                    </div>
                    <div class="field-checkbox" *ngIf="(user.isResponsible==false) || (user.isResponsible==null)">
                        <input id="activated" type="checkbox" [checked]="false" (click)="setAsResponsible(user, true)">
                        <label for="activated"></label>
                    </div>
                </td>
                <td class="p-text-right">
                    <button
                        pButton
                        pTooltip="Ta bort från ansvariga"
                        label="Ta bort"
                        tooltipPosition="top"
                        type="button"
                        (click)="removeUser(user)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>
</div>
</div>
<div class="p-grid p-mt-4">
    <div class="p-col-6">
        <!-- Ändra avbryt till röd -->
        <button pButton type="button" class="p-button p-button-raised p-text-uppercase" (click)="deny()" label="Avbryt"></button> 
    </div>
    <div class="p-col-6">
        <button pButton type="button" class="p-button p-button-raised p-text-uppercase" (click)="accept()" label="OK"></button>
    </div>
  </div>
</div>