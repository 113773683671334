import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";
import { User } from "../models/user/user.model";
import { UserList } from "../models/user/user-list.model";
import { CreateUser } from "../models/user/create-user.model";
import { ApplyUserAndOrganization } from "../models/user/apply-user-and-organization.model";
import { UpdateUser } from "../models/user/update-user.model";
import { RegisterUser } from "../models/user/register-user.model";
import { catchError, retry } from "rxjs/operators";
import { Observable } from "rxjs";
import { Result } from "../models/common/result.model";

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<User, UserList, CreateUser, UpdateUser> {
  endpointBaseURL = 'users'

  getUsersInOrganization(): Observable<UserList> {
    return this.http.get<UserList>(`${this.endpointBaseURL}/userFromOrganization`)
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
  }

  getAllInRole(roleId: number, pageIndex: number = 1, pageSize: number | null = null): Observable<UserList> {
    const pageSizeQuery = `${pageSize != null ? `&pageSize=${pageSize}` : ''}`;
    return this.http.get<UserList>(`${this.endpointBaseURL}/role/${roleId}?pageIndex=${pageIndex}${pageSizeQuery}`)
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
  }

  getUserFromLocalStorage(): any {
    return localStorage.getItem('user');
  }

  getAllInOrganization(organizationID: number, pageIndex: number | null = null, pageSize: number | null = null): Observable<UserList> {
    const pageIndexQuery = `${pageIndex != null ? `&pageIndex=${pageIndex}` : ''}`;
    const pageSizeQuery = `${pageSize != null ? `&pageSize=${pageSize}` : ''}`;
    return this.http.get<UserList>(`${this.endpointBaseURL}?organizationID=${organizationID}${pageIndexQuery}${pageSizeQuery}`)
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
  }

  getAllInCompany(companyId: number, pageIndex: number = 1, pageSize: number | null = null): Observable<UserList> {
    const pageSizeQuery = `${pageSize != null ? `&pageSize=${pageSize}` : ''}`;
    return this.http.get<UserList>(`${this.endpointBaseURL}/company/${companyId}?pageIndex=${pageIndex}${pageSizeQuery}`)
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
  }

  getAllNotInCompany(companyId: number, query: string, pageIndex: number = 1, pageSize: number | null = null): Observable<UserList> {
    const pageSizeQuery = `${pageSize != null ? `&pageSize=${pageSize}` : ''}`;
    return this.http.get<UserList>(`${this.endpointBaseURL}/company/${companyId}?query=${query}&pageIndex=${pageIndex}${pageSizeQuery}&inRole=false`)
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
  }

  getAllNotInRole(roleId: number, query: string, pageIndex: number = 1, pageSize: number | null = null): Observable<UserList> {
    const pageSizeQuery = `${pageSize != null ? `&pageSize=${pageSize}` : ''}`;
    return this.http.get<UserList>(`${this.endpointBaseURL}/role/${roleId}?query=${query}&pageIndex=${pageIndex}${pageSizeQuery}&inRole=false`)
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
  }

  addUserToRole(userId: number, roleId: number): Observable<Result> {
    return this.http.post<Result>(`${this.endpointBaseURL}/${userId}/role/${roleId}`, {})
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
  }

  removeUserFromRole(userId: number, roleId: number): Observable<Result> {
    return this.http.delete<Result>(`${this.endpointBaseURL}/${userId}/role/${roleId}`, {})
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
  }

  updateUserToRole(userId: number, roleId: number): Observable<Result> {
    return this.http.put<Result>(`userToRole/userID/${userId}/update/${roleId}`, {})
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
  }

  register(registerUser: RegisterUser) {
    return this.http.post<RegisterUser>(`${this.endpointBaseURL}/register`, registerUser)
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
  }

  apply(registerUser: ApplyUserAndOrganization) {
    return this.http.post<ApplyUserAndOrganization>(`Application/ApplyUserAndOrganization`, registerUser)
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
  }

  resetPasswordRequest(email: string): Observable<any> {
    return this.http.post(`${this.endpointBaseURL}/reset-password`, { UserEmail: email }, { responseType: 'text' });
  }

  updatePasswordByToken(pw: string, token: string): Observable<any> {
    return this.http.put(`${this.endpointBaseURL}/reset-password`, { NewUserPassword: pw, Token: token }, { responseType: 'text' });
  }

  requestEmailToken(email: string): Observable<any> {
    return this.http.post(`${this.endpointBaseURL}/email-token-request/${email}`, {}, { responseType: 'text' });
  }

  deleteUser(deleteUserId: number, assignUserId: number): Observable<any> {
    return this.http.delete(`${this.endpointBaseURL}/${deleteUserId}/${assignUserId}`, {}, );
  }
}