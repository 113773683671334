import { Notification } from './../models/notification/notification.model';
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root'
  })

export class NotificationService extends BaseService<Notification, Notification, Notification, Notification> {
    endpointBaseURL = 'notification';

    postNotification(notification: Notification): Observable<boolean> {
        return this.http.post<boolean>(`${this.endpointBaseURL}/create`, notification);
    }
}
