<ng-container *ngIf="loaded">
<p-card>
    <div style="display: flex; justify-content: start; font-size: 16px;">
        <div style="width: 400px;">
          <p>Rubrik: <b>{{contract.contractExtensionName}}</b></p>
          <p>Konsult: <b>{{ contractConsultant.firstName }} {{contractConsultant.lastName}}</b></p>
          <p>Startdatum: <b>{{ getDatestring(contract.startDate) | date: 'dd/MM/yyyy'}}</b></p>
          <p>Slutdatum: <b>{{ getDatestring(contract.endDate) | date: 'dd/MM/yyyy'}}</b></p>
        </div>
        
            <div style="width: 400px;">

              <p *ngIf="state === 'broker' || state === 'customer'">Pris Kund: <b>{{ contract.customerPrice }}</b></p>
              <p *ngIf="state === 'broker' || state === 'supplier'">Pris Lev: <b>{{ contract.supplierPrice }}</b></p>
              <p>Ändringar: <b [innerHTML]="contract.changesInText"></b></p>
            </div>

            <div style="width: 400px;">
              <p *ngIf="state === 'broker' || state === 'customer'">Skickad till Kund: <b>{{ customerReceiver?.contractExtensionReceiverEmail || 'Har inte skickats' }}</b></p>
              <p *ngIf="state === 'broker' || state === 'customer'">Status Kund: <b>{{getContractExtensionResponse(customerReceiver)}}</b> <b *ngIf="customerReceiver?.isContractExtensionAccepted === false"> - {{customerReceiver.contractExtensionRejectionReason}}</b></p>
              <p *ngIf="state === 'broker' || state === 'supplier'">Skickad till Leverantör: <b>{{ supplierReceiver?.contractExtensionReceiverEmail || 'Har inte skickats'}}</b></p>
              <p *ngIf="state === 'broker' || state === 'supplier'">Status Leverantör: <b>{{getContractExtensionResponse(supplierReceiver)}}</b> <b *ngIf="supplierReceiver?.isContractExtensionAccepted === false"> - {{supplierReceiver.contractExtensionRejectionReason}}</b></p>
            </div>

    </div>
    <!-- <div style="display: flex; justify-content: start; font-size: 16px;"> -->
        <p-card *ngIf="state === 'broker' || state === 'customer'" header="Kund">
            <button *ngIf="this.state === 'broker'" pButton label="Ladda upp avtal"  (click)="showFileUpload('customer')"></button>
            <ng-container *ngIf="showCustomerFileUpload">
              <app-file-upload [header]="'Ladda upp avtal för Kund'" (uploadSuccess)="onCustomerUploadDone($event)">
              </app-file-upload>
            </ng-container>
            <button pButton label="Visa avtal" style="margin-left:10px;" (click)="showFile(contract.customerFile)" *ngIf="contract.customerFile"></button>
            <p>Signerade avtal: <b *ngIf="contract.customerFile">{{ contract.customerFile.displayFileName }}</b></p>
        </p-card>

          <p-card *ngIf="state === 'broker' || state === 'supplier'" header="Leverantör">    
            <button *ngIf="this.state === 'broker'" pButton label="Ladda upp avtal"  (click)="showFileUpload('supplier')"></button>
            <ng-container *ngIf="showSupplierFileUpload">
              <app-file-upload [header]="'Ladda upp avtal för Leverantör'" (uploadSuccess)="onSupplierUploadDone($event)">
              </app-file-upload>
            </ng-container>
            <p>Signerade avtal: <b *ngIf="contract.supplierFile">{{ contract.supplierFile.displayFileName }}</b></p>
            <button pButton label="Visa avtal" style="margin-left:10px;" (click)="showFile(contract.supplierFile)" *ngIf="contract.supplierFile"></button>
        </p-card>
        <ng-container *ngIf="display">
          <ngx-extended-pdf-viewer 
            [src]="pdfSrc"
            [useBrowserLocale]="true"
            [showToolbar]="true"
            [showPrintButton]="state === 'broker'"
            [showDownloadButton]="state === 'broker'" 
            [height]="'75vh'">
          </ngx-extended-pdf-viewer>
      </ng-container>
    <!-- </div> -->
    <div  style="text-align:center; margin-top:50px;">
        <button pButton class="p-button p-button-raised p-text-uppercase" label="OK" (click)="onOK()" ></button>
        </div>
</p-card>

</ng-container>

