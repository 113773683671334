<div class="p-grid p-mt-4">
<div class="p-col-6">
<label>Skriv in mottagares epostadresser och tryck enter</label>
</div>

</div>
<div class="p-col-6">
  <p-chips [(ngModel)]="test" ></p-chips>
  </div>

<div class="p-grid p-mt-4">
<div class="p-col-6">
    <!-- <button pButton (click)="cancel()" iconPos="right" style="float: right;"
      class="p-button p-button-raised p-text-uppercase" label="Avbryt"></button> -->
</div>
<div class="p-col-6 p-text-right">
  <button pButton (click)="cancel()" iconPos="right" style="float: center; margin-right: 5px;" 
  class="p-button p-button-raised p-text-uppercase" label="Avbryt"></button>
    <button pButton  (click)="create()" iconPos="right" style="float: right;"
      class="p-button p-button-raised p-text-uppercase" label="Skicka"></button>
</div>
</div>