import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BrokerTender, CreateBrokerTender, BrokerTenderListItem, UpdateBrokerTender, CreateBrokerTenderCommentDTO, BrokerTenderComment } from "../models/assignment-children/broker-tender.model";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root",
  })
  
  export class BrokerTenderService extends BaseService<BrokerTender, CreateBrokerTender, BrokerTenderListItem, UpdateBrokerTender> {
    endpointBaseURL = "brokertender";

    getBrokerTenderSupplier(brokerTenderId: number): Observable<any> {
      return this.http.get<BrokerTender>(`${this.endpointBaseURL}/supplier/${brokerTenderId}`);
    }

    getBrokerTenderComments(brokerTenderID: number): Observable<any> {
      return this.http.get<BrokerTenderComment[]>(`${this.endpointBaseURL}/comment/${brokerTenderID}`);
    }

    createBrokerTenderComments(createBrokerTenderCommentDTO: CreateBrokerTenderCommentDTO): Observable<any> {
      return this.http.post(`${this.endpointBaseURL}/comment`, createBrokerTenderCommentDTO);
    }
    getArchivedBrokerTenders(organizationID:number): Observable<any> {
      return this.http.get(`${this.endpointBaseURL}/archivedBrokerTenders?organizationID=${organizationID}`);
    }
  }
  