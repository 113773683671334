import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { emailAddressValidator } from 'src/app/validators/email-address.validator';
import { MessageService, SelectItem } from 'primeng/api';
import { Resume } from 'src/app/models/resume/resume.model';
import { FileService } from 'src/app/services/file-service';
import { Receiver } from 'src/app/models/receiver/receiver.model';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { CreateSubscriberDTO } from 'src/app/models/subscription/create-subscriber.model'

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styles: [
  ]
})
export class SubscriptionModalComponent implements OnInit {
  receivers: Receiver[];
  newItem: string;
  supplierTenderID: number;
  brokerTenderID: number;
  showEmailForm: boolean;
  email: string = '';
  emailError: boolean = false;
  region: string = 'Universal';
  subscriptionForm: FormGroup;
  errorMessage: string = '';
  regions: SelectItem[];

  constructor(
    public config: DynamicDialogConfig,
    public modalRef: DynamicDialogRef,
    public fileService: FileService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private subscriptionService: SubscriptionService) { }

  selectedResume?: Resume;

  ngOnInit(): void {
    this.receivers = [];
    this.brokerTenderID = -1;
    this.showEmailForm = false;

    this.subscriptionForm = this.formBuilder.group({
      region: [[]],
      email: ['', [Validators.required, emailAddressValidator()]]
    });

    this.regions = [
      { label: 'Väst', value: 2 },
      { label: 'Öst', value: 3 },
      { label: 'Mitt', value: 4 },
      { label: 'Norr', value: 5 },
      { label: 'Syd', value: 6 },
      { label: 'Övrigt', value: 1 }
    ];

    this.subscriptionForm.get('email')!.valueChanges.subscribe(() => {
      this.errorMessage = '';  // Reset the error message on any email value change
    });
  }


  addItem(email: string) {
    this.newItem = '';
    this.receivers.unshift({
      email
    });
  }

  deleteReceiver(receiver: Receiver) {
    const index = this.receivers.indexOf(receiver);
    if (index > -1) {
      this.receivers.splice(index, 1);
    }
  } 

  addEmailToSubscriptionsList(): void {
    if (this.subscriptionForm.invalid) {
      if (this.subscriptionForm.controls.email.errors?.required) {
        this.errorMessage = 'MailRequired';
      }
    else if (this.subscriptionForm.controls.email.errors?.invalidEmail) {
      this.errorMessage = 'Invalid Email Format';
    }
      return;
    }
    const emailValue = this.subscriptionForm.get('email')!.value;

    const selectedRegions: number[] = this.subscriptionForm.get('region')!.value || [];

    const allRegionValues: number[] = this.regions.map(region => region.value);

    let regionsToSubscribe: number[];

    if (selectedRegions.length === 0 || JSON.stringify(selectedRegions.sort((a, b) => a - b)) === JSON.stringify(allRegionValues.sort((a, b) => a - b))) {
      regionsToSubscribe = [7];
    } else {
      regionsToSubscribe = selectedRegions;
    }

    const subscriber: CreateSubscriberDTO = {
      subscriberemail: emailValue,
      regions: regionsToSubscribe,
      token: ""
    };
    this.subscriptionService.createSubscriber(subscriber).subscribe(
      (response) => {
        this.messageService.add({
          key: 'main',
          severity: 'success',
          detail: `Email kommer att skickas till den här adressen ${emailValue} inom en minut, för att skapa prenumerationen läs och följ instruktionerna`,
          life: 20000
        })
      },
      (error) => {
        this.messageService.add({
          key: 'main',
          severity: 'error',
          detail: 'Misslyckades att lägga till prenumeration.'
        });
      }
    );
    this.modalRef.close();
  }

  showToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Ditt mail har skickats'
    })
  }
}
