import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { forkJoin, Observable, combineLatest } from 'rxjs';
import { concatMap, mergeMap } from 'rxjs/operators';
import { ErrorDialogComponent } from 'src/app/components/shared/error-dialog/error-dialog.component';
import { ErrorModalComponent } from 'src/app/components/shared/error-modal/error-modal/error-modal.component';
import { BrokerCallOff } from 'src/app/models/assignment-children/broker-call-off.model';
import { CreateSupplierTender } from 'src/app/models/assignment-children/supplier-tender.model';
import { ConsultantListItem, ConsultantToResumeListItem } from 'src/app/models/consultant/consultant.model';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { Organization, OrganizationChildListItem } from 'src/app/models/organization/organization.model';
import { CreateResume } from 'src/app/models/resume/resume.model';
import { AssignmentChildrenStatus } from 'src/app/models/status/status.enum';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { User } from 'src/app/models/user/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BrokerCallOffService } from 'src/app/services/broker-call-off.service';
import { ConsultantService } from 'src/app/services/consultant.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { ResumeService } from 'src/app/services/resume.service';
import { SupplierTenderService } from 'src/app/services/supplier-tender.service';
import { UserService } from 'src/app/services/user.service';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { SupplierTender } from 'src/app/models/assignment-children/supplier-tender.model';
import { convertToLocaleStringFromUTC } from 'src/app/utils/date';

@Component({
  selector: 'app-create-supplier-tender',
  templateUrl: './create-supplier-tender.component.html',
  styles: [
  ]
})

export class CreateSupplierTenderComponent implements OnInit {
  assignmentID: number;
  brokerCallOffID: number;
  form: FormGroup;
  user: UserAuthenticateResponse;
  supplierTenderParameters: CreateSupplierTender;
  organizations: OrganizationChildListItem[] = [];
  consultants: ConsultantListItem[] = [];
  resumes: ConsultantToResumeListItem[] = [];
  processing = false;
  leavePageWarningEnabled: boolean = true;
  selectedSupplier: number;
  selectedSupplierUser: number = 0;
  selectedConsultant: number = 0;
  selectedResume: number = 0;
  supplierTenders: SupplierTender[] = [];
  createConsultant: boolean;
  createSupplier: boolean;
  newSupplier: Organization;
  brokerCallOffObject: any;
  supplierUsers: User[] = [];
  invalidForm: boolean = false;
  state: string;
  estimate: string;
  backPath: string;
  hideNotPublishedBrokerCallOff: boolean = false;
  convertDate = convertToLocaleStringFromUTC;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private consultantService: ConsultantService,
    private dialogService: DialogService,
    private resumeService: ResumeService,
    private supplierTenderService: SupplierTenderService,
    private router: Router,
    private messageService: MessageService,
    private brokerCallOffService: BrokerCallOffService,
    private confirmationService: ConfirmationService,
    private userService: UserService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.processing=true; 
    combineLatest([this.route.params, this.route.queryParams]).subscribe(([params, queryParams]) => {
      this.assignmentID = Number(params.assignmentID);
      this.brokerCallOffID = Number(params.brokerCallOffID);
      this.backPath = queryParams['back'];
      //If the user is a CustomerSupplier, the side-nav and header components needs to be updated
      const refresh = queryParams['refresh'];
      if (refresh === 'true') {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { refresh: 'false' },
          queryParamsHandling: 'merge'
        }).then(() => {
          window.location.reload();
        });
      }

      this.user = this.authService.getUserFromStorage();

      if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
        this.state = 'broker';
      }
      else if (Number(this.user.organizationTypeID) === OrganizationType.customer) {
        this.state = 'customer';
      }
      else if (Number(this.user.organizationTypeID) === OrganizationType.supplier) {
        this.state = 'supplier';
      }
      else {
        this.state = 'undefined'
      }

      this.brokerCallOffService.get<BrokerCallOff>(this.brokerCallOffID).pipe(
        mergeMap(res => {
          if(res.statusID !== AssignmentChildrenStatus.BrokerCallOffPublished) {
            this.hideNotPublishedBrokerCallOff = true;
          }
          this.brokerCallOffObject = res;
          this.checkEstimate();
          this.brokerCallOffObject.startDate = res.startDate !== null ? this.convertDate(res.startDate) : res.startDate;
          this.brokerCallOffObject.startDate = this.brokerCallOffObject.startDate.replace(',', '');
          this.brokerCallOffObject.endDate = res.endDate !== null ? this.convertDate(res.endDate) : res.endDate;
          this.brokerCallOffObject.endDate = this.brokerCallOffObject.endDate.replace(',', '');
          this.processing=false;
          if(Number(this.user.organizationTypeID) == OrganizationType.supplier)
          {
            return this.brokerCallOffService.getSupplierTendersWithBrokerCallOffId(res.brokerCallOffID);
          }
          return [];
        })
      ).subscribe(res => {
        this.supplierTenders = res;
      })
    });

    this.createForm();

    if (this.state === 'supplier') {
      this.organizationService.getSupplierIDByOrganization(Number(this.user.organizationID)).subscribe(res => {
        this.selectedSupplier = res;
        this.form.get('supplierID')?.setValue(this.selectedSupplier);
        this.onSupplierChange(null);
      })

    }
    else {
      this.fetchOrganizations();
    }
    this.supplierTenderParameters = this.form.value;
  }

  fetchOrganizations(): void {
    this.processing = true;
    this.organizationService.getOrganizationsByType(OrganizationType.supplier).subscribe(
      (response: any) => {
        this.organizations = response.organizations;
        this.organizations = sortAlphabetically(this.organizations, 'organizationName');
        this.processing = false;
      },
      (error) => {
        this.processing = false;
      }
    );
  }

  onCreateClicked() {
    if (this.form.invalid) {
      this.invalidForm = true;
      return;
    }
    this.processing = true;
    this.leavePageWarningEnabled = false;

    this.supplierTenderParameters = this.form.value;
    this.supplierTenderParameters.resumes = [this.selectedResume];
    if (this.supplierTenderParameters.supplierTenderPrice > this.brokerCallOffObject.brokerMaxPrice) {
      this.confirmationService.confirm({
        message: 'Offertpriset överstiger mäklarens maxpris, Vill du fortsätta?',
        acceptLabel: "Ja",
        rejectLabel: "Nej",

        accept: () => {
          this.supplierTenderService.create(this.supplierTenderParameters).subscribe(res => {
            this.processing = false;
            this.showCreatedNotification();
            if(Number(this.user.organizationTypeID) != OrganizationType.supplier)
            {
              this.router.navigate([`broker-tender-board/${this.brokerCallOffID}`]);
              return;
            }
            this.resetFormAndUpdateList();
          }, error => {
            this.processing = false;
            this.leavePageWarningEnabled = true;
            this.dialogService.open(ErrorModalComponent, { data: { header: 'Kunde ej skapa anbud', message: 'Vänligen se över formuläret och försök igen.' } });
          });
        },
        reject: () => {
          this.processing = false;
          this.leavePageWarningEnabled = true;
        }
      })
    } else {
      this.supplierTenderService.create(this.supplierTenderParameters).subscribe(res => {
        this.processing = false;
        this.showCreatedNotification();
        if(Number(this.user.organizationTypeID) != OrganizationType.supplier)
        {
          this.router.navigate([`broker-tender-board/${this.brokerCallOffID}`]);
          return;
        }
        this.resetFormAndUpdateList();
        
      }, error => {
        this.processing = false;
        this.leavePageWarningEnabled = true;
        this.dialogService.open(ErrorModalComponent, { data: { header: 'Kunde ej skapa anbud', message: 'Vänligen se över formuläret och försök igen.' } });
      });
    }
  }

  showCreatedNotification() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Offerten har skapats'
    })
  }

  resetFormAndUpdateList() {
    this.brokerCallOffService.getSupplierTendersWithBrokerCallOffId(this.brokerCallOffID).subscribe(res => {
      this.supplierTenders = res;
      this.form.reset();
      this.createForm();
      this.invalidForm = false;
      this.selectedSupplierUser = 0;
      this.selectedConsultant = 0;
      this.selectedResume = 0;
    });

  }

  createForm() {
    this.form = this.formBuilder.group({
      assignmentID: [this.assignmentID, [Validators.required]],
      supplierUserID: [this.selectedSupplierUser],
      supplierID: [this.selectedSupplier],
      supplierTenderPrice: ["", [Validators.required]],
      supplierComment: [""],
      isTermsAccepted: [true, [Validators.required]],
      statusID: [AssignmentChildrenStatus.SupplierTenderSent, [Validators.required]],
      resumes: [[]],
      resumeID: [],
      consultantID: []
    });
  }

  createSupplierHandler(event: any) {
    this.newSupplier = event;
    this.fetchOrganizations();
    this.createSupplier = false;
  }

  onSupplierChange(event: any): void {

    this.consultants = [];
    this.supplierUsers = [];

    const temp = this.organizations.find(x => x.supplierID === this.selectedSupplier);

    if (temp) {
      const observables: Observable<any>[] = [
        this.consultantService.getConsultantsByOrganization(this.selectedSupplier),
        this.userService.getAllInOrganization(temp.organizationID)
      ];

      forkJoin(observables).subscribe(res => {
        this.consultants = res[0].consultants;
        this.consultants = sortAlphabetically(this.consultants, 'firstName');
        this.supplierUsers = res[1].users;
        this.supplierUsers = sortAlphabetically(this.supplierUsers, 'fullName');
      })
    }
    else {

      this.organizationService.getOrganizationWithSupplierId(this.selectedSupplier).pipe(
        concatMap(org => {
          return this.userService.getAllInOrganization(org.organizationID)
        }),
        mergeMap((users => {
          this.supplierUsers = users.users;
          return this.consultantService.getConsultantsByOrganization(this.selectedSupplier)
        }))
      ).subscribe(res => {
        this.consultants = res.consultants;
      })
    }
  }

  onConsultantChange(event: any): void {
    this.selectedResume = 0;
    this.resumeService.getResumesBySupplierTenderId(this.selectedConsultant).subscribe(res => {
      this.resumes = res.consultantToResumes;

    });
  }

  onResumeChange(event: any): void {


  }


  onSupplierUserChange(event: any): void {

  }


  onCreateSupplier(): void {
    this.createSupplier = true;
  }

  onCreateConsultant(): void {
    this.createConsultant = true

  }

  onShowResume(supplierTendertoResumeFileID: string): void {
    this.router.navigate(['resume/resume-received'], { queryParams: { getResumeByFileId: true, fileID: supplierTendertoResumeFileID, brokerCallOffID: this.brokerCallOffID, assignmentID: this.assignmentID } });
  }
  onShowBrokerCalloffs(): void {
    if (this.backPath) {
      this.router.navigate([this.backPath]);
    }
    else {
      this.router.navigate(['broker-call-off-list']);
    }
  }

  checkEstimate() {
    if (Number(this.brokerCallOffObject.assignmentEstimate) > 0)
      this.estimate = this.brokerCallOffObject.assignmentEstimate;
    else {
      this.estimate = '-';
    }
  }

  onUploadDone(event: any) {
    if (event.success) {
      const resume: CreateResume = {
        fileID: event.file.fileID,
        fileName: event.file.fileName,
        displayFileName: event.file.displayFileName,
        consultantID: null,
        consultants: [this.selectedConsultant]
      }
      this.resumeService.create(resume).subscribe(res => {
        this.resumes.push({
          resume: {
            resumeID: res.resumeID,
            fileID: res.fileID,
            fileName: res.fileName,
            displayFileName: res.displayFileName,
            file:
              res.file
          },
          consultantID: this.selectedConsultant
        });
      }, error => {
        console.error(error);
        this.dialogService.open(ErrorDialogComponent, { data: { message: 'Kunde ej ladda upp CV..' } });
      });
    }
  }
  
  canDeactivate(confirmationMessage: string): boolean | Observable<boolean> | Promise<boolean> {
    if (this.leavePageWarningEnabled) {
      if (this.form.dirty) {
        return window.confirm(confirmationMessage);
      }
    }
    return true;
  }

  onCreateConsultantDone(event: any) {
    if (event.success) {
      this.consultants.push({
        supplierName: event.consultant.supplierName,
        supplierID: event.consultant.supplierID,
        consultantID: event.consultant.consultantID,
        firstName: event.consultant.firstName,
        lastName: event.consultant.lastName,
        email: event.consultant.email,
        phoneNumber: event.consultant.phoneNumber
      });
      this.createConsultant = false;
    }
  }
}
