<p-card header="CV">
  <p-dropdown *ngIf="supplierTenderResumes.length > 1" [options]="supplierTenderResumes" placeholder="Välj ett CV"
    optionLabel="resume.file.displayFileName" [(ngModel)]="selectedResume" (ngModelChange)="onSelect()">
  </p-dropdown>
  <div *ngIf="pdfSrc != null">
    <div class="pdf-test">
      <ngx-extended-pdf-viewer 
        [src]="pdfSrc"
        [useBrowserLocale]="true"
        [showToolbar]="true"
        [showPrintButton]="state === 'broker'"
        [showDownloadButton]="state === 'broker'">
      </ngx-extended-pdf-viewer>
    </div>
  </div>
  <div class="p-mt-4" [hidden]="!processing">
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>
  <button pButton type="button" icon="pi pi-chevron-left"
  class="p-button-secondary p-button-raised p-text-uppercase" (click)="onTillbakaClicked()" label="Tillbaka"
  [disabled]="processing"></button>
</p-card>

