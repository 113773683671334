import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import {HttpClient} from "@angular/common/http";

import { SupplierTenderToResumeList } from "../models/supplier-tender-resume/supplier-tender-resume.model";
import {catchError, retry} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SupplierTenderResumeService {
  endpointBaseURL = 'ResumeToSupplierTender';
  retry = 0;


  constructor(public http: HttpClient) {}
    
  getResumesByToken(supplierTenderID: number, tokenHash: string): Observable<any> {
      return this.http.get<SupplierTenderToResumeList>(`ResumeToSupplierTender/${supplierTenderID}/token/${tokenHash}`)
      .pipe(
        retry(this.retry),
        catchError(this.handleError)
      );
    }

  handleError(error: any) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
    } else {
        // Get server-side error
        errorMessage = `Error code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
  
}
