<div class="p-grid">
    <div class="p-col-4 p-offset-4">
        <p-card header="Registrera leverantör">
            <form [formGroup]="userForm" (ngSubmit)="onRegisterClicked()">
                <div class="p-mt-4">
                    <p class="p-text-bold">Konto</p>
                    <p-divider></p-divider>
                </div>
                <div class="p-fluid p-formgrid p-grid p-mt-5 p-mb-1">
                    <div class="p-field p-col">
                        <label for="firstName">Förnamn
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="placeholder text"></i>
                            </span>
                        </label>
                        <input id="firstName" formControlName="firstName" type="text" [disableControl]="processing" pInputText>
                    </div>
                    <div class="p-field p-col">
                        <label for="lastName">Efternamn
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="placeholder text"></i>
                            </span>
                        </label>
                        <input id="lastName" formControlName="lastName" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="jobTitle">Titel i bolaget
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="placeholder text"></i>
                            </span>
                        </label>
                        <input id="jobTitle" formControlName="jobTitle" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="email">E-postadress
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="placeholder text"></i>
                            </span>
                        </label>
                        <input id="email" formControlName="email" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="password">Lösenord
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="placeholder text"></i>
                            </span>
                        </label>
                        <p-password formControlName="password" [disableControl]="processing"
                                    id="password"></p-password>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="passwordConfirm">Bekräfta lösenord
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="placeholder text"></i>
                            </span>
                        </label>
                        <p-password formControlName="passwordConfirm" [disableControl]="processing"
                                    id="passwordConfirm"></p-password>
                        <small *ngIf="userForm.hasError('mismatch')" class="p-error">Lösenorden matchar ej</small>
                    </div>
                </div>
                <div class="p-mt-4">
                    <p class="p-text-bold">Övrigt</p>
                    <p-divider></p-divider>
                </div>
                <div class="p-fluid p-grid p-mt-4">
                    <div class="p-field p-col-6">
                        <label for="phoneNumber">Telefonnummeer
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="placeholder text"></i>
                            </span>
                        </label>
                        <input id="phoneNumber" formControlName="phoneNumber" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="organizationNumber">Organisationsnummer
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="placeholder text"></i>
                            </span>
                        </label>
                        <input id="organizationNumber" formControlName="organizationNumber" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="companyName">Bolagsnamn
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="placeholder text"></i>
                            </span>
                        </label>
                        <input id="companyName" formControlName="companyName" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-mt-2">
                    <small *ngIf="invalidForm" class="p-error">Registreing av leverantör misslyckades. Kontrollera fälten</small>
                </div>
                <div class="p-grid p-mt-4">
                    <div class="p-col-6">
                        <button pButton
                                type="button"
                                icon="pi pi-chevron-left"
                                class="p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka"
                                [disabled]="processing"
                                (click)="onBackClicked()"></button>&nbsp;
                        <button pButton
                                type="submit"
                                
                                iconPos="right"
                                class="p-button p-button-raised p-text-uppercase"
                                label="Registrera"
                                [disabled]="processing"></button>
                    </div>
                </div>
                <div class="p-mt-4" [hidden]="!processing">
                    <p-progressBar mode="indeterminate"></p-progressBar>
                </div>
            </form>
        </p-card>
    </div>
</div>