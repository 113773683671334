import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { AuthService } from "../../../services/auth.service";
import { UserService } from "../../../services/user.service";
import { Location } from '@angular/common';

//Delete this and import the enum from models when 1197-crete-assignment is merged
enum OrganizationType {
  broker = 1,
  customer = 2,
  supplier = 3,
}

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styles: []
})

export class SideNavComponent implements OnInit {

  items: MenuItem[];
  orgTypeID = -1;
  isSupplier: boolean = false;
  isBroker = false;
  user: UserAuthenticateResponse;
  constructor(private router: Router, public authService: AuthService, public userService: UserService, private route: ActivatedRoute, private location: Location) {
  }

  ngOnInit(): void {
    if (JSON.parse(this.userService.getUserFromLocalStorage()).organizationTypeID) {
      this.orgTypeID = JSON.parse(this.userService.getUserFromLocalStorage()).organizationTypeID;
      this.user = this.authService.getUserFromStorage();
    } else {
      let locationPath = this.location.path();
      if(!locationPath.includes('login')) {
        this.router.navigate(['login']);
      }
    }
    if (Number(this.orgTypeID) == OrganizationType.supplier) {
      this.isSupplier = true;
    }
    if (Number(this.orgTypeID) == OrganizationType.broker) {
      this.isBroker = true;
    }

    this.items = [
      {
        command: () => this.navigateTo("/dashboard"),
        label: 'Översikt',
        styleClass: 'side-nav-menuitem',
        icon: 'pi pi-fw pi-th-large'
      },
      {
        label: "Avrop",
        icon: "pi pi-fw pi-file-o",
        visible: !this.isSupplier,
        styleClass: "side-nav-menuitem",
        items: [
          {
            label: 'Lista Avrop',
            command: () => this.navigateTo("/broker-call-off-list")
          },
          {
            label: 'Arkiv',
            command: () => {
              if (!this.isBroker) {
                this.navigateTo("/call-off/archive/" + this.user.organizationID)
              } else {
                this.navigateTo("/call-off/archive")
              }
            }
          },
          {
            label: 'Skapa avrop',
            visible: Number(this.orgTypeID) === OrganizationType.customer || Number(this.orgTypeID) === OrganizationType.broker,
            command: () => {
              this.navigateTo("call-off/createCustomerCallOff");
            }
          },
          {
            label: 'Mallar',
            visible: Number(this.orgTypeID) == OrganizationType.broker,
            command: () => this.navigateTo("assignment-templates")
          },
          {
            command: () => this.navigateTo(`/call-off-board/${this.user.organizationID}`),
            label: "Statustavla",
          },
        ],
      },
      {
        label: "Offert",
        icon: "pi pi-fw pi-file-o",
        visible: this.isSupplier || this.isBroker,
        styleClass: "side-nav-menuitem",
        items: [
          {
            // command: () => {},
            label: "Skickade Offert",
            command: () => this.navigateTo(`/supplier-tender-list/${this.user.organizationID}`)
          },
          {
            label: "Statustavla",
            command: () => this.navigateTo(`/supplier-tender-board/1`),
            visible: Number(this.orgTypeID) === OrganizationType.supplier
          },
          {
            label: "Arkiv",
            command: () => this.navigateTo(`/tender-archive/${this.user.organizationID}`)
          }
        ]
      },
      {
        label: "Uppdrag",
        icon: "pi pi-fw pi-file-o",
        visible: this.isSupplier,
        styleClass: "side-nav-menuitem",
        command: () => this.navigateTo("/broker-call-off-list")
      },
      {
        label: "   Avtal",
        icon: "pi pi-fw pi-file-o",
        styleClass: "side-nav-menuitem",
        items: [
          {
            command: () => this.navigateTo("/contract-list"),
            label: "Avtal",
          },
          {
            command: () => this.navigateTo("/contract-archive"),
            label: "Arkiv",
            visible: this.isBroker
          },
        ],
      },
      {
        label: 'organisation',
        icon: 'pi pi-fw pi-sitemap',
        styleClass: 'side-nav-menuitem',
        visible: Number(this.orgTypeID) == OrganizationType.broker,
        items: [
          {
            command: () => this.navigateTo("/organizations"),
            label: 'Visa alla'
          },
          {
            command: () => this.navigateTo("organization/create"),
            label: 'Skapa',
            visible: this.isBroker
          },
          {
            command: () => this.navigateTo("/organizations/users"),
            label: 'Visa användare'
          },
          {
            command: () => this.navigateTo("/organization-template"),
            label: 'Mallar'
          }
        ],
      },
      {
        label: 'Användare',
        icon: 'pi pi-fw pi-users',
        styleClass: 'side-nav-menuitem',
        visible: Number(this.orgTypeID) == OrganizationType.broker,
        items: [
          {
            command: () => this.navigateTo("/users"),
            label: 'Visa alla'
          },
          {
            command: () => this.navigateTo("/user/create"),
            label: 'Skapa',
          }

        ],
      },
      {
        label: 'Hantera CV',
        icon: 'pi pi-fw pi-copy',
        styleClass: 'side-nav-menuitem',
        command: () => this.navigateTo("/manage/resumes"),
        visible: Number(this.orgTypeID) === OrganizationType.broker
      },

      {
        command: () => this.navigateTo("/system"),
        label: 'System',
        styleClass: 'side-nav-menuitem',
        icon: 'pi pi-fw pi-cog'
      }
    ];
  }

  navigateTo(url: string): void {
    this.router.navigate([url]);
  }

}
