<div *ngIf="resumes.length > 0">
    <button *ngIf="!showEmailForm" pButton type="button" iconPos="right"
        class="p-button p-button-raised p-text-uppercase" label="Dela CV" (click)="shareResume()"></button>
    <button *ngIf="showEmailForm" pButton type="button"
        class="p-button p-button-raised p-text-uppercase" label="Avbryt delning av CV" (click)="shareResume()"></button>
</div>
<ul class="resumes" style="list-style:none">
    <div *ngIf="resumes.length === 0" p>Inget här</div>
    <div *ngIf="resumes.length > 0">
        <div *ngIf="showEmailForm">
            <div class="p-inputgroup p-col-4">
                <input type="text" pInputText placeholder="Ny epostaddress"
                    class="lg-text-input" id="addItemInput" [(ngModel)]="newItem"
                />
                <button pButton class="btn-primary" (click)="addItem(newItem)">
                    Lägg till
                </button>
            </div>
            <br><br>
            <app-receiver-item *ngFor="let receiver of receivers" [receiver]="receiver"
            (onDeleteReceiver)="deleteReceiver(receiver)"></app-receiver-item>
            <div *ngIf="receivers.length > 0" class="p-grid p-mt-4">
                <div class="p-col-6">
                <button pButton type="button"
                    class="p-button p-button-raised p-text-uppercase" label="Skicka epost" (click)="postToEmails()"></button>
                </div>
            </div>
        </div>
    </div>
    <li *ngFor="let resume of resumes" 
[class.selected]="resume === selectedResume">

<div class="cv-button" style="display: flex; align-items: center;">
    <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-success p-button-text" (click)="onSelect(resume)"></button><p style="margin: 10px;font-size: 18px; cursor: pointer;" (click)="onSelect(resume)">{{resume.displayFileName}}</p>
</div>

<p-divider></p-divider>

</li>
</ul>

<div *ngIf="pdfSrc != null">
<div class="pdf-test">
    <ngx-extended-pdf-viewer
    [src]="pdfSrc"
    [height]="'100vh'"
    [useBrowserLocale]="true"
    [showToolbar]="true"
    [showPrintButton]="state === 'broker'"
    [showDownloadButton]="state === 'broker'"
    >
    </ngx-extended-pdf-viewer>
    </div>
</div>