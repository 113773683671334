<p-card>
    <ng-template pTemplate="header">
        <div class="p-d-flex">
            <div *ngIf="user.organizationTypeID == brokerOrgType || user.organizationTypeID == customerOrgType" class="p-card-title">
                Statustavla (Offert) - {{header}} - Referens - {{ref}}
            </div>
            <div *ngIf="user.organizationTypeID == supplierOrgType" class="p-card-title">
                Statustavla (Offert)
            </div>
        </div>

    </ng-template>

    <button *ngIf="user.organizationTypeID == brokerOrgType" pButton iconPos="right"
        class="p-button p-button-raised p-text-uppercase" label="Skapa Offert"
        (click)="createSupplierTenderModal()"
        [disabled]="this.hideNotPublishedBrokerCallOff"
        pTooltip="{{this.hideNotPublishedBrokerCallOff ? 'Avropet är inte publicerat' : ''}}"
        tooltipPosition="top">
    </button>

    <div class="board">
        <div cdkDropListGroup class="board-container">
            <div class="p-d-flex">
                <div *ngFor="let lane of lanes;let indexOfelement=index;" class="board-column p-mr-2"
                    [style.opacity]="showLane[indexOfelement] ? '100%' : '40%'">
                    <div class="board-column-header" [style.borderColor]="showLane[indexOfelement] ? 'grey' : 'red'"
                        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                        <div class="p-d-flex p-ai-center card">
                            <span>{{lane.statusInfo.statusLaneName}}</span>
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                    pTooltip="{{lane.statusInfo.description}}"></i>
                            </span>
                            <span class="p-ml-auto" *ngIf="lane.statusInfo.isNotification">
                                <i class="pi pi-bell"></i>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="lane.assignmentChildren" cdkDropList [cdkDropListData]="lane.assignmentChildren"
                        (cdkDropListDropped)="onDrop($event, lane.statusInfo.statusID)" class="board-column-content">
                        <div cdkDrag (cdkDragStarted)="onDragStarted(assignmentChild)" *ngFor="
                                let assignmentChild of lane.assignmentChildren
                                let index" class="board-column-item" style="cursor: pointer;">
                            <div class="board-column-item-header">
                                <div class="p-d-flex p-jc-between">
                                    <div class="board-column-item-text" *ngIf="user.organizationTypeID == brokerOrgType || user.organizationTypeID == customerOrgType" (click)="viewConsultantInformation(assignmentChild)">
                                        <i class="pi pi-book"></i>
                                        <span class="p-pl-2"><b>{{assignmentChild.assignmentChildID}}</b> {{assignmentChild.supplierUserFullName}}</span>
                                    </div>
                                    <div class="board-column-item-text"
                                        (click)="viewCalloff(assignmentChild.assignmentChildID)"
                                        *ngIf="this.user.organizationTypeID == supplierOrgType">
                                        <i class="pi pi-book"></i>
                                        <span class="p-pl-2"><b>{{assignmentChild.assignmentChildID}}</b>
                                            {{assignmentChild.header}}</span>
                                    </div>
                                    <div class="board-column-item-text">
                                        <p-menu #menu [popup]="true" [model]="items" [appendTo]="myDiv"></p-menu>
                                        <div class="board-hover-item board-column-contextmenu" #myDiv
                                            (click)="menu.toggle($event); selectedAssignmentChild=assignmentChild;"><i
                                                class="pi pi-ellipsis-h"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div class="board-column-item-content">
                                <div class="board-column-item-text">
                                    <div *ngIf="user.organizationTypeID == supplierOrgType" class="p-grid">
                                        <div class="p-col-6">Konsult: </div>
                                        <div class="p-col"
                                            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
                                            title={{assignmentChild.supplierUserFullName}}>
                                            {{assignmentChild.supplierUserFullName}}</div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-6">Pris: </div>
                                        <div class="p-col"
                                            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                                            {{assignmentChild.price}}</div>
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-6">Datum: </div>
                                        <div class="p-col"
                                            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                                            {{assignmentChild.created | date: 'dd/MM/yyyy'}}</div>
                                    </div>
                                </div>
                                <div class="board-column-item-text">
                                </div>
                                <div *ngIf="!assignmentChild.showFooter" class="p-d-flex p-jc-center">
                                    <div class="board-hover-item" (click)="toggleFooter(assignmentChild)">
                                        <i class="pi pi-chevron-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="board-column-item-footer" *ngIf="assignmentChild.showFooter">
                                <div class="board-column-item-text">
                                    <div class="p-grid">
                                        <div class="p-col">Kommentar:</div>
                                        <div class="p-col">{{assignmentChild.comment}}</div>
                                    </div>
                                </div>
                                <div *ngIf="assignmentChild.showFooter" class="p-d-flex p-jc-center">
                                    <div class="board-hover-item" (click)="toggleFooter(assignmentChild)">
                                        <i class="pi pi-chevron-up"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-card>