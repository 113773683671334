import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeModule } from "src/app/prime-module.ts";
import { CreateCustomerCallOffComponent } from './create-customer-call-off/create-customer-call-off/create-customer-call-off.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    CreateCustomerCallOffComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeModule,
  ]
})
export class CustomerCallOffModule { }
