<ng-container *ngIf="this.processing==false">
  <p-card header="Uppdrag: {{brokerCallOffObject.assignment.header}}" [style]="{'margin-bottom': '25px'}">
    <div style="display: flex; justify-content: left; font-size: 16px;">
      <div style="width: 400px;">
        <p><b>Uppdragstyp:</b> {{brokerCallOffObject.assignmentTypeName}}</p>
        <p><b>Uppdragsort:</b> {{brokerCallOffObject.assignmentLocation}}</p>
        <p><b>Uppdragsbeskrivning:</b>
        <p [innerHTML]="brokerCallOffObject.description"></p>
      </div>
      <div style="width: 400px;">
        <p><b>Omfattning: </b>
          {{estimate}}<b *ngIf="brokerCallOffObject.assignmentTypeID === 3">%</b></p>
        <p><b>Pris:</b> {{brokerCallOffObject.brokerMaxPrice}} ({{brokerCallOffObject.priceTypeName}})</p>
        <p><b>Arbetsplats:</b> {{brokerCallOffObject.assignmentLocationTypeName}}</p>
      </div>
      <div style="width: 400px;">
        <p><b>Startdatum:</b> {{brokerCallOffObject.startDate}}</p>
        <p><b>Slutdatum:</b> {{brokerCallOffObject.endDate}}</p>
      </div>
    </div>
    <div class="p-col-12 p-lg-12">
      <div style="display: flex; justify-content: center; font-size: 16px;">
      </div>
    </div>
  </p-card>
  <button pButton type="button" 
  class="p-button p-button-raised p-text-uppercase" label="Avbryt" (click)="onCancel()"></button>
</ng-container>


