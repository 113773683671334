<div class="container p-grid">
    <div class="p-col">
        <div class="p-d-flex">
            <div *ngIf="authService.isAuthenticated()" class="p-mr-2">
                <app-side-nav></app-side-nav>
            </div>
            <div class="p-mr-2 p-p-4 container-content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>