import { Component, OnInit } from '@angular/core';
import { SupplierTenderResumeService } from 'src/app/services/supplier-tender-resume.service';
import { UserService } from 'src/app/services/user.service';

import { FileService } from 'src/app/services/file-service';
import { SupplierTenderResumeListItem, SupplierTenderToResumeList } from 'src/app/models/supplier-tender-resume/supplier-tender-resume.model';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';

@Component({
  selector: 'app-resume-view',
  templateUrl: './resume-view.component.html',
  styles: [
  ]
})
export class ResumeViewComponent implements OnInit {
  userID = JSON.parse(this.userService.getUserFromLocalStorage()).userID;
  id: number = parseInt(this.userID);
  sid = '';
  supplierTenderID = 0;
  tokenHash = '';
  pdfSrc: Blob | null;
  supplierTenderResumes: SupplierTenderResumeListItem[] = [];
  selectedResume: SupplierTenderResumeListItem;
  processing: boolean;
  getResumeByFileId: boolean;
  fileID: string;
  brokerCallOffID: number;
  assignmentID: number;
  user: any;
  organizationTypeNbr: any;
  state: string = '';

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private fileService: FileService,
    private router: Router,
    private supplierTenderResumeService: SupplierTenderResumeService,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    this.processing = true;
    this.route.queryParams.subscribe(params => {
      this.getResumeByFileId = params['getResumeByFileId'];
      this.fileID = params['fileID'];
      this.brokerCallOffID = params['brokerCallOffID'];
      this.assignmentID = params['assignmentID'];
    });

    if(this.getResumeByFileId)
    {
      this.getResume();
    }
    else
    {
      this.tokenHash = String(this.route.snapshot.paramMap.get('token'));
      this.sid = String(this.route.snapshot.paramMap.get('supplierTenderID'));
      this.supplierTenderID = parseInt(this.sid);
      this.getResumes();
    }

    this.user = this.authService.getUserFromStorage();
    this.organizationTypeNbr = Number(this.user.organizationTypeID);
    if (this.organizationTypeNbr === OrganizationType.broker) {
      this.state = 'broker';
    }
  }

  getResumes(): void {
    this.processing = true;
    this.pdfSrc = null;
    this.supplierTenderResumeService.getResumesByToken(this.supplierTenderID, this.tokenHash).subscribe(
      (response: SupplierTenderToResumeList) => {
        this.supplierTenderResumes = response.supplierTenderResumes;
        if (response.supplierTenderResumes.length == 1) {
          this.selectedResume = response.supplierTenderResumes[0];
          this.onSelect();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getResume(): void {
    this.fileService.getFileById(this.fileID)
    .subscribe(res => {
      this.pdfSrc = res;
      this.processing = false;
    });
  }

  onTillbakaClicked() {
    this.router.navigate(['/supplier-tender-create', this.assignmentID, this.brokerCallOffID]);
  }

  onSelect(): void {
    this.fileService.getFileByIdWithToken(this.selectedResume.resume.fileID, this.tokenHash)
      .subscribe(res => {
        this.pdfSrc = res;
        this.processing = false;
      });
  }
}
