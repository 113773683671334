<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-card header="Redigera användare i roll">
    <form [formGroup]="editRoleUsersForm" (ngSubmit)="onAddRoleUserClicked()">
        <div class="p-grid">
            <div class="p-fluid p-col-6">
                <p-autoComplete
                        placeholder="Sök användare.."
                        [(ngModel)]="selectedUser"
                        formControlName="selectedUser"
                        [suggestions]="users"
                        (completeMethod)="search($event)"
                        [dropdown]="true"
                        [required]="true"
                        field="fullName">
                    <ng-template let-user pTemplate="selectedUser">
                        <span>{{user.fullName}}</span>
                    </ng-template>
                </p-autoComplete>
            </div>
            <div class="p-col-6 p-text-right">
                <button pButton 
                        pTooltip="Lägg till användare"
                        tooltipPosition="top"
                        type="submit"
                        iconPos="right"
                        class="p-button-raised p-button p-text-uppercase"
                        label="Lägg till"
                        [disabled]="processing"></button>
            </div>
        </div>
    </form>
    <p-table [value]="roleUsers">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:5rem">Id</th>
                <th>Namn</th>
                <th>Epost</th>
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td>{{user.id}}</td>
                <td>{{user.fullName}}</td>
                <td>{{user.email}}</td>
                <td class="p-text-right">
                    <button
                            pButton
                            pRipple
                            pTooltip="Ta bort från roll"
                            tooltipPosition="top"
                            type="button"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-danger p-button-text p-mr-1"
                            (click)="confirmRemoveUserFromRole(user)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="p-mt-4" [hidden]="!processing">
        <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
</p-card>