export interface AssignmentChildrenResponse {
    assignmentChildren: AssignmentChildren[];
}

export interface AssignmentChildren {
    assignmentID : number;
    assignmentChildID : number;
    assignmentChildType : string;
    header : string;
    customerName: string;
    assignmentReference : string;
    statusID : number;
    showFooter: boolean;
    created : string;
    price : number;
    comment : string;
    customerEmail: string;
    supplierOrganizationName: string;
    supplierUserFullName: string;
    statusLaneName: string;
    supplierContactFullName: string;
    supplierContactEmail: string;
    supplierContactPhoneNumber: string;
    newStatusID: number | null;
}

export enum AssignmentType {
    Timbank = 1,
    Åtagande = 2,
    Resurskonsult = 3,
}

export enum PriceType{
    Fastpris = 1,
    Timpris = 2,
}

export enum AssignmentUnit {
    Timmar = 1,
    "%" = 2
}

export enum Region {
    North = 1,
    Middle = 2,
    East = 3,
    West = 4,
    Univeral = 5,
  }

export  enum assignmentLocationType {
    Förhandlingsbar = 1,
    Flexibel = 2,
    Remote = 3,
    Onsite = 4
}

export interface ContactInfo {
    organizationId: number,
    organizationTypeId: number,
    fullName: string,
    email: string,
    phoneNumber: number,
}

export interface ContactoInfoList {
    contactInfos: ContactInfo[];
}

export interface ResponsibleContactInfo {
    brokerResponsibleContact:ContactInfo,
    customerReponsibleContact: ContactInfo
}