import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import { UserComponent } from './user/user.component';
import { UsersComponent } from './users/users.component';
import {UserProfileComponent} from "./user-profile/user-profile.component";
import {PrimeModule} from "../../prime-module.ts";
import {RegisterUserComponent} from "./register-user/register-user.component";
import {CheckOrgNumberComponent} from "./apply-user/apply-check-org-number.component";
import {ApplyUserSupplierComponent} from "./apply-user/apply-user-supplier.component";
import { CreateUserComponent } from './create-user/create-user.component';
import { UpdateUserRoleComponent } from './update-user-role/update-user-role.component';
import { UserResetComponent } from './user-reset/user-reset.component';

@NgModule({
    declarations: [
        ApplyUserSupplierComponent,
        CheckOrgNumberComponent,
        UserComponent,
        UsersComponent,
        UserProfileComponent,
        RegisterUserComponent,
        CreateUserComponent,
        UpdateUserRoleComponent,
        UserResetComponent
    ],
    imports: [
        CommonModule,
        PrimeModule
    ],
    exports: [
        UpdateUserRoleComponent
    ]
})

export class UserModule { }
