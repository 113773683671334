import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { User } from 'src/app/models/user/user.model';

@Component({
  selector: 'app-user-select-dialog',
  templateUrl: './user-select-dialog.component.html',
  styles: [
  ]
})
export class UserSelectDialogComponent implements OnInit {
  message: string
  userList: any[];
  selectedUser: any;
  filterUserId: number;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.message = this.config.data.message;
    this.userList = this.config.data.users;
    this.filterUserId = this.config.data.filterUserId;

    this.filterAddedUser();
  }

  onUserSelected(event: any) {
    this.selectedUser = event.value;
  }

  accept(){
    this.ref.close(this.selectedUser);
  }

  deny(){
    this.ref.close(false);
  }

  filterAddedUser() {
    if(this.filterUserId !== undefined) {
      this.userList = this.userList.filter(user => user.userID !== this.filterUserId);
    }
    
  }

}
