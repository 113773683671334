<div class="p-grid p-justify-center">
    <div class="p-col-12 p-lg-12">
        <p-card header='Skapa Tillägg'>
            <form [formGroup]="form" (ngSubmit)="onCreateClicked()">
                <div class="p-mt-4">
                    <!-- <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label for="contractName">Namn *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="placeholder text"></i>
                                </span>
                            </label>
                            <input id="name" formControlName="contractName" type="text" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="contractDate">Avtalsdatum *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="placeholder text"></i>
                                </span>
                            </label>
                            <p-calendar id="contractDate" formControlName="contractDate"  [disableControl]="processing" >
                            </p-calendar>
                            </div>
                        </div>
                        

                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="customerPrice">Kundens pris *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="placeholder text"></i>
                                </span>
                            </label>
                            <input id="customerPrice" formControlName="customerPrice" type="number" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>

                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="supplierPrice">Leverantörens pris *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="placeholder text"></i>
                                </span>
                            </label>
                            <input id="supplierPrice" formControlName="supplierPrice" type="number" [disableControl]="processing"
                                pInputText (blur)="getMargin()">
                            </div>
                        </div>
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="priceComment">Kommentar angående pris
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="placeholder text"></i>
                                </span>
                            </label>
                            <input id="priceComment" formControlName="priceComment" type="text" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="margin">Marginal
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="placeholder text"></i>
                                </span>
                            </label>
                            <input [attr.disabled]="true" id="margin" formControlName="margin" type="number" [disableControl]="processing"
                                pInputText value="customerPrice-supplierPrice" readonly>
                            </div>
                        </div>
                        
                                <div class="p-fluid p-grid">
                                    <div class="p-field p-col-6">
                            <label for="assignmentExtent">Omfattning *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="placeholder text"></i>
                                </span>
                            </label>
                            <input id="assignmentExtent" formControlName="assignmentExtent" type="text" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>
                                <div class="p-fluid p-grid p-formgrid">
                                    <div class="p-field p-col-12 p-md-4">
                            <label>Startdatum *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="placeholder text"></i>
                                </span>
                            </label>
                            <p-calendar id="startDate" formControlName="startDate" [disableControl]="processing" [minDate]="minimumDate" dateFormat="yy/mm/dd">
                            </p-calendar>
                            </div>
                                
                            <div class="p-field p-col-12 p-md-4">
                            <label for="endDate">Slutdatum *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="placeholder text"></i>
                                </span>
                            </label>
                            <p-calendar id="endDate" formControlName="endDate"
                                [disableControl]="processing" [minDate]="minimumDate" dateFormat="yy/mm/dd">

                            </p-calendar>
                            </div>
                                </div> -->
                                <div class="p-fluid p-grid">
                                    <div class="p-field p-col-6">
                                    <label for="contractExtensionName">Rubrik för tillägget
                                        <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                                style="top: 8px" pTooltip="Rubrik i fritext för att lättare identifiera tillägget"></i>
                                        </span>
                                    </label>
                                    <input id="contractExtensionName" formControlName="contractExtensionName" type="text" [disableControl]="processing"
                                        pInputText>
                                    </div>
                                </div>
                                <div class="p-fluid p-grid p-formgrid">
                                    <div class="p-field p-col-12 p-md-4">
                            <label>Startdatum *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Det datum då tillägget till avtalet ska börja gälla"></i>
                                </span>
                            </label>
                            <p-calendar id="startDate" formControlName="startDate" [disableControl]="processing" dateFormat="yy/mm/dd">
                            </p-calendar>
                            </div>
                                
                            <div class="p-field p-col-12 p-md-4">
                            <label for="endDate">Slutdatum *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Det datum då tillägget/avtalet avslutas"></i>
                                </span>
                            </label>
                            <p-calendar id="endDate" formControlName="endDate"
                                [disableControl]="processing" dateFormat="yy/mm/dd">

                            </p-calendar>
                            </div>
                                </div>
                                <p-divider></p-divider>
                        <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label for="currentConsultant">Vald konsult *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Konsulten som avtalat omfattar"></i>
                                </span>
                            </label>
                            <p *ngIf="loaded" id="currentConsultant">{{currentConsultant.firstName}} {{currentConsultant.lastName}}</p>
                        </div>
                            <div class="p-field p-col-6">
                                <label for="changeConsultant">Ska konsulten ändras?</label>
                                <button pButton type="button" id="changeConsultant"
                                class="p-button p-button-raised p-text-uppercase" label="Visa tillgängliga konsulter"
                                [disabled]="processing" (click)="onGetConsultants()"></button>
                                
                                <div *ngIf="showConsultants">
                                <p-dropdown 
                                placeHolder="--Välj en konsult--"
                                [options]="consultants"
                                optionLabel="firstName"
                                inputId="consultantID" 
                                formControlName="consultantId" 
                                optionValue="consultantID" 
                                [disableControl]="processing"
                                (onChange)="onConsultantChange($event)"
                                [filter]="true"
                             
                                >
                                <option *ngFor="let con of consultants" [ngValue]="con.consultantID">
                                    {{con.firstName }} {{ con.lastName }}</option></p-dropdown>
                                </div>
                            </div>
                            
                        </div>
                        <p-divider></p-divider>
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="customerPrice">Kundens pris *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Priset som kunden och Swedish Consulting Group har kommit överens om"></i>
                                </span>
                            </label>
                            <input id="customerPrice" formControlName="customerPrice" type="number" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>

                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="supplierPrice">Leverantörens pris *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Priset som leverantören och Swedish Consulting Group har kommit överens om"></i>
                                </span>
                            </label>
                            <input id="supplierPrice" formControlName="supplierPrice" type="number" [disableControl]="processing"
                                pInputText (blur)="getMargin()">
                            </div>
                        </div>
                        <p-divider></p-divider>
                        <div class="p-field">
                        <label>Ändringar i fritext</label>
                        <p-editor formControlName="changesInText" [style]="{'height':'320px'}" header="Test"></p-editor>
                        </div>
                        <div class="p-mt-2">
                            <small *ngIf="invalidForm" class="p-error">Misslyckades skapa tillägg. Kontrollera fälten</small>
                          </div>
                            <div class="p-grid p-mt-4">
                                <div class="p-col-6">
                                    <button pButton type="button" icon="pi pi-chevron-left"
                                        class="p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka"
                                        [disabled]="processing" (click)="onBackClicked()"></button>
                                </div>
                                <div class="p-col-6 p-text-right">
                                    <button pButton type="submit" iconPos="right"
                                        class="p-button p-button-raised p-text-uppercase"
                                        label="Skapa"
                                        [disabled]="processing"></button>
                                </div>
                            </div>
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
                <div class="p-mt-4" [hidden]="!processing">
                    <p-progressBar mode="indeterminate"></p-progressBar>
                </div>
            </form>
        </p-card>
    </div>
</div>