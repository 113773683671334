import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { UserAuthenticateResponse } from "../../../models/user/user-authenticate-response.model";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ]
})
export class LoginComponent implements OnInit {

  processing: boolean;
  loginInvalid: boolean;
  state: string;

  resetSent = false;
  loginForm: FormGroup;

  isRedirected: boolean;
  assignmentID: string | null = null;
  brokerCalloffID: string | null = null;
  redirectTo: string | null = null;
  brokerCalloffList = '/broker-call-off-list';
  authSubscription: Subscription;
  user: UserAuthenticateResponse;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.state = 'login';

    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.minLength(2)]],
      password: ['', [Validators.required, Validators.minLength(2)]]
    });

    this.isRedirected = this.route.snapshot.queryParamMap.get('redirect') === 'true';

    this.assignmentID = this.route.snapshot.queryParamMap.get('assignmentID');

    this.brokerCalloffID = this.route.snapshot.queryParamMap.get('brokerCalloffID');

    this.redirectTo = this.route.snapshot.queryParamMap.get('redirectTo');

    let isAuthenticated = this.authService.isAuthenticated();

    if (isAuthenticated) {
      this.user = this.authService.getUserFromStorage();
      if (!this.isRedirected) {
        this.router.navigate([this.brokerCalloffList]);
      }
      else if (this.isRedirected) {
        this.redirect();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }


  onLoginClicked() {
    if (this.processing) {
      return;
    }
    this.processing = true;
    localStorage.setItem('processing', this.processing + "");
    this.authService.logout();

    this.authService.login(
      this.loginForm.controls.userName.value,
      this.loginForm.controls.password.value
    ).subscribe(res => {
      this.user = this.authService.getUserFromStorage();
      if (this.isRedirected) {
        this.redirect();
      } else {
        this.router.navigate([this.brokerCalloffList]);
      }
      this.processing = false;
      localStorage.setItem('processing', this.processing + "");
      this.loginInvalid = false;
    }, error => {
      this.processing = false;
      localStorage.setItem('processing', this.processing + "");
      this.loginInvalid = true;
    });
  }

  onResetSubmit(): void {
    this.processing = true;

    this.userService.resetPasswordRequest(this.loginForm.controls.userName.value.trim()).subscribe(res => {
      this.resetSent = true;
      this.processing = false;
    }, error => {
      this.processing = false;
    });
  }

  onForgottenPasswordClicked() {
    if (this.processing) {
      return;
    }
    this.state = 'reset';
  }
  onBackToLoginClicked() {
    if (this.processing) {
      return;
    }
    this.state = 'login';
  }

  onApplyClicked() {
    if (this.processing) {
      return;
    }

    this.router.navigate(['login/user/check-org-number']);
    this.processing = true;
  }


  redirect(): void {

    if (this.redirectTo == '/supplier-tender-create') {
      if (this.authService.checkIfUserHasSupplierAccess()) {
        this.authService.checkIfUserIsCustomerSupplierAndSetOrgType();
        this.router.navigate([this.redirectTo, this.assignmentID, this.brokerCalloffID]);
      }  else {
        this.router.navigate([this.brokerCalloffList]);
      }
    } 
  }

  test() {
    this.router.navigate(['/user/reset']);
  }
}
