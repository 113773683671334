import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BrokerTender } from 'src/app/models/assignment-children/broker-tender.model';
import { UpdateSupplierTender } from 'src/app/models/assignment-children/supplier-tender.model';
import { Assignment, AssignmentDTO } from 'src/app/models/assignment/assignment.model';
import { Contract, UpdateContract } from 'src/app/models/contract/contract.model';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { User } from 'src/app/models/user/user.model';
import { AssignmentService } from 'src/app/services/assignment.service';
import { AuthService } from 'src/app/services/auth.service';
import { BrokerTenderService } from 'src/app/services/broker-tender.service';
import { ConsultantService } from 'src/app/services/consultant.service';
import { ContractService } from 'src/app/services/contract.service';
import { FileService } from 'src/app/services/file-service.service';
import { SupplierTenderService } from 'src/app/services/supplier-tender.service';
import { SupplierService } from 'src/app/services/supplier.service';
import { UserService } from 'src/app/services/user.service';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { convertToLocaleStringFromUTC, removeTimeFromDateString } from 'src/app/utils/date';
import { environment } from 'src/environments/environment';
import { ContractEmailComponent } from '../contract-email/contract-email.component';
import { ContractExtensionCreateComponent } from '../contract-extension-create/contract-extension-create.component';
import { ContractExtensionModalComponent } from '../contract-extension-modal/contract-extension-modal.component';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styles: [
  ]
})
export class ContractComponent implements OnInit {

  contract: Contract;
  viewContract: Contract;
  consultant: any;
  assignment: AssignmentDTO;
  brokerTender: BrokerTender;
  responsibleBrokerUser: any;
  responsibleCustomerUser: any;
  consultants: any[] =[];
  brokerUsers: any[];
  supplierOrganizations: any[] = [];
  customerUsers: any[];
  supplierTenderContactList: any[] = [];
  supplierUsers: any[];
  users: any[]
  loaded = false;
  display: boolean;
  tableSettings: any = environment.applicationSettings.table;
  pageIndex: number = 1;  
  pageSize: number = this.tableSettings.defaultPageSize;
  pageTotal: number;
  showCustomerFileUpload: boolean = false;
  showSupplierFileUpload: boolean = false;
  pdfSrc: Blob;
  user: UserAuthenticateResponse
  state: String = 'undefined';
  dialogRef: DynamicDialogRef;
  createExtension: boolean = false;
  contractId: number;
  stateSupplierContactInfo: string;
  availableSupplierUsers: User[] = [];
  selectedSupplierUser: number;
  showSupplier: boolean = false;
  convertDate = convertToLocaleStringFromUTC;
  // TODO FIX IN BACKEND - retrieve the actual strings not only ids
  assignmentType = ['Timbank', 'Åtagande', 'Resurskonsult'];

  assignmentUnit = ['Timmar', '%'];

  constructor(
  private contractService: ContractService, 
  private route: ActivatedRoute, 
  private assignmentService: AssignmentService,
  private brokerTenderService: BrokerTenderService,
  private supplierTenderService: SupplierTenderService,
  private fileService: FileService,
  private authService: AuthService,
  private dialogService: DialogService,
  private consultantService: ConsultantService,
  private userService: UserService,
  private supplierService: SupplierService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUserFromStorage();
    if(Number(this.user.organizationTypeID) === OrganizationType.broker) {
      this.state = 'broker';
    }
    else if(Number(this.user.organizationTypeID) === OrganizationType.customer) {
      this.state = 'customer';
    }
    else if(Number(this.user.organizationTypeID) === OrganizationType.supplier) {
      this.state = 'supplier';
    }
    else {
      this.state = 'undefined'
    }

    this.route.params.subscribe(params => {
      let requests: Observable<any>[] = [];
      this.contractService.get<Contract>(params.contractID).pipe(
        mergeMap(contract => {
          this.contract = contract;
          this.contract.startDate = contract.startDate !== null ? this.convertDate(contract.startDate): contract.startDate;
          this.contract.contractDate = contract.contractDate !== null ? this.convertDate(contract.contractDate) : contract.contractDate;
          this.contract.endDate = this.convertDate(contract.endDate);

          this.contract.contractExtensions = this.sortContractExtensions();
          if (this.state === 'supplier') {
            return this.brokerTenderService.getBrokerTenderSupplier(this.contract.brokerTenderID);
          }
          else {
          return this.brokerTenderService.get<BrokerTender>(this.contract.brokerTenderID);
        }
        }),
        mergeMap((brokerTender) => {
          this.brokerTender = brokerTender;
          return  this.consultantService.getConsultantByConsultantId(this.contract.consultantID);
        }),
        mergeMap((consultant) => {
          this.consultant = consultant;
          if(this.state === 'supplier') {
          requests.push(this.assignmentService.getAssignmentSupplier(this.brokerTender.assignmentID));
        }
        else requests.push(this.assignmentService.get<Assignment>(this.brokerTender.assignmentID));
          requests.push(this.assignmentService.getResponsibleUsers(this.brokerTender.assignmentID));
          requests.push(this.assignmentService.getUsers(this.brokerTender.assignmentID));
          requests.push(this.assignmentService.getSupplierContact(this.brokerTender.supplierTenderID));
          requests.push(this.consultantService.getConsultantByConsultantId(this.contract.consultantID));
          requests.push(this.supplierService.getOrganizationBySupplierID(this.consultant.supplierID));
          return forkJoin(requests);
        })
      ).subscribe(res => {
        this.assignment = res[0];
        this.responsibleBrokerUser = res[1].responsibleBrokerUser;
        this.responsibleCustomerUser = res[1].responsibleCustomerUser;
        this.users = res[2].userToAssignmentUsers;
        this.setUsersByOrganizationType();
        this.supplierTenderContactList[0] = res[3];
        this.consultants[0] = res[4];
        this.supplierOrganizations[0] = res[5];

        this.contract.contractExtensions.forEach(extension => {
          extension.startDate = extension.startDate !== null ? this.convertDate(extension.startDate): extension.startDate;
          extension.contractDate = extension.contractDate !== null ? this.convertDate(extension.contractDate) : extension.contractDate;
          extension.endDate = this.convertDate(extension.endDate);
        });

        if (this.consultants[0].consultantManagerUser === null){
          this.stateSupplierContactInfo = "supplierTenderContactInfo";
        }
        else if (this.supplierTenderContactList[0] === null){
          this.stateSupplierContactInfo = "supplierOrganizationInfo";
        }
        else {
          this.stateSupplierContactInfo = "responsableConsultantManagerContactInfo";
        }
        this.loaded = true; 
      }, error => {
        console.log(error);
      });
    })
  }

  sortContractExtensions(){
    return this.contract.contractExtensions.sort((a, b) => {
      return <any>new Date(b.created) - <any>new Date(a.created);
    });
  }

  showFileUpload(type: string) {
    if (type === 'customer'){
      this.showCustomerFileUpload = true;
    }
    if (type === 'supplier'){
      this.showSupplierFileUpload = true;
    }
  }

  showSupplierUsers(): void {
    this.userService.getAllInOrganization(this.supplierOrganizations[0].organizationID).subscribe(res => {
      this.availableSupplierUsers = res.users;
      this.availableSupplierUsers = sortAlphabetically(this.availableSupplierUsers, 'fullName');

      this.showSupplier = true;
    })
  }

  setUsersByOrganizationType(): void {
    this.brokerUsers = [];
    this.brokerUsers.push(this.responsibleBrokerUser);
    this.customerUsers = [];
    this.customerUsers.push(this.responsibleCustomerUser);
    this.supplierUsers = this.users.filter(u => u.organizationTypeID === 3);
  }

  loadContractsExtensions(event: LazyLoadEvent) {
    const {first, filters, sortField, sortOrder} = event;
    this.pageIndex = (<number>first / 10) +1;
  }

  getDatestring(date: any) {
    return removeTimeFromDateString(date.toString());
  }

  onSupplierUserChange(event: any): void {
    
  }

  onChangeSupplierUser() {
    var dto : UpdateSupplierTender = {
      supplierUserID : this.selectedSupplierUser,
       supplierComment: null,
        supplierTenderPrice: null,
        isTermsAccepted: true,
        statusID: null,
        resumes: null
      };

      this.supplierTenderService.update(this.brokerTender.supplierTenderID, dto).pipe(
        mergeMap(res =>{
          return this.userService.get<User>(this.selectedSupplierUser);
        })
      ).subscribe(res =>{
        this.supplierTenderContactList = [];
        this.supplierTenderContactList.push(res);
        this.showSupplier = false;
      })

   this.supplierTenderService.update(this.brokerTender.supplierTenderID, dto).subscribe(res =>{
   })
  }

  onSupplierUploadDone(event: any) {
    if(event.success) {
      this.contractService.update<UpdateContract>(this.contract.contractID, {supplierFileId: event.file.fileID}).pipe(
        mergeMap( res => {
          
           return this.contractService.get<Contract>(this.contract.contractID)

        })).subscribe(contract => {
          if(contract) {
          this.contract = contract;
        }
        }, error => {
          console.log(error);
        }); 
    }
  }

  onCustomerUploadDone(event: any) {
    if(event.success) {
      this.contractService.update<UpdateContract>(this.contract.contractID, {customerFileId: event.file.fileID}).pipe(
        mergeMap( res => {
          
           return this.contractService.get<Contract>(this.contract.contractID)

        })).subscribe(contract => {
          if(contract) {
          this.contract = contract;
        }
        }, error => {
          console.log(error);
        }); 
    }
  }

  onUploadDocument(event: any): void {
  }

  showFile(file: any): void {
    this.fileService.getFileById(file.fileID).subscribe(res => {
      this.pdfSrc = res;
      this.display = true;
     });
  }

  onRequestExtension() {
    this.brokerTenderService.get(this.contract.brokerTenderID).subscribe(res => {
      this.dialogRef = this.dialogService.open(ContractEmailComponent,
        {
          header: 'Begär förlängning',
          width: '40%',
          data: {
            eventID: 22,
            mailType: 'sendToBroker',
            contract: this.contract,
            brokerTender: res,
            notificationSubject: `Ny förlängning till avtal: ${this.contract.customerContractReference}`
          }
        })
    })
    
  }

  onCreateExtension() {
   this.dialogRef = this.dialogService.open(ContractExtensionCreateComponent, {
    header: "",
    width: '60%',
    data: {
    contractId: this.contract.contractID
  }
   })
   this.dialogRef.onClose.subscribe(res => {
    if (res){
      this.contractService.get<Contract>(this.contract.contractID).subscribe(res => {
        this.contract = res;
      })
    }
    else {
    }
   })
  }

  openContractExtensionPage(contract: Contract) {
    this.viewContract = contract;
    console.log(this.viewContract);
    // event.stopPropagation();
    this.dialogRef = this.dialogService.open(ContractExtensionModalComponent,{
      header: 'Tilläggsdetaljer',
      width: '80%',
    data:{
      contract: this.viewContract
    }});
  }

  activateContractExtension(event: any, contractExtenstion: any) {
    var update  = {};
    event.stopPropagation();
    this.contractService.activateContractExtension(contractExtenstion.contractExtensionID, update).subscribe(res => {
      this.contract = res;
      this.contract.contractExtensions = this.sortContractExtensions();
    })
  }

  onOK() {
    this.display = false;
  }
}
