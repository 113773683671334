// export enum AssignmentChildrenStatus {
//     CustomerCallOffDraft = 1,
//     CustomerCallOffReview,
//     CustomerCallOffSent,
//     BrokerCallOffPublished,
//     SupplierTenderSent,
//     SupplierTenderReview,
//     BrokerTenderSent,
//     BrokerTenderInterviewRequest,
//     BrokerTenderInterview,
//     BrokerTenderNegotiate,
//     BrokerTenderAccepted,
//     BrokerTenderRejected,
//     BrokerTenderContracted,
//     BrokerTenderArchived
// }

export enum AssignmentChildrenStatus {
    CustomerCallOffDraft = 1,
    CustomerCallOffReview,
    CustomerCallOffSent,
    CustomerCallOffReviewBroker,
    BrokerCallOffDraft,
    BrokerCallOffPublished,
    CallOffCloseRequest,
    CallOffClosed,
    SupplierTenderSent,
    SupplierTenderReview,
    BrokerTenderDraft,
    BrokerTenderSent,
    BrokerTenderReview,
    BrokerTenderInterviewRequest,
    BrokerTenderInterview,
    BrokerTenderNegotiate,
    BrokerTenderAccepted,
    BrokerTenderContractOngoing,
    BrokerTenderContracted,
    BrokerTenderRejectedCustomer,
    BrokerTenderRejectedSupplier,
    AssignmentArchived,
    CallOffCompleted,
}
