import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../../../services/auth.service";
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { MenuItem } from "primeng/api";
import { Observable, Subscription } from 'rxjs';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { DialogService } from "primeng/dynamicdialog";
import { Location } from '@angular/common';
import { ModalHandler } from 'src/app/components/shared/ModalHandler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit, OnDestroy {

  currentUser$: Observable<any>;
  items: MenuItem[] = [];
  rightItems: MenuItem[] = [];
  display: boolean = false;
  user: UserAuthenticateResponse;
  toSupplierLabel: string = "Till Leverantör";
  toCustomerLabel: string = "Till Kund";
  organizationTypeLabelItemName: string = "organizationTypeLabel";
  subscription: Subscription;
  supportEmail: string = environment.supportEmail;

  constructor(private router: Router, public authService: AuthService, private dialogService: DialogService, private location: Location,
    private modalHandler: ModalHandler) {
    this.currentUser$ = this.authService.userToken;
  }

  ngOnInit(): void {
    let updated = false;
    this.subscription = this.currentUser$.subscribe(user => {
      this.updateMenuItems();
      this.authService.checkIfUserIsAuthorizedForPage();
      updated = true;
    });
    if (updated == false) {
      this.updateMenuItems();
      this.authService.checkIfUserIsAuthorizedForPage();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateMenuItems(): void {
    this.user = this.authService.getUserFromStorage();

    this.rightItems = [
      {
        label: "Prenumeration",
        icon: "pi pi-fw pi-bell",
        styleClass: "p-menuitem-link p-ripple",
        command: () => this.showSubscriptionModal()
      },
      {
        label: "Support",
        icon: "pi pi-fw pi-comments",
        styleClass: "p-menuitem-link p-ripple",
        command: () => this.showDialog()
      }
    ]

    this.rightItems.push(
      {
        label: "Min sida",
        icon: "pi pi-fw pi-user",
        styleClass: "p-menuitem-link p-ripple",
        command: () => this.onMyPageClicked()
      },
      {
        label: "Bolag",
        icon: "pi pi-fw pi-home",
        styleClass: "p-menuitem-link",
        items: [
          {
            label: "Min organisation",
            command: () => this.navigateTo(`/organization`)

          },
          {
            label: "Visa användare",
            command: () => this.navigateTo("/organizations/users")
          },
        ]
      },
      {
        label: "Logga ut",
        icon: "pi pi-fw pi-sign-out",
        styleClass: "p-menuitem-link p-ripple",
        command: () => this.onLogoutClicked()
      });

    this.addCustomerSupplierItem();

    this.items = [
      {
        styleClass: 'menuitem-logo',
        label: 'Swedish Consulting Group BRM',
        icon: 'menubar-logo'
      }
    ];

  }

  private supplierCustomerItem: MenuItem = {
    label: <string>localStorage.getItem(this.organizationTypeLabelItemName) == null ? this.toSupplierLabel : <string>localStorage.getItem(this.organizationTypeLabelItemName),
    icon: "pi pi-fw pi-arrow-right",
    styleClass: "p-menuitem-link center-icon",
    command: () => this.onCustomerSupplierClicked()
  };

  onMyPageClicked(): void {
    this.router.navigate([`/user/profile`]);
  }


  onCustomerSupplierClicked(): void {
    const user = JSON.parse(<string>localStorage.getItem("user"));
    if (user) {
      if (user.organizationTypeID == OrganizationType.customer) {
        user.organizationTypeID = String(OrganizationType.supplier);
        localStorage.setItem(this.organizationTypeLabelItemName, this.toCustomerLabel);
        const supplierItem = this.rightItems.find(item => item.label === this.toCustomerLabel);
        if (supplierItem) {
          supplierItem.label = this.toCustomerLabel;
        }
      } else if (user.organizationTypeID == OrganizationType.supplier) {
        user.organizationTypeID = String(OrganizationType.customer);
        localStorage.setItem(this.organizationTypeLabelItemName, this.toSupplierLabel);
        const supplierItem = this.rightItems.find(item => item.label === this.toSupplierLabel);
        if (supplierItem) {
          supplierItem.label = this.toSupplierLabel;
        }
      }
      localStorage.setItem("user", JSON.stringify(user));
      location.reload();
    }
  }

  removeCustomerSupplier(): void {
    const supplierItem = this.rightItems.find(item => item.label === this.toCustomerLabel);
    if (supplierItem) {
      supplierItem.label = this.toSupplierLabel;
    }
    const index = this.rightItems.indexOf(this.supplierCustomerItem);
    if (index > -1) {
      this.rightItems.splice(index, 1);
    }
  }

  addCustomerSupplierItem(): void {
    const user = JSON.parse(<string>localStorage.getItem("user"));

    if (user != null) {
      if (user.organizationTypeIDJWT == OrganizationType.customerSupplier) {
        if (user.organizationTypeID == OrganizationType.supplier) {
          localStorage.setItem(this.organizationTypeLabelItemName, this.toCustomerLabel);
          const supplierItem = this.rightItems.find(item => item.label === this.toCustomerLabel);
          if (supplierItem) {
            supplierItem.label = this.toCustomerLabel;
          }
        }
        else {
          localStorage.setItem(this.organizationTypeLabelItemName, this.toSupplierLabel);
          const supplierItem = this.rightItems.find(item => item.label === this.toSupplierLabel);
          if (supplierItem) {
            supplierItem.label = this.toSupplierLabel
          }
        }
        this.rightItems.splice(0, 0, this.supplierCustomerItem);
      } else {
        const index = this.rightItems.indexOf(this.supplierCustomerItem);
        if (index > -1) {
          this.rightItems.splice(index, 1);
        }
      }
    }
    else {
      localStorage.setItem(this.organizationTypeLabelItemName, this.toSupplierLabel);
      const supplierItem = this.rightItems.find(item => item.label === this.toSupplierLabel);
      if (supplierItem) {
        supplierItem.label = this.toSupplierLabel;
      }
    }
  }

  onLogoutClicked() {
    if (this.authService.isAuthenticated() == true) {
      this.removeCustomerSupplier();
      this.authService.logout();
      this.router.navigate([`/login`]);
    }
    else {
      this.updateMenuItems();
      this.router.navigate([`/login`]);

    }
  }

  showDialog() {
    this.display = true;
  }
  public showSubscriptionModal() {
    this.modalHandler.showSubscriptionModal();
  }

  onOK() {
    this.display = false;
  }

  navigateTo(url: string): void {
    this.router.navigate([url]);
  }
}