import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SupplierTender, CreateSupplierTender, SupplierTenderListItem, UpdateSupplierTender } from "../models/assignment-children/supplier-tender.model";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root",
  })
  
  export class SupplierTenderService extends BaseService<SupplierTender, CreateSupplierTender, SupplierTenderListItem,  UpdateSupplierTender> {
    endpointBaseURL = "suppliertender";
  
    getArchivedSupplierTenders(organizationID: number): Observable<any> {
      return this.http.get(`${this.endpointBaseURL}/archivedSupplierTenders?organizationID=${organizationID}`);
    }
    getSupplierTenders(organizationID: number): Observable<any> {
      return this.http.get(`${this.endpointBaseURL}/getSupplierTenderList?organizationID=${organizationID}`);
    }
    supplierTenderListBySupplier(organizationID: number): Observable<any> {
      return this.http.get(`${this.endpointBaseURL}/supplierTenderListBySupplier?organizationID=${organizationID}`);
    }
  }
  