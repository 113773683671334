

<p-card>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div style="margin: 1em 0; display: flex; align-items: center;" *ngIf="isBroker || isCustomer">
            <input type="checkbox" class="p-checkbox" formControlName="canSendCallOffIfNotResponsible">
            <label for="canSendCallOffIfNotResponsible">Tillåt icke administratörer skicka avrop</label>
        </div>
    
        <div>
            <input class="p-button p-button-raised p-text-uppercase" type="submit" value="Spara">
        </div>
    </form>
</p-card>