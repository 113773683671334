import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsultantService } from 'src/app/services/consultant.service';
import { of } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateConsultantComponent } from 'src/app/components/assignment-children/create-consultant/create-consultant.component';
import { ResumeService } from 'src/app/services/resume.service';
import { FileService } from 'src/app/services/file-service';
import { ResumeEditComponent } from 'src/app/components/resume-edit/resume-edit.component';

@Component({
  selector: 'app-organization-consultant-details',
  templateUrl: './organization-consultant-details.component.html',
  styleUrls: ["./organization-consultant-details.css"
  ]
})
export class OrganizationConsultantDetailsComponent implements OnInit {

  consultantId : number;

  constructor(
      private route: ActivatedRoute,
      ) { }


  ngOnInit(): void {
    this.consultantId = Number(this.route.snapshot.paramMap.get("consultantId"))  
  }

}