import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { AssignmentChildrenStatus } from 'src/app/models/status/status.enum';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { BrokerTenderService } from 'src/app/services/broker-tender.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PermissionService } from 'src/app/services/permission.service';
import { SupplierTenderService } from 'src/app/services/supplier-tender.service';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal/error-modal.component';

@Component({
  selector: 'app-broker-call-off-archive-list',
  templateUrl: './tender-archive.component.html',
  styles: [
  ]
})

export class TenderArchiveComponent implements OnInit {
  public organizationId: any;
  user: UserAuthenticateResponse;
  isBrokerUser: boolean;
  customers: any = [];
  Tenders: [] = [];
  StatusRules: any[];
  selectedOrganizationID: number;
  updateStatusDto: { assignmentChildTypeName: any; assignmentChildID: any; currentStatus: any; newStatusID: number; };

  constructor(
    private supplierTenderService: SupplierTenderService,
    private brokerTenderService: BrokerTenderService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private auth: AuthService,
    private permissionService: PermissionService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUserFromStorage();

    const storedOrganizationID = localStorage.getItem('selectedCustomerOrganizationID');
    if (storedOrganizationID) {
      this.selectedOrganizationID = +storedOrganizationID;
    } else {
      this.selectedOrganizationID = 0;
    }

    if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
      this.isBrokerUser = true;
    }

    this.permissionService.getStatusRules(parseInt(this.user.organizationTypeID)).subscribe((res) => {
      this.StatusRules = res;
    })
    this.organizationId = this.route.snapshot.paramMap.get("organizationID");
    if (this.isBrokerUser) {
      // this.getCustomers();
      this.organizationService.getOrganizationsByType(OrganizationType.customer).subscribe(
        (response: any) => {
          this.customers = response.organizations;
          this.customers = sortAlphabetically(this.customers, 'organizationName');
          this.selectedOrganizationID = this.selectedOrganizationID === 0 ? this.customers[0].organizationID : this.selectedOrganizationID;
          this.getArchivedTenders();
        },
        (_error) => {
        }
      );
    }
  }

  getArchivedTenders() {
    this.Tenders = [];
    if (this.isBrokerUser) {
      this.brokerTenderService.getArchivedBrokerTenders(this.selectedOrganizationID).subscribe((res) => {
        let temp: [] = res.tenderArchiveItems;
        temp.forEach(element => {
          this.Tenders.push(element)
        });
      });
    }

    this.supplierTenderService.getArchivedSupplierTenders(this.selectedOrganizationID).subscribe((res) => {
      let temp: [] = res.tenderArchiveItems;
      temp.forEach(element => {
        this.Tenders.push(element)
      });
    })
  }

  onChange() {
    localStorage.setItem('selectedCustomerOrganizationID', this.selectedOrganizationID.toString());
    this.getArchivedTenders();
  }

  canChangeStatus(statusRules: any[], currentStatusID: number, newStatusID: number): boolean {
    if (statusRules.find(sr => sr.statusID === currentStatusID && sr.allowedStatusID === newStatusID)) {
      return true;
    }
    return false;
  }

  async onRestoreClicked(archivedItem: any) {

    if ((this.isBrokerUser && archivedItem.tenderType === "BrokerTender")) {
      if (this.canChangeStatus(this.StatusRules, archivedItem.statusId, AssignmentChildrenStatus.BrokerTenderDraft)) {
        await this.brokerTenderService.update(archivedItem.tenderId, { statusID: AssignmentChildrenStatus.BrokerTenderDraft }).toPromise();
      } else {
        this.dialogService.open(ErrorModalComponent, { data: { message: `Kunde inte avarkivera anbud, inte tillåtet` } })
      }

    } else if (archivedItem.tenderType === "SupplierTender") {
      if (this.canChangeStatus(this.StatusRules, archivedItem.statusId, AssignmentChildrenStatus.SupplierTenderReview)) {
        await this.supplierTenderService.update(archivedItem.tenderId, { statusId: AssignmentChildrenStatus.SupplierTenderReview }).toPromise();
      } else {
        this.dialogService.open(ErrorModalComponent, { data: { message: `Kunde inte avarkivera offerten, inte tillåtet` } })
      }
    }
    this.getArchivedTenders();
  }

}