import {FilterMatchMode} from "primeng/api";

export const environment = {
  production: true,
  accountJSON: 'assets/account.prod.json',
  apiUrl: "https://brm-ng-v2.azurewebsites.net/api/v1",
  hubUrl: "https://brm-ng-v2.azurewebsites.net/hub/v1",
  clientUrl: "https://brm2.swcg.com/#/",
  clientId: "7c68af50f54e",
  supportEmail: "brmsupport@sprinta.se",
  publishedCallOffsUrl: "https://swcg.com/uppdrag",
  applicationSettings: {
    validation: {
      emailValidationRegex: '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
      passwordValidationRegex: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!*()@%&]).{6,}$'
    },
    table: {
      defaultPageSize: 10,
      defaultNumericMatchMode: [
        { label: 'Equals', value: FilterMatchMode.EQUALS },
      ],
      defaultTextMatchMode: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
        { label: 'Starts with', value: FilterMatchMode.STARTS_WITH },
        { label: 'Ends with', value: FilterMatchMode.ENDS_WITH },
        { label: 'Equals', value: FilterMatchMode.EQUALS },
        { label: 'Not equals', value: FilterMatchMode.NOT_EQUALS },
        { label: 'Not contains', value: FilterMatchMode.NOT_CONTAINS }
      ],
      defaultDateMatchMode: [
        { label: 'Date is', value: FilterMatchMode.DATE_IS },
        { label: 'Date is not', value: FilterMatchMode.DATE_IS_NOT },
        { label: 'Date is before', value: FilterMatchMode.DATE_BEFORE },
        { label: 'Date is after', value: FilterMatchMode.DATE_AFTER }
      ]
    }
  }
};