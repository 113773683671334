<div class="p-col-12 p-lg-12">
    <p-card>    
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="p-field p-col-12">
                <label for="responsibleBroker">
                    Nuvarande ansvarig mäklare *
                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                        pTooltip="Användare hos Swedish Consulting Group som är ansvarig för kund"></i>
                    </span>                               
                </label>
                <p></p>
                <input id="responsibleBroker" [attr.disabled]="true" formControlName="responsibleBroker" type="text"
                    pInputText>
                <p></p>
                <label for="user">
                    Välj ansvarig mäklare *
                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                        pTooltip="Val av användare som är ansvarig för kund"></i>
                    </span>                               
                </label>
                <p></p>
                <p-dropdown
                [options]="brokerUsers"
                optionLabel="fullName"
                (onChange)="onChange($event)"
                [filter]="true"
                ></p-dropdown>
                <p></p>
            </div>
            <button pButton type="submit" iconPos="left"
                class="p-button p-button-raised p-text-uppercase"
                label="Spara"></button>
        </form>
    </p-card>
</div>
