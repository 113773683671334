import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AssignmentChildren } from "src/app/models/assignment-children/assignment-children.model";
import { BrokerTenderComment, CreateBrokerTenderCommentDTO } from "src/app/models/assignment-children/broker-tender.model";
import { BrokerTenderService } from "src/app/services/broker-tender.service";

@Component({
  selector: "app-tender-comments-modal",
  templateUrl: "./tender-comments-modal.html",
  styles: [],
})
export class TenderCommentsModalComponent implements OnInit{

  processing: boolean;
  invalidForm: boolean;
  commentDTO: CreateBrokerTenderCommentDTO;
  form: FormGroup;
  errors: string[];
  assignmentChild: AssignmentChildren;
  tenderComments: BrokerTenderComment[];

  constructor(
    private builder: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private brokerTenderService: BrokerTenderService
    ) {}

  ngOnInit(): void {
    
    this.assignmentChild = this.config.data.assignmentChild;

    this.form = this.builder.group({
      brokerTenderId: [this.assignmentChild.assignmentChildID],
      comment: ["", [Validators.required]],
      author: [JSON.parse(<string>localStorage.getItem('user')).name],     
   });

    if(this.assignmentChild.assignmentChildType === 'BrokerTender'){
      this.brokerTenderService.getBrokerTenderComments(this.assignmentChild['assignmentChildID']).subscribe(res =>{
        this.tenderComments = res['comments'];
        this.tenderComments.reverse();
      })
    } 
    else if(this.assignmentChild.assignmentChildType === 'SupplierTender'){
      //TODO: add SupplierTenderComments inmplementation
      //TODO: move declaration of variables the if statements and genaralize
      //the implementation to accommodate both supplier- and brokertenders
    }    
  }

  onCreateClicked(comment: string){
    this.invalidForm = false;
    this.commentDTO = this.form.value;
    if (this.processing){
      return;
    }

    this.processing = true;

    if (this.form.invalid){
      this.invalidForm = true;
      this.processing = false;
      return;
    }

    this.brokerTenderService.createBrokerTenderComments(this.commentDTO).subscribe(
      (res) => {
        this.tenderComments = res['comments'];
        this.tenderComments.reverse();
        this.form.get('comment')?.setValue('');
        this.form.markAsPristine();
        this.processing = false;
      },
      (error) => {
        this.processing = false;
      }
      );
  }
}