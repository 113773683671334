import { Component, OnInit } from '@angular/core';
import { SharedResume } from 'src/app/models/resume/resume.model';
import { ResumeService } from 'src/app/services/resume.service';
import { MenuItem, LazyLoadEvent, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { ContextMenu } from 'primeng/contextmenu';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmailModalComponent } from '../../email-modal/email-modal/email-modal.component';
import { SharedResumeEmailComponent } from '../shared-resume-email/shared-resume-email.component';
@Component({
  selector: 'app-manage-resumes',
  templateUrl: './manage-resumes.component.html',
  styles: [
  ]
})
export class ManageResumesComponent implements OnInit {
sharedResumeList: SharedResume[];
tableSettings: any = environment.applicationSettings.table;
items: MenuItem[];
kebabItems: MenuItem[];
selectedSharedResume: SharedResume
  constructor(
    private resumeService: ResumeService,
    private router: Router,
    private clipboard: Clipboard,
    private dialogService: DialogService,

  ) { }

  ngOnInit(): void {
    this.resumeService.getSharedResumes().subscribe(res => {
      console.log(res);
      this.sharedResumeList = res;
      // this.sharedResumeList.forEach(resume => {
      //   resume.messageToCustomer = resume.messageToCustomer.innerHTML;
      // })
      console.log(this.sharedResumeList);
    })
    this.items = [
      {
        label: 'Kopiera länk',
        command: () => this.clipboard.copy(this.selectedSharedResume.linkUrl)
      }
    ]
    this.kebabItems = [
      {
        label: 'Skicka till anbudskontakt',
      },
      {
        label: 'Skicka manuellt',
        command: () => this.openEmailModal()
      },
      {
        label: 'Visa delningshistorik',
        // command: () => this.openEmailModal()
      },
      {
        label: 'Redigera',
        command: () => this.router.navigate([`shared-resume-edit/${this.selectedSharedResume.sharedResumeID}`])
      },
      {
        label: 'Aktivera/Deaktivera',
        command: () => this.sharedResumeActiveStatus(this.selectedSharedResume.sharedResumeID)
      },
      {
        label: 'Ta bort',
        command: () => 
        // console.log(this)
        this.archiveSharedResume(this.selectedSharedResume.sharedResumeID)
      }
    ]
  }

  openEmailModal() {
    this.dialogService.open(SharedResumeEmailComponent, {
      header: 'Dela CV',
      width:'40%',
      data: {
        sharedResume: this.selectedSharedResume
      }
    });
  }

  loadBrokerCallOffs(event: LazyLoadEvent) {
    const { first, filters, sortField, sortOrder } = event;
    // this.pageIndex = (<number>first / 10) + 1;
  }

  onCreateSharedResumeClicked() {
    this.router.navigate(['shared-resume-create']);
  }

  sharedResumeActiveStatus(sharedResumeId: number) {
    this.resumeService.sharedResumeActiveStatus(sharedResumeId).subscribe(res => {
      console.log(res);
      this.resumeService.getSharedResumes().subscribe(res => {
        console.log(res);
        this.sharedResumeList = res;
        console.log(this.sharedResumeList);
      })
    })
  }

  archiveSharedResume(sharedResumeId: number) {
    this.resumeService.archiveSharedResume(sharedResumeId).subscribe(res => {
      console.log(res);
      this.resumeService.getSharedResumes().subscribe(res => {
        console.log(res);
        this.sharedResumeList = res;
        console.log(this.sharedResumeList);
      })
    })
  }

  getSharedResumeReceiverHistory(sharedResumeId: number) {
    this.resumeService.getSharedResumeReceiverHistory(sharedResumeId).subscribe(res => {
      console.log(res);
    })
  }
}
