import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorModalComponent } from 'src/app/components/shared/error-modal/error-modal/error-modal.component';
import { BrokerCallOffListItem, CreateBrokerCallOffDTO } from 'src/app/models/assignment-children/broker-call-off.model';
import { CustomerCallOffListItem } from 'src/app/models/assignment-children/customer-call-off.model';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { AssignmentChildrenStatus } from 'src/app/models/status/status.enum';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { BrokerCallOffService } from 'src/app/services/broker-call-off.service';
import { CustomerCallOffService } from 'src/app/services/customer-call-off.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PermissionService } from 'src/app/services/permission.service';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-broker-call-off-archive-list',
  templateUrl: './broker-call-off-archive-list.component.html',
  styles: [
  ]
})


export class BrokerCallOffArchiveListComponent implements OnInit {
  public organizationId: any;
  tableSettings: any = environment.applicationSettings.table;
  brokerCallOffs: BrokerCallOffListItem[];
  customerCallOffs: CustomerCallOffListItem[];
  user: UserAuthenticateResponse;
  isBrokerUser: boolean;
  customers: any = [];
  CallOffs: ArchivedCallOffListItemDTO[] = [];
  StatusRules: any[];
  selectedOrganizationID: number;
  updateStatusDto: { assignmentChildTypeName: any; assignmentChildID: any; currentStatus: any; newStatusID: number; };



  constructor(
    private brokerCallOffService: BrokerCallOffService,
    private customerCallOffService: CustomerCallOffService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private auth: AuthService,
    private permissionService: PermissionService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUserFromStorage();

    const storedOrganizationID = localStorage.getItem('selectedCustomerOrganizationID');
    if (storedOrganizationID) {
      this.selectedOrganizationID = +storedOrganizationID;
    } else {
      this.selectedOrganizationID = 0;
    }

    if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
      this.isBrokerUser = true;
    }

    this.permissionService.getStatusRules(parseInt(this.user.organizationTypeID)).subscribe((res) => {
      this.StatusRules = res;
    })

    this.organizationId = this.route.snapshot.paramMap.get("organizationID");
    if (this.isBrokerUser) {
      this.organizationService.getOrganizationsByType(OrganizationType.customer).subscribe(
        (response: any) => {
          this.customers = response.organizations;
          this.customers = sortAlphabetically(this.customers, 'organizationName');
          this.selectedOrganizationID = this.selectedOrganizationID === 0 ? this.customers[0].organizationID : this.selectedOrganizationID;
          this.onChange();
        },
        (_error) => {
        }
      );
    }
  }

  getArchivedCallOffs() {
    this.CallOffs = [];
    this.customerCallOffs = [];
    this.brokerCallOffs = [];
    if (this.isBrokerUser) {
      this.brokerCallOffService.getArchivedBrokerCallOffs(this.selectedOrganizationID).subscribe(res => {
        this.brokerCallOffs = res.brokerCallOffs;
        let temp = this.brokerCallOffs.map<ArchivedCallOffListItemDTO>((bco) => {
          return {
            CallOffID: bco.brokerCallOffID,
            CallOffType: "BrokerCallOff",
            CallOffTitle: bco.header,
            CallOffReference: bco.assignmentReference,
            CallOffCreated: bco.created,
            CallOffModified: bco.lastModified,
            CallOffStatus: bco.statusID,
          }
        })
        temp.forEach(item => {
          this.CallOffs.push(item);
        })
      });
    }

    this.customerCallOffService.getArchivedCallOffs(this.selectedOrganizationID).subscribe(res => {
      this.customerCallOffs = res.customerCallOffs;

      let temp = this.customerCallOffs.map<ArchivedCallOffListItemDTO>((cco) => {
        return {
          CallOffID: cco.customerCallOffID,
          CallOffType: "CustomerCallOff",
          CallOffTitle: cco.header,
          CallOffReference: cco.assignmentReference,
          CallOffCreated: cco.created,
          CallOffModified: cco.modifiedDate,
          CallOffStatus: cco.statusID,
        }
      })

      temp.forEach(item => {
        this.CallOffs.push(item);
      });

    });

  }


  onChange() {
    localStorage.setItem('selectedCustomerOrganizationID', this.selectedOrganizationID.toString());
    this.getArchivedCallOffs();
  }

  canChangeStatus(statusRules: any[], currentStatusID: number, newStatusID: number): boolean {
    if (statusRules.find(sr => sr.statusID === currentStatusID && sr.allowedStatusID === newStatusID)) {
      return true;
    }
    return false;
  }

  async onRestoreClicked(archivedItem: ArchivedCallOffListItemDTO) {

    if ((this.isBrokerUser && archivedItem.CallOffType === "BrokerCallOff")) {
      if (this.canChangeStatus(this.StatusRules, archivedItem.CallOffStatus, AssignmentChildrenStatus.CallOffCloseRequest)) {
        await this.brokerCallOffService.update(archivedItem.CallOffID, { statusID: AssignmentChildrenStatus.CallOffCloseRequest }).toPromise();
      } else {
        this.dialogService.open(ErrorModalComponent, { data: { message: `Kunde inte avarkivera avrop, inte tillåtet` } })
      }

    } else if (archivedItem.CallOffType === "CustomerCallOff") {
      if (this.canChangeStatus(this.StatusRules, archivedItem.CallOffStatus, AssignmentChildrenStatus.CustomerCallOffDraft)) {
        await this.customerCallOffService.update(archivedItem.CallOffID, { statusId: AssignmentChildrenStatus.CustomerCallOffDraft }).toPromise();
      } else {
        this.dialogService.open(ErrorModalComponent, { data: { message: `Kunde inte avarkivera avrop, inte tillåtet` } })
      }
    }
    this.getArchivedCallOffs();
  }

}

export interface ArchivedCallOffListItemDTO {
  CallOffID: number
  CallOffType: string
  CallOffTitle: string
  CallOffReference: string
  CallOffCreated: string
  CallOffModified: string
  CallOffStatus: any
}