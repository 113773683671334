import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { BaseService } from "./base.service";
import { BrokerCallOffList, BrokerCallOffListItem, CreateBrokerCallOff, UpdateBrokerCallOff, BrokerCallOff } from "../models/assignment-children/broker-call-off.model";
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: 'root'
})

export class BrokerCallOffService extends BaseService<BrokerCallOffListItem, BrokerCallOffList, CreateBrokerCallOff, UpdateBrokerCallOff> {
    endpointBaseURL = 'brokercalloff';

    getBrokerCallOffs(organizationTypeId: number): Observable<any> {
        return this.http.get(`${this.endpointBaseURL}/view?userType=${organizationTypeId}`);
    }

    getBrokerCallOffWithSupplierTenderId(supplierTenderId: number): Observable<any> {
        return this.http.get(`${this.endpointBaseURL}/BrokerCallOffWithSupplierTenderId/${supplierTenderId}`);
    }

    getSupplierTendersWithBrokerCallOffId(brokerCalloffId: number): Observable<any> {
        return this.http.get(`${this.endpointBaseURL}/${brokerCalloffId}/SupplierTenders`);
    }

    getArchivedBrokerCallOffs(organizationId?: number | string): Observable<any> {
        return this.http.get(`${this.endpointBaseURL}/ArchivedBrokerCallOffs?organizationID=${organizationId}`)
    }
}