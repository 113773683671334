import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styles: [
  ]
})
export class UnsubscribeComponent implements OnInit {
  token: string;
  email: string;
  userMessage: string;
  isError: boolean = false;
  processing: boolean = false;
  unsubscribeAuthenticated = '/subscription/unsubscribe';
  unsubscribe = '/login/subscription/unsubscribe';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private cdRef: ChangeDetectorRef,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.processing = this.route.snapshot.queryParamMap.get('processing') === 'true';
    this.token = String(this.route.snapshot.paramMap.get('token'));
    this.email = String(this.route.snapshot.paramMap.get('email'));

    if (this.processing) {
      if (this.authService.isAuthenticated()) {
        this.router.navigate([this.unsubscribeAuthenticated, this.token, this.email], { queryParams: { processing: 'false' } });
      } else {
        this.router.navigate([this.unsubscribe, this.token, this.email], { queryParams: { processing: 'false' } }).then(() => {
          window.location.reload();
        });
      }
    } else{
        this.subscriptionService.deleteSubscriber(this.token, this.email).subscribe(res => {
        this.userMessage = "Ni har nu blivit avprenumererad";
        this.isError = false;
      },
        err => {
          this.userMessage = "Något gick fel med avprenumerationen";
          this.isError = true;
          this.cdRef.detectChanges();
        });
    }
  }
  onShowLogin(): void {
    this.router.navigate(['login']);
  }
  onShowBrokerCalloffs(): void {
    this.router.navigate(['broker-call-off-list']);
  }
}