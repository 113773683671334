<div class="p-grid p-justify-center">
    <div class="p-col-12 p-lg-7">
        <p-card header="Leverantörens Information">
            <div class="p-mt-4">
                <div class="p-field" *ngIf="assignmentChild?.supplierUserFullName">
                    <p class="p-text-bold">Konsulten:</p>
                    <p>{{assignmentChild.supplierUserFullName}}</p>
                </div>
                <div class="p-field" *ngIf="assignmentChild?.supplierContactFullName">
                    <p class="p-text-bold">Kontaktperson:</p>
                    <p>{{assignmentChild.supplierContactFullName}}</p>
                </div>
                <div class="p-field" *ngIf="assignmentChild?.supplierContactEmail">
                    <p class="p-text-bold">Kontaktpersonens Email:</p>
                    <p>{{assignmentChild.supplierContactEmail}}</p>
                </div>
                <div class="p-field" *ngIf="assignmentChild?.supplierContactPhoneNumber">
                    <p class="p-text-bold">Kontaktpersonens Telefon:</p>
                    <p>{{assignmentChild.supplierContactPhoneNumber}}</p>
                </div>
                <div class="p-field" *ngIf="assignmentChild?.supplierOrganizationName">
                    <p class="p-text-bold">Organisationen:</p>
                    <p>{{assignmentChild.supplierOrganizationName}}</p>
                </div>
                <div class="p-field" *ngIf="assignmentChild?.comment">
                    <p class="p-text-bold">Kommentar:</p>
                    <textarea rows="6" cols="50" pInputTextarea readonly
                        style="overflow-y: auto; height: 200px; width: 100%;">{{assignmentChild.comment}}</textarea>
                </div>
            </div>
        </p-card>
    </div>
</div>