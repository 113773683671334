import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemComponent } from './system/system.component';
import {PrimeModule} from "../../prime-module.ts";



@NgModule({
  declarations: [
    SystemComponent
  ],
  imports: [
    CommonModule,
    PrimeModule
  ]
})
export class SystemModule { }
