import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { createOrganizationSettings, organizationSettings } from 'src/app/models/organization-settings/organization-settings';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { Organization } from 'src/app/models/organization/organization.model';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { OrganizationSettingsService } from 'src/app/services/organization-settings-service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-organization-settings',
  templateUrl: './organization-settings.component.html',
  styles: [
  ]
})
export class OrganizationSettingsComponent implements OnInit {
  
  organizationService : OrganizationService;
  organizationSettingsService : OrganizationSettingsService;
  authService : AuthService;
  user : UserAuthenticateResponse;
  organizationId : number;
  organizationSettings : any;
  form : FormGroup;
  builder : FormBuilder;
  messageService: MessageService;
  createOrganizationSettings: createOrganizationSettings;

  isBroker : boolean;
  isCustomer: boolean;

  constructor(public OrganizationService: OrganizationService, public OrganizationSettingsService: OrganizationSettingsService, MessageService: MessageService, public AuthService: AuthService, public formbuilder : FormBuilder) { 
    this.organizationService = OrganizationService;
    this.organizationSettingsService = OrganizationSettingsService;
    this.authService = AuthService;
    this.builder = formbuilder;
    this.messageService = MessageService;
  }

  ngOnInit(): void {
    this.user = this.authService.getUserFromStorage();
    this.organizationId = Number(this.user.organizationID);

    this.form = this.builder.group({
      canSendCallOffIfNotResponsible: [false, [Validators.required]]
    })


    this.organizationService.get<Organization>(this.organizationId).subscribe((res) => {
      this.organizationSettings = res.settings;
      console.log(this.organizationSettings);

      if (this.organizationSettings){
        this.form.get('canSendCallOffIfNotResponsible')?.setValue(this.organizationSettings.canSendCallOffIfNotResponsible);
      }

      
    });
    
    if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
      this.isBroker = true;
    }
    if (Number(this.user.organizationTypeID) === OrganizationType.customer) {
      this.isCustomer = true;
    }
  }

  onSubmit() {

    if (this.organizationSettings){
    this.organizationSettingsService.updateOrganizationSettings(this.organizationSettings.organizationSettingsID,this.form.value).subscribe((res) => {
      if(res == true){
        this.messageService.add({
          key:'main',
          severity: 'success',
          detail: 'Sparat'
        })
      } else {
        this.messageService.add({
          key:'main',
          severity: 'Error',
          detail: 'Kunde inte spara inställningar'
        })
      }
    });
    
  }
  else {
    this.createOrganizationSettings = this.form.value;
    this.createOrganizationSettings.organizationID = this.organizationId;
    this.organizationSettingsService.create(this.createOrganizationSettings).subscribe(res => {
      console.log(res);
      if(res){
        this.messageService.add({
          key:'main',
          severity: 'success',
          detail: 'Sparat'
        })
      } else {
        this.messageService.add({
          key:'main',
          severity: 'Error',
          detail: 'Kunde inte spara inställningar'
        })
      }
    })
  }
  }

}
