import { createOptional } from '@angular/compiler/src/core';
import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Consultant } from 'src/app/models/consultant/consultant.model';
import { ConsultantService } from 'src/app/services/consultant.service';

@Component({
  selector: 'app-create-consultant',
  templateUrl: './create-consultant.component.html',
  styles: [
  ]
})
export class CreateConsultantComponent implements OnInit {

  processing: boolean;
  leavePageWarningEnabled: boolean = true;
  invalidForm: boolean;
  consultant: Consultant;
  consultantID: number;
  form: FormGroup;
  errors: string[];
  isEditMode: boolean;

  @Output() createConsultantSuccess = new EventEmitter<any>();
  @Input() supplierId: number;

  constructor(
    private builder: FormBuilder,
    private consultantService: ConsultantService,
    private messageService: MessageService,
    @Optional() public config: DynamicDialogConfig,
    @Optional() public DialogRef: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.form = this.builder.group({
      supplierID: [this.supplierId],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
    });
    if (this.config != null) {

      this.isEditMode = this.config.data.isEditMode;

      if (!this.isEditMode) {
        this.form.controls['supplierID'].setValue(this.config.data.supplierId);
      }
      else {
        this.consultant = this.config.data.consultant
        this.consultantID = this.config.data.consultantId;

        this.form.controls['supplierID'].setValue(this.consultant.supplierID),
          this.form.controls['firstName'].setValue(this.consultant.firstName),
          this.form.controls['lastName'].setValue(this.consultant.lastName),
          this.form.controls['email'].setValue(this.consultant.email),
          this.form.controls['phoneNumber'].setValue(this.consultant.phoneNumber)
      }
    }
    else {
      this.isEditMode = false;
    }
  }

  onCreateClicked() {
    this.leavePageWarningEnabled = false;
    this.invalidForm = false;
    this.consultant = this.form.value;
    if (this.processing) {
      return;
    }

    this.processing = true;

    if (this.form.invalid) {
      this.invalidForm = true;
      this.processing = false;
      return;
    }

    if (!this.isEditMode) {
      this.consultantService.create(this.consultant).subscribe(
        (response) => {
          this.processing = false;
          this.showCreatedToast();
          this.createConsultantSuccess.emit({ success: true, consultant: response });

          if (this.DialogRef != undefined) {
            this.DialogRef.close(true);
          }

        },
        (error) => {
          this.processing = false;
          this.leavePageWarningEnabled = true;
        }
      );
    } else {
      this.consultantService.update(this.consultantID, this.consultant).subscribe(
        (response) => {
          this.processing = false;
          this.createConsultantSuccess.emit({ success: true, consultant: response });
          this.DialogRef.close(true);
        },
        (error) => {
          this.processing = false;
          this.DialogRef.close(false);
        }
      );
    }
  }
  canDeactivate(confirmationMessage: string): boolean | Observable<boolean> | Promise<boolean> {
    if (this.leavePageWarningEnabled) {
      if (this.form.dirty) {
        return window.confirm(confirmationMessage);
      }
    }
    return true;
  }

  showCreatedToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Konsulten har skapats'
    }
    )
  }

}