import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { User } from 'src/app/models/user/user.model';
import { CustomerService } from 'src/app/services/customer.service';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal/error-modal.component';

@Component({
  selector: 'app-customer-broker-user-modal',
  templateUrl: './customer-broker-user-modal.component.html',
  styles: [
  ]
})
export class CustomerBrokerUserModalComponent implements OnInit {
  dialogref: DynamicDialogRef;
  form: FormGroup;
  preSelectedBrokerUser: string;
  brokerUsers: User[] = this.config.data.users;
  selectedBrokerUser: User;

  constructor(
    private dialogService: DialogService,
    public config: DynamicDialogConfig,
    private builder: FormBuilder,
    private customerService: CustomerService,
    private messageService: MessageService,
    public modalRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    console.log(this.brokerUsers);

    this.brokerUsers = sortAlphabetically(this.brokerUsers, 'fullName');
    if(this.config.data.responsibleBrokerUser){
      this.preSelectedBrokerUser = this.config.data.responsibleBrokerUser.fullName
    }
    else{
      this.preSelectedBrokerUser = "Det finns ingen ansvarig mäklare"
    }
    this.form = this.builder.group({
      responsibleBroker: [""],
    });

    this.form.get('responsibleBroker')?.setValue(this.preSelectedBrokerUser);
    this.selectedBrokerUser = this.brokerUsers[0];
  }

  onChange(e: any){
    this.selectedBrokerUser = e.value;
    console.log(this.selectedBrokerUser);
  }
  
  onSubmit(){
    if (this.preSelectedBrokerUser != this.selectedBrokerUser.fullName) {
      this.customerService.updateCustomer(this.config.data.customer.customerID, {
        CustomerID: this.config.data.customer.customerID,
        OrganizationID: this.config.data.customer.organizationID,
        ResponsibleBrokerUserID: this.selectedBrokerUser.userID,

      }).subscribe(res => {
        this.modalRef.close(true);
        this.showUpdatedToast();
        location.reload();
      },
      error => {
        this.dialogService.open(ErrorModalComponent,
          {
            header: "Något gick fel",
            data: {
              message: "Misslyckades att uppdatera ansvarig mäklare. Vänligen försök igen."
            }
          });
      })
    }
    else {
      this.dialogService.open(ErrorModalComponent, {
        header: "Samma ansvarig mäklare vald",
        data: {
          message: "Vänligen välj en ny ansvarig mäklare och försök igen."
        }
      })
    }
  }

  showUpdatedToast() {
    this.messageService.add({
      key:'main',
      severity: 'success',
      detail: 'Kunden har blivit tilldelad en ny ansvarig mäklare'
    })
  }
}
