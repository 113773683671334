import { FormBuilder, Validators } from '@angular/forms';
import { AssignmentTemplateDTO, CreateAssignmentTemplate } from './../../../models/assignment/assignment-template.model';
import { AssignmentTemplateService } from './../../../services/assignment-template-service';
import { Component, OnInit } from '@angular/core';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { Organization } from 'src/app/models/organization/organization.model';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal/error-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { UtilService } from 'src/app/services/util.service';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { Router } from "@angular/router";

@Component({
  selector: 'app-assignment-templates',
  templateUrl: './assignment-templates.component.html',
  styles: [
  ]
})
export class AssignmentTemplatesComponent implements OnInit {
  form: FormGroup;
  user: UserAuthenticateResponse;
  isBrokerUser: boolean = false;
  customers: Organization[] = [];
  selectedCustomer: Organization;
  selectedOrganizationID: number;
  assignmentTemplateDTO: AssignmentTemplateDTO;
  processing: boolean = false;
  createTemplate: CreateAssignmentTemplate
  selectedAssignmentTemplateId: number;
  
  constructor(
    private authService: AuthService, 
    private organizationService: OrganizationService,
    private assignmentTemplateService: AssignmentTemplateService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private dialogService: DialogService,
    private router: Router,
    private utilService: UtilService) { }

  ngOnInit(): void {
    this.user = this.authService.getUserFromStorage();
    if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
      this.isBrokerUser = true;
    }

    if(this.isBrokerUser){
      this.organizationService.getOrganizationsByType(OrganizationType.customer).subscribe(
        (response: any) => {
          this.customers = response.organizations;
          this.customers = sortAlphabetically(this.customers, 'organizationName');
        },
        (error) => {
          // alert("Inga funna kunder");
          this.dialogService.open(ErrorModalComponent,
            {
              header:"Något gick fel",
              data: {
                message:"Kunde inte hitta kundobjekt"
              }
            })
        }
      );

      this.form = this.formBuilder.group({
        organizationID: [""],
        template: [""],
        templateName: [""],
        description: ["", [Validators.required]]
      });
    }
    else{
      // alert("Användare måste vara broker för att få tillgång till denna sida");
      this.dialogService.open(ErrorModalComponent,
        {
          header:"Något gick fel",
          data: {
            message:"Användare saknar rätt behörighet"
          }
        })
    }
  }

  showCreated() {
    this.utilService.getAccount().subscribe(res => {
      console.log(res);
      console.log(res.brm_link_calloff);
    })
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: "Din mall har skapats"
    })
  }  
  onChange(e: any) {
    
    this.selectedOrganizationID = e.value;
    if(e.value !== null) {
      this.processing = true;
    this.assignmentTemplateService.getAssignmentTemplatesByOrganizationId(e.value).subscribe(
      (response: any) => {
        this.processing = false;
        this.assignmentTemplateDTO = response.assignmentTemplates[0];
        this.form.get('templateName')?.setValue(this.assignmentTemplateDTO.templateName);
        this.form.get('template')?.setValue(this.assignmentTemplateDTO.template);
        this.selectedAssignmentTemplateId = this.assignmentTemplateDTO.assignmentTemplateID;
        this.processing = false;
      },
      (error) => {
        this.dialogService.open(ErrorModalComponent, 
          {
            header: "Något gick fel",
            data: {
              text: "hej",
              message: "Inga mallar hittades för den valda kunden"
            }
          } )
        this.processing = false;
      }
    );
  }
  }
  
  onCreateClicked() {
    this.processing = true;
    this.createTemplate = this.form.value;
    this.createTemplate.organizationID = this.selectedOrganizationID;

    if (this.selectedAssignmentTemplateId){
      this.onSaveClicked();
      return;
    }

    this.assignmentTemplateService.createTemplate(this.createTemplate).subscribe(res => {
      console.log(res);
      this.showCreatedToast();
      this.processing = false;
    },
    (error) => {
      this.dialogService.open(ErrorModalComponent,
        {
          header:"Något gick fel",
          data: {
            message:"Misslyckades att skapa mallen. Vänligen försök igen."
          }
        })
    })
  }

  onSaveClicked() {
    this.assignmentTemplateService.updateTemplate(this.selectedAssignmentTemplateId, this.createTemplate).subscribe(res =>{
      console.log(res);
      this.showUpdatedToast();
      this.processing = false;
    },
    (error) => {
      this.dialogService.open(ErrorModalComponent,
        {
          header:"Något gick fel",
          data: {
            message:"Misslyckades att uppdatera mallen. Vänligen försök igen."
          }
        })
      this.processing = false;
    }
    
    )
  }

  onShowBrokerCalloffs(): void {
    this.router.navigate(['broker-call-off-list']);
  }

  showCreatedToast() {
    this.messageService.add({
      key:'main',
      severity: 'success',
      detail:'Mallen har skapats'
    })
  }
  showUpdatedToast() {
    this.messageService.add({
      key:'main',
      severity: 'success',
      detail:'Mallen har uppdaterats'
    })
  }

}
