import { Injectable } from '@angular/core';
import { SubscriptionModalComponent } from 'src/app/components/shared/subscription/subscription-modal.component';
import { DialogService} from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root'
})
export class ModalHandler {

  constructor(private dialogService: DialogService) {}

  public showSubscriptionModal() {
    this.dialogService.open(SubscriptionModalComponent,
      {
        header: "Prenumera på nya uppdrag",
        width: '13%',
        contentStyle: { "max-height": "500px", "overflow": "auto" }
      });
  }
}
