import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { AssignmentChildren, AssignmentChildrenResponse, ContactInfo, ResponsibleContactInfo } from 'src/app/models/assignment-children/assignment-children.model';
import { Board } from 'src/app/models/assignment-children/Board/board.enum';
import { LaneModel } from 'src/app/models/assignment-children/Board/lane.model';
import { StatusToAllowedStatus } from 'src/app/models/assignment-children/Board/status-to-allowed-status.model.ts/status-to-allowed-status.model.ts.component';
import { UpdateStatusDTO } from 'src/app/models/assignment-children/Board/update-status-dto';
import { Status } from 'src/app/models/assignment-children/status.model';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { Organization } from 'src/app/models/organization/organization.model';
import { Resume } from 'src/app/models/resume/resume.model';
import { StatusInfo } from 'src/app/models/status/status-info.model';
import { AssignmentChildrenStatus } from 'src/app/models/status/status.enum';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { AssignmentChildrenService } from 'src/app/services/assignment-children-service';
import { AssignmentService } from 'src/app/services/assignment.service';
import { AuthService } from 'src/app/services/auth.service';
import { BrokerCallOffService } from 'src/app/services/broker-call-off.service';
import { CallOffBoardHubService } from 'src/app/services/hub/call-off-board-hub.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PermissionService } from 'src/app/services/permission.service';
import { CreateBrokerCallOffModalComponent } from '../../assignment-children/broker-call-off/create-broker-call-off/create-broker-call-off-modal.component';
import { EmailModalComponent } from '../../email-modal/email-modal/email-modal.component';
import { PublishModalComponent } from '../../email-modal/publish-modal/publish-modal.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { CustomerCallOffService } from 'src/app/services/customer-call-off.service';
import { getTemplateFooter } from 'src/app/utils/template-footer';
import { NotificationReceiverDTO, Notification } from 'src/app/models/notification/notification.model';
import { generateContentString } from 'src/app/utils/template-string-replace';
import { NotificationService } from 'src/app/services/notification.service';
import { concatMap, map } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { UserContactInfo } from 'src/app/models/assignment/assignment.model';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal/error-modal.component';
import { AssignmentTemplateService } from 'src/app/services/assignment-template-service';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-call-off-board',
  templateUrl: './call-off-board.component.html',
  styles: [
  ]
})
export class CallOffBoardComponent implements OnInit {
 
  lanes: LaneModel[];
  assignmentChildren: AssignmentChildren[];
  selectedAssignmentChild: AssignmentChildren;
  statuses: Status[];
  statusInfos: StatusInfo[];
  statusRules: StatusToAllowedStatus[];
  token: string;
  updateStatusDto: UpdateStatusDTO;
  assignmentID: any;
  organizationID: number;
  resumes: Resume[];
  header: string;
  ref: string;
  changeText: boolean;
  user: UserAuthenticateResponse;
  showLane: boolean[];
  isBrokerUser: boolean = false;
  isCustomerUser: boolean = false;
  items: MenuItem[];
  dialogRef: DynamicDialogRef;
  continue: boolean;
  contactInfo: any;
  contactInfoList: ContactInfo[];
  responsibleContact: ResponsibleContactInfo;
  customers: Organization[] = [];
  selectedOrganizationID: number;
  notificationSubject: string;
  notificationContent: string;
  notificationReceivers: any[] = [];
  account: any;
  userToAssignment: UserContactInfo[];
  responsibleBrokerUser: UserContactInfo;
  organizationSettings: any;

  constructor(
    private permissionService: PermissionService,
    private authService: AuthService,
    private callOffBoardHub: CallOffBoardHubService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router,
    private assignmentChildrenService: AssignmentChildrenService,
    private confirmationService: ConfirmationService,
    private brokerCallOffService: BrokerCallOffService,
    private assignmentService: AssignmentService,
    private organizationService: OrganizationService,
    private customerCallOffService: CustomerCallOffService,
    private notificationService: NotificationService,
    private utilService: UtilService,
    private subscriptionService: SubscriptionService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.isBrokerUser = false;
    this.token = this.authService.getTokenFromStorage();
    this.user = this.getUserObject();
    this.lanes = [];
    this.showLane = [];
    this.changeText = false;
    this.responsibleBrokerUser = <UserContactInfo>{};

    const storedOrganizationID = localStorage.getItem('selectedCustomerOrganizationID');
    if (storedOrganizationID) {
      this.selectedOrganizationID = +storedOrganizationID;
    } else {
      this.selectedOrganizationID = 0;
    }

    this.utilService.getAccount().subscribe(res => {
      this.account = res;
    })

    this.items = [
      {
        label: 'Arkivera', icon: 'pi pi-fw pi-file-pdf',
        command: () => this.onArchiveCard(this.selectedAssignmentChild),
        visible: this.isBrokerUser || this.isCustomerUser
      },
      {
        label: 'Gå till anbudstavla', icon: 'pi pi-fw pi-file-pdf',
        command: () => this.onViewBrokerCallOffClicked(this.selectedAssignmentChild),
        visible: this.isBrokerUser || this.isCustomerUser
      },
      {
        label: 'Skicka påminnelse', icon: 'pi pi-fw pi-file-pdf',
        command: () => this.onEmailReminderToSubscribers(this.selectedAssignmentChild),
        visible: this.isBrokerUser || this.isCustomerUser
      },
    ];

    this.route.params.subscribe(params => {
      this.organizationID = params.organizationID;

      if (this.isBrokerUser) {
        this.organizationService.getOrganizationsByType(OrganizationType.customer).subscribe(
          (response: any) => {
            this.customers = response.organizations;
            this.customers = sortAlphabetically(this.customers, 'organizationName');
            this.selectedOrganizationID = this.selectedOrganizationID === 0 ? this.customers[0].organizationID : this.selectedOrganizationID;
            this.onChange();
          },
          (error) => {
          }
        );

      }
      else {
        this.selectedOrganizationID = this.organizationID;
        this.organizationService.get<Organization>(this.selectedOrganizationID).subscribe((res) => {
          this.organizationSettings = res.settings;
          if (this.selectedOrganizationID > 0) {
            this.callOffBoardHub.init(this.token)
              .then(async () => {
                this.callOffBoardHub.subscribeToCallOffGroup(Number(this.selectedOrganizationID), this.token = this.authService.getTokenFromStorage(),
                  (AssignmentChildrenResponse: AssignmentChildrenResponse) => this.onAssignmentChildren(AssignmentChildrenResponse.assignmentChildren));
                this.callOffBoardHub.connection.on("NotifyUpdateToHubGroup", data => {
                  this.callOffBoardHub.getAssignmentChildren(Number(this.selectedOrganizationID), this.token, (AssignmentChildrenResponse: AssignmentChildrenResponse) => this.onAssignmentChildren(AssignmentChildrenResponse.assignmentChildren));
                })
              })
          }
        })
      }
    });

    this.permissionService.getStatusRules(parseInt(this.user.organizationTypeID)).subscribe(res => {
      this.statusRules = res;
    })

    this.permissionService.getStatusInfos(Number(this.user.organizationTypeID), Board.Avrop).subscribe(res => {
      this.statusInfos = res;

      this.statusInfos = this.statusInfos.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.statusLaneName === value.statusLaneName
        ))
      )
      this.statusInfos.forEach(statusInfo => {
        this.lanes.push({ statusInfo });
        this.showLane.push(true);
      });
    })

  }

  onEmailReminderToSubscribers(assignmentChild: AssignmentChildren) {
    this.subscriptionService.EmailReminderToSubscribers(assignmentChild.assignmentID).subscribe(
      (response) => {
        this.messageService.add({
          key: 'main',
          severity: 'success',
          detail: `Email kommer att skickas inom en minut.`,
          life: 20000
        })
      },
      (error) => {
        this.messageService.add({
          key: 'main',
          severity: 'error',
          detail: 'Email har inte skickats.'
        });
      }
    );
    
  }

  

  getUserObject() {

    const updateUser = this.authService.getUserFromStorage();
    if (Number(updateUser.organizationTypeID) === OrganizationType.broker) {
      this.isBrokerUser = true;
    }
    if (Number(updateUser.organizationTypeID) === OrganizationType.customer) {
      this.isCustomerUser = true;
    }

    return updateUser;

  }

  getCustomerName(): String {
    const customerName = this.customers.find(x => x.organizationID === this.selectedOrganizationID)
    return customerName?.organizationName || `${this.selectedOrganizationID}`;
  }

  onChange() {
    localStorage.setItem('selectedCustomerOrganizationID', this.selectedOrganizationID.toString());
    this.callOffBoardHub.disconnect();
    if (this.isBrokerUser) {
      this.callOffBoardHub.init(this.token)
        .then(async () => {
          this.callOffBoardHub.subscribeToCallOffGroup(Number(this.selectedOrganizationID), this.token = this.authService.getTokenFromStorage(),
            (AssignmentChildrenResponse: AssignmentChildrenResponse) => this.onAssignmentChildren(AssignmentChildrenResponse.assignmentChildren));
          this.callOffBoardHub.connection.on("NotifyUpdateToHubGroup", data => {
            this.callOffBoardHub.getAssignmentChildren(Number(this.selectedOrganizationID), this.token, (AssignmentChildrenResponse: AssignmentChildrenResponse) => this.onAssignmentChildren(AssignmentChildrenResponse.assignmentChildren));
          })
        })
    }
  }
  toggleFooter(assignmentChild: AssignmentChildren) {
    assignmentChild.showFooter = !assignmentChild.showFooter;
  }

  isLaneAllowed(currentStatusId: number, laneStatusId: number) {
    return this.statusRules.some(x => x.statusID === currentStatusId && x.allowedStatusID === laneStatusId)
  }

  onDragStarted(assignmentChild: any) {
    this.showLane = [];
    this.lanes.forEach((lane) => {
      this.showLane.push(this.isLaneAllowed(assignmentChild.statusID, lane.statusInfo.statusID));
    });

    this.selectedAssignmentChild = assignmentChild;
  }

  private async updateAssignmentChildPosition(assignmentChildren: AssignmentChildren, statusID: number) {
    this.updateStatusDto = {
      assignmentChildTypeName: assignmentChildren.assignmentChildType,
      assignmentChildID: assignmentChildren.assignmentChildID,
      currentStatus: assignmentChildren.statusID,
      newStatusID: statusID
    };

    await this.callOffBoardHub.updateAssignmentChildStatus(this.updateStatusDto, () => { }, this.onNotifyUpdateToHubGroup, Number(this.selectedOrganizationID));
  }

  onArchiveCard(assignmentChild: AssignmentChildren) {
    //Check Permissions for archival
    var brokerCanArchiveCard = this.isBrokerUser && (assignmentChild.statusID != AssignmentChildrenStatus.BrokerCallOffPublished);
    var customerCanArchiveCard = this.isCustomerUser && (assignmentChild.statusID === AssignmentChildrenStatus.CustomerCallOffReview || assignmentChild.statusID === AssignmentChildrenStatus.CustomerCallOffDraft);

    if (customerCanArchiveCard || brokerCanArchiveCard) {
      this.updateAssignmentChildPosition(assignmentChild, AssignmentChildrenStatus.AssignmentArchived);
    } else {
      this.dialogService.open(ErrorModalComponent, { data: { message: `Kunde inte arkivera avrop från fält: ${assignmentChild.statusLaneName}` } })
    }
  }

  onViewBrokerCallOffClicked(brokerCallOff: AssignmentChildren) {
    if (brokerCallOff.assignmentChildType === 'BrokerCallOff')
      this.router.navigate([`/broker-tender-board/${brokerCallOff.assignmentChildID}`]);
  }

  checkFurtherActionRequired(newStatusID: number): Observable<boolean> {
    this.user = this.getUserObject();

    if (newStatusID === AssignmentChildrenStatus.CustomerCallOffSent) {

      if (!this.isBrokerUser) {

        return this.assignmentService.getUsers(this.selectedAssignmentChild.assignmentID).pipe(
          concatMap(x => {
            this.userToAssignment = x['userToAssignmentUsers'];
            const test = this.userToAssignment.find(x => x.userID === Number(this.user.userID))

            if (test?.isResponsible === true || this.organizationSettings.canSendCallOffIfNotResponsible) {
              return this.customerCallOffService.getCustomerCallOff(this.selectedAssignmentChild.assignmentChildID);
            }
            else {
              return of(false);
            }
          }),
          concatMap(res => {
            if (!res) {
              this.showResponsibleDialog();
              return of(false);

            }
            else {

              var object = res;
              var params = [
                { param: 'responsible_person', value: this.user.name },
                { param: 'responsible_email', value: this.user.email },
                { param: 'responsible_phone', value: this.user.phone },
                { param: 'request_customer', value: res.assignment.customerName },
                { param: 'request_title', value: res.assignment.header },
                { param: 'brm_link', value: `<a href=${this.account.brm_link_calloff}${this.user.organizationID}">Länk</a>` },
                { param: 'brm_banner', value: `<p><img src='https://brmngversion.blob.core.windows.net/images/swcg-email-banner.png'></p>` },
                { param: 'brm_footer', value: getTemplateFooter() }
              ];

              this.sendCallOffMailToBroker(object, params)
              return of(true);
            }
          })

        )
      }
      else {
        this.showResponsibleDialog();
        return of(false);
      }
    }

    if (newStatusID === AssignmentChildrenStatus.BrokerCallOffDraft) {
      this.dialogRef = this.dialogService.open(CreateBrokerCallOffModalComponent, {
        header: "Annons",
        width: '50%',
        data: {
          assignmentChild: this.selectedAssignmentChild
        }
      });
      return this.dialogRef.onClose.pipe(
        concatMap(res => {
          if (res) {
            return of(true);
          }
          else return of(false);
        }),
        map(res3 => {
          return res3;
        })
      )
    }

    if (newStatusID === AssignmentChildrenStatus.BrokerCallOffPublished) {
      this.dialogRef = this.dialogService.open(PublishModalComponent, {
        header: "Vill du publicera annonsen?",
        width: '40%',
        data: {
          notificationReceivers: receivers,
          notificationSubject: `Ert avrop: ${this.selectedAssignmentChild.header} är nu publicerat`,
          assignmentID: this.selectedAssignmentChild.assignmentID,
          assignmentChildID: this.selectedAssignmentChild.assignmentChildID,
          eventID: 4,
          assignmentChild: this.selectedAssignmentChild,
          newStatusID: newStatusID
        }
      });
      return this.dialogRef.onClose;
    }

    if (newStatusID === AssignmentChildrenStatus.CallOffCloseRequest) {
      this.dialogRef = this.dialogService.open(PublishModalComponent, {
        header: "Vill du avpublicera?",
        width: '40%',
        data: {
          notificationReceivers: receivers,
          notificationSubject: `Ert Avrop: ${this.selectedAssignmentChild.header} är inte längre publicerat på vår hemsida`,
          assignmentID: this.selectedAssignmentChild.assignmentID,
          assignmentChildID: this.selectedAssignmentChild.assignmentChildID,
          eventID: 5,
          assignmentChild: this.selectedAssignmentChild
        }
      });
      return this.dialogRef.onClose;
    }

    if (newStatusID === AssignmentChildrenStatus.CallOffClosed) {
      this.dialogRef = this.dialogService.open(EmailModalComponent, {
        header: "Vill du stänga avrop?",
        width: '40%',
        data: {
          notificationReceivers: receivers,
          notificationSubject: `Avrop: ${this.selectedAssignmentChild.header} är nu stängt`,
          assignmentID: this.selectedAssignmentChild.assignmentID,
          assignmentChildID: this.selectedAssignmentChild.assignmentChildID,
          eventID: 8,
          mailType: 'customer',
          assignmentChild: this.selectedAssignmentChild
        }
      });
      return this.dialogRef.onClose;
    }

    if (newStatusID === AssignmentChildrenStatus.CallOffCompleted) {
      this.dialogRef = this.dialogService.open(EmailModalComponent, {
        header: "Vill du stänga avrop?",
        width: '40%',
        data: {
          notificationReceivers: receivers,
          notificationSubject: `Avrop: ${this.selectedAssignmentChild.header} är nu stängt`,
          assignmentID: this.selectedAssignmentChild.assignmentID,
          assignmentChildID: this.selectedAssignmentChild.assignmentChildID,
          eventID: 8,
          mailType: 'customer',
          assignmentChild: this.selectedAssignmentChild
        }
      });
      return this.dialogRef.onClose;
    }

    if (newStatusID === AssignmentChildrenStatus.BrokerTenderInterviewRequest) {
      var receivers: any = [];
      this.assignmentChildrenService.getUserToAssignmentInfo(this.assignmentID)
        .subscribe(res => {
          for (let i = 0; i < res.userToAssignmentUsers.length; i++) {
            receivers.push(res.userToAssignmentUsers[i]);
          }
        },
          (error) => {
            console.log(error);
          }
        )
      this.dialogRef = this.dialogService.open(EmailModalComponent, {
        header: "Send email",
        width: '60%',
        height: '60%',
        data: {
          notificationReceivers: receivers,
          notificationSubject: 'Intervju önskas',
          notificationContent: 'Innehåll till Intervju önskas',
          assignmentChild: this.selectedAssignmentChild,
          required: true
        }
      });
      return of(true);
    }
    if (newStatusID === AssignmentChildrenStatus.BrokerTenderInterview) {
      return of(true);
    }
    if (newStatusID === AssignmentChildrenStatus.BrokerTenderNegotiate) {
      return of(true);
    }
    if (newStatusID === AssignmentChildrenStatus.BrokerTenderAccepted) {
      return of(true);
    }

    if (newStatusID === AssignmentChildrenStatus.BrokerTenderRejectedCustomer) {

      return of(true);
    }
    if (newStatusID === AssignmentChildrenStatus.CallOffCloseRequest) {
      return of(true);
    }
    if (newStatusID === AssignmentChildrenStatus.CallOffClosed) {
      return of(true);
    }
    if (newStatusID === AssignmentChildrenStatus.CallOffCompleted) {
      return of(true);
    }
    if (newStatusID === AssignmentChildrenStatus.AssignmentArchived) {
      this.dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
        data: {
          message: "Är du säker på att du vill stänga avropet, arkivera samtliga anbud och skicka mail till berörda parter?",
        }
      });
      return this.dialogRef.onClose
    }
    else {
      return of(true);
    }
  }

  private resetShowLane(): void {
    this.showLane = [];
    this.statusInfos.forEach(() => {
      this.showLane.push(true)
    });
  }

  async onDrop(event: CdkDragDrop<AssignmentChildren[]>, statusID: number) {
    try {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.resetShowLane();
      }
      else {
        if (this.rejectCustomerCancel(this.selectedAssignmentChild) && statusID === AssignmentChildrenStatus.CallOffClosed) {

          this.assignmentService.getResponsibleUsers(this.selectedAssignmentChild.assignmentID).subscribe(res => {
            this.responsibleBrokerUser = res['responsibleBrokerUser'];
            this.dialogService.open(ErrorModalComponent,
              {
                header: "Något gick fel",
                data: {
                  message: `Du får inte lov att avbryta avropet på egen hand. Vänligen
                tag kontakt med ansvarig KAM hos Swedish Consulting Group: 
                ${this.responsibleBrokerUser.email}`
                }
              })
            this.resetShowLane();
          })

        }
        else {
          if (this.isLaneAllowed(event.previousContainer.data[event.previousIndex].statusID, statusID)) {
            this.checkFurtherActionRequired(statusID).subscribe((res => {
              if (res) {
                transferArrayItem(event.previousContainer.data,
                  event.container.data,
                  event.previousIndex,
                  event.currentIndex);
                this.updateAssignmentChildPosition(event.container.data[event.currentIndex], statusID).then(x => {
                  this.resetShowLane();
                });
              }
              this.resetShowLane();
            }))
          }
          else this.resetShowLane();
        }
      }
    }
    catch (error: any) {
      this.resetShowLane();
      throw new Error(error);
    }
  }

  private async onNotifyUpdateToHubGroup(assignmentChildren: any[]) {
  }
  private async onAssignmentChildren(assignmentChildren: any[]) {
    this.lanes.forEach(l => {
      l.assignmentChildren = assignmentChildren.filter(ac => ac.statusLaneName === l.statusInfo.statusLaneName);
    });
  }

  sendCallOffToBroker(assignmentChild: AssignmentChildren) {

  }

  sendCallOffMailToBroker(assignment: any, params: any) {
    this.notificationSubject = `Nytt avrop: ${assignment.assignment.header}  från  ${assignment.assignment.customerName}`;
    let responsibleBrokerReceiver: NotificationReceiverDTO;
    let organizationId = 1;
    if (Number(this.user.organizationTypeID) == (OrganizationType.customer || OrganizationType.customerSupplier)) {
      organizationId = Number(this.user.organizationID);
    }
    else {
      organizationId = this.selectedOrganizationID;
    }

    this.organizationService.getCustomerByOrganizationID(organizationId).subscribe(customer => {

      if (customer.responsibleBrokerUser != null) {
        responsibleBrokerReceiver = {
          notificationReceiverFirstName: customer.responsibleBrokerUser.firstName,
          notificationReceiverLastName: customer.responsibleBrokerUser.lastName,
          notificationReceiverEmail: customer.responsibleBrokerUser.email
        };
        this.notificationReceivers.push(responsibleBrokerReceiver);

        this.responsibleBrokerUser.userID = customer.responsibleBrokerUser.userID;
        this.responsibleBrokerUser.organizationID = customer.responsibleBrokerUser.organizationID;
        this.responsibleBrokerUser.fullName = `${customer.responsibleBrokerUser.firstName} ${customer.responsibleBrokerUser.lastName}`;
        this.responsibleBrokerUser.email = customer.responsibleBrokerUser.email;
        this.responsibleBrokerUser.phoneNumber = customer.responsibleBrokerUser.phoneNumber;
      }
      else {
        this.responsibleBrokerUser.fullName = `${this.account.defaultBroker.firstName} ${this.account.defaultBroker.lastName}`;
        this.responsibleBrokerUser.email = this.account.defaultBroker.email;
        this.responsibleBrokerUser.phoneNumber = this.account.defaultBroker.phoneNumber;
      }


      this.organizationService.getOrganizationTemplateWithEventID(1).subscribe(organization => {
        this.notificationContent = organization.contentString;
        this.notificationContent = generateContentString(this.notificationContent, params);

        var accountReceiver: NotificationReceiverDTO = {
          notificationReceiverFirstName: "",
          notificationReceiverLastName: "",
          notificationReceiverEmail: this.account.email
        };

        this.notificationReceivers.push(accountReceiver);

        const notification: Notification = {
          notificationSubject: this.notificationSubject,
          notificationContent: this.notificationContent,
          notificationData: {
            notificationContent: "avrop"
          },
          notificationReceivers: this.notificationReceivers
        };

        this.notificationService.postNotification(notification).subscribe(res => {
          this.sendCallOffConfirmationMailToCustomer(assignment);
        });
      });
    });
  }



  sendCallOffConfirmationMailToCustomer(assignment: any) {
    this.user = this.getUserObject();
    this.notificationSubject = `Tack för avrop: ${assignment.assignment.header}  från  ${assignment.assignment.customerName}`;
    this.organizationService.getOrganizationTemplateWithEventID(3).pipe(map(res => {
      this.notificationContent = res.contentString;

      var params = [
        { param: 'responsible_person', value: this.responsibleBrokerUser.fullName },
        { param: 'responsible_email', value: this.responsibleBrokerUser.email },
        { param: 'responsible_phone', value: this.responsibleBrokerUser.phoneNumber },
        { param: 'request_customer', value: assignment.assignment.customerName },
        { param: 'request_title', value: assignment.assignment.header },
        { param: 'brm_link', value: `<a href=${this.account.brm_link_calloff}${this.user.organizationID}">Länk</a>` },
        { param: 'website_requests_url', value: `<a href=${this.account.website_requests_url}>Uppdrag</a>` },
        { param: 'brm_banner', value: `<p><img src='https://brmngversion.blob.core.windows.net/images/swcg-email-banner.png'></p>` },
        { param: 'brm_footer', value: getTemplateFooter() }
      ];

      this.notificationContent = generateContentString(this.notificationContent, params);

      var receiver: NotificationReceiverDTO = {
        notificationReceiverFirstName: this.user.name,
        notificationReceiverLastName: "",
        notificationReceiverEmail: this.user.email
      }

      var receivers = [];

      for (let i = 0; i < this.userToAssignment.length; i++) {
        if (this.userToAssignment[i].isResponsible) {
          var receiver: NotificationReceiverDTO = {
            notificationReceiverFirstName: this.userToAssignment[i].fullName,
            notificationReceiverLastName: "",
            notificationReceiverEmail: this.userToAssignment[i].email
          }
          receivers.push(receiver);
        }
      }

      this.notificationReceivers = receivers;

      const notification: Notification = {
        notificationSubject: this.notificationSubject,
        notificationContent: this.notificationContent,
        notificationData: {
          notificationContent: "tack för avrop"
        },
        notificationReceivers: this.notificationReceivers
      }
      return this.notificationService.postNotification(notification).subscribe(res => {
        console.log(res);
      })
    })
    ).subscribe(res => {
      console.log(res);
    })
  }

  viewCalloff(assignmentChild: any): void {
    const ref = this.dialogService.open(CreateBrokerCallOffModalComponent, {
      header: "Avrop",
      width: '50%',
      data: { assignmentChild: assignmentChild, mode: 'view' }
    });

    ref.onClose.subscribe((result) => {
      if (result) {
        // Handle the result if the modal was closed with a result (e.g., form submission)
      } else {
        // Handle the case if the modal was closed without a result (e.g., cancel button)
      }
    });
    console.log(assignmentChild);
  }

  showResponsibleDialog() {
    this.dialogService.open(ErrorModalComponent,
      {
        header: "Något gick fel",
        data: {
          message: "Du får inte skicka avropet till Swedish Consulting Group. Vänligen tag kontakt med ansvarig beställare."
        }
      })
  }

  rejectCustomerCancel(assignmentChild: AssignmentChildren) {
    if (!this.isBrokerUser && assignmentChild.statusID !== AssignmentChildrenStatus.CustomerCallOffDraft) {
      return true;
    }
    else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.callOffBoardHub.disconnect();
  }
}
