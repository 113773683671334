import { UserToAssignmentModule } from './user-to-assignment/user-to-assignment.module';
import { EmailModalModule } from './email-modal/email-modal.module';
import { ResumeViewModule } from './resume-view/resume-view.module';
import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {OrganizationModule} from "./organization/organization.module";
import {SharedModule} from "primeng/api";
import {UserModule} from "./user/user.module";
import {LayoutModule} from "./layout/layout.module";
import {AuthModule} from "./auth/auth.module";
import {RoleModule} from "./role/role.module";
import {DashboardModule} from "./dashboard/dashboard.module";
import {SystemModule} from "./system/system.module";
import { AssignmentBoardModule } from './assignment-board/assignment-board.module';
import { SharedComponentsModule } from './shared/shared-components.module';
import { BrokerCallOffModule } from './assignment-children/broker-call-off/broker-call-off.module';
import { PrimeModule } from '../prime-module.ts';
import { SupplierTenderModule } from './assignment-children/supplier-tender/supplier-tender.module';
import { CreateConsultantComponent } from './assignment-children/create-consultant/create-consultant.component';
import { CreateSupplierTenderComponent } from './assignment-children/supplier-tender/create-supplier-tender/create-supplier-tender.component';
import { CreateBrokerTenderComponent } from './assignment-children/broker-tender/create-broker-tender/create-broker-tender.component';
import { CustomerCallOffModule } from './assignment-children/customer-call-off/customer-call-off.module';
import { ContractModule } from './contract/contract.module';
import { ResumeEditComponent } from './resume-edit/resume-edit.component';
import { SubscriptionModalComponent } from 'src/app/components/shared/subscription/subscription-modal.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { UnsubscribeComponent } from 'src/app/components/shared/subscription/unsubscribe.component';
import { UnsubscribeEnterEmailComponent } from 'src/app/components/shared/subscription/unsubscribe-enter-email.component';
import { SubscribeComponent } from 'src/app/components/shared/subscription/subscribe.component';
import { ViewBrokerCalloffSupplierModalComponent } from 'src/app/components/assignment-children/supplier-tender/view-broker-call-off-supplier-modal/view-broker-call-off-supplier-modal.component';

@NgModule({
    declarations: [
    CreateSupplierTenderComponent,
    CreateConsultantComponent,
    CreateBrokerTenderComponent,
    ViewBrokerCalloffSupplierModalComponent,
    SubscriptionModalComponent,
    ResumeEditComponent,
    UnsubscribeComponent,
    UnsubscribeEnterEmailComponent,
    SubscribeComponent
  ],
    imports: [
        CommonModule,
        AuthModule,
        OrganizationModule,
        DashboardModule,
        LayoutModule,
        SharedModule,
        SharedComponentsModule,
        SystemModule,
        UserModule,
        RoleModule,
        AssignmentBoardModule,
        BrokerCallOffModule, 
        CustomerCallOffModule,
        SupplierTenderModule,       
        PrimeModule,
        EmailModalModule,
        UserToAssignmentModule,
        ContractModule,
        MultiSelectModule
    ],
    exports: [
    ]
})

export class ComponentsModule { }
