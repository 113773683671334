import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { CreateSubscriberDTO } from 'src/app/models/subscription/create-subscriber.model';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styles: [
  ]
})

export class SubscribeComponent implements OnInit {
  token: string;
  email: string;
  userMessage: string;
  isError: boolean = false;
  processing: boolean = false;
  regions: number[];
  subscribeAuthenticated = '/subscription/subscribe';
  subscribe = '/login/subscription/subscribe';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private cdRef: ChangeDetectorRef,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.processing = this.route.snapshot.queryParamMap.get('processing') === 'true';
    let regionsQueryParam: string | null;
    regionsQueryParam = this.route.snapshot.queryParamMap.get('regions');
    this.token = String(this.route.snapshot.paramMap.get('token'));
    this.email = String(this.route.snapshot.paramMap.get('email'));

    if (regionsQueryParam) {
      this.regions = regionsQueryParam.split(',').map(Number);
    }

    const subscriber: CreateSubscriberDTO = {
      subscriberemail: this.email,
      regions: this.regions,
      token: this.token
    };

    if (this.processing) {
      if (this.authService.isAuthenticated()) {
        this.router.navigate([this.subscribeAuthenticated, this.token, this.email], {
          queryParams: {
            processing: 'false',
            regions: regionsQueryParam
          }
        });
      } else {
        this.router.navigate([this.subscribe, this.token, this.email], {
          queryParams: {
            processing: 'false',
            regions: regionsQueryParam
          }
        }).then(() => {
          window.location.reload();
        });
      }
    } else {
      this.subscriptionService.createSubscriber(subscriber).subscribe(res => {
        this.userMessage = "Prenumerationen är nu skapad";
        this.isError = false;
      },
        err => {
          if (err.status === 401) {
            this.userMessage = `Länken har gått ut ett nytt verifikationsmail har skickats till denna emailadress ${this.email}.`;
          } else if (err.status === 409) {
            this.userMessage = `Emailadress ${this.email} är redan registrerad för prenumeration.`;
          }
          else {
            this.userMessage = "Något gick fel med prenumerationen";
          }
          this.isError = true;
          this.cdRef.detectChanges();
        });
    }
  }
  onShowLogin(): void {
    this.router.navigate(['login']);
  }
  onShowBrokerCalloffs(): void {
    this.router.navigate(['broker-call-off-list']);
  }
}