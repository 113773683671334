import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleComponent } from './role/role.component';
import { RolesComponent } from './roles/roles.component';
import {PrimeModule} from "../../prime-module.ts";
import { CreateRoleComponent } from './create-role/create-role.component';
import { EditRoleComponent } from './edit-role/edit-role.component';
import { EditRoleUsersComponent } from './edit-role/edit-role-users/edit-role-users.component';


@NgModule({
  declarations: [
      RoleComponent,
      RolesComponent,
      CreateRoleComponent,
      EditRoleComponent,
      EditRoleUsersComponent
  ],
  imports: [
    CommonModule,
    PrimeModule
  ],
  exports: [
  ]
})

export class RoleModule { }
