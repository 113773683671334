import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { SharedResume } from 'src/app/models/resume/resume.model';
import { ResumeService } from 'src/app/services/resume.service';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal/error-modal.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-shared-resume-create',
  templateUrl: './shared-resume-create.component.html',
  styles: [
  ]
})
export class SharedResumeCreateComponent implements OnInit {
processing: false;
leavePageWarningEnabled: boolean = true;
form: FormGroup;
isActiveCheck: false;
isGDPRCheck: false;
hasAcceptedPoliciesCheck: false;
hasProtectedIdentityCheck: false;
selectedFileId: string;
sharedResume: SharedResume;
selectedSharedResumeId: number;

@Input() passedSharedResumeId: number;

  constructor(
    private formBuilder: FormBuilder,
    private resumeService: ResumeService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      isActive: [false],
      isGDPR: [false],
      hasAcceptedPolicies: [false, [Validators.requiredTrue]],
      hasProtectedIdentity: [false],
      callOffNumber: ["", [Validators.min(1), Validators.required]],
      tenderReference: ["", [Validators.min(1), Validators.required]],
      consultantName: ["", [Validators.min(1), Validators.required]],
      supplierOrganizationName: ["", [Validators.min(1),Validators.required]],
      messageToCustomer: [""],
    }, )

    if(this.passedSharedResumeId === undefined) {
      this.route.params.subscribe(params => {
        if(params?.sharedResumeId) {
          this.selectedSharedResumeId = Number(params.sharedResumeId);
          this.fetchSharedResume(this.selectedSharedResumeId);
        }
      })
    }
    
  }

  fetchSharedResume(sharedResumeId: number){
    this.resumeService.getSharedResume(sharedResumeId).subscribe(res => {
      console.log(res);
      this.form.get('callOffNumber')?.setValue(res.callOffNumber)
      this.form.get('tenderReference')?.setValue(res.tenderReference)
      this.form.get('consultantName')?.setValue(res.consultantName)
      this.form.get('supplierOrganizationName')?.setValue(res.supplierOrganizationName)
      this.form.get('messageToCustomer')?.setValue(res.messageToCustomer)
      this.form.get('isActive')?.setValue(res.isActive)
      this.form.get('isGDPR')?.setValue(res.isGDPR)
      this.form.get('hasAcceptedPolicies')?.setValue(res.hasAcceptedPolicies)
      this.form.get('hasProtectedIdentity')?.setValue(res.hasProtectedIdentity)
      this.selectedFileId = res.fileID;
    })
  }

  onCreateClicked(){
    this.leavePageWarningEnabled = false;
    this.sharedResume = this.form.value;
    console.log(this.sharedResume);
    this.sharedResume.fileID = this.selectedFileId;

    if(this.selectedSharedResumeId){
      this.onSaveClicked();
      return;
    }

    this.resumeService.createSharedResume(this.sharedResume).subscribe(res => {
      console.log(res);
    this.showCreatedToast();
    this.router.navigate(['/manage/resumes']);
  },
  (error) => {
    this.processing = false;
    this.leavePageWarningEnabled = true;
    this.dialogService.open(ErrorModalComponent,
      {
        header:"Något gick fel",
        data: {
          message: "Anbudet måste flyttas till Avtal skapas för att kunna skapa avtal"
        }
      })
  })
  }

  onUploadDone(event: any) {
    if (event.success) {
      this.selectedFileId = event.file.fileID;
    //   const resume: CreateResume = {
    //     fileID: event.file.fileID,
    //     fileName: event.file.fileName,
    //     displayFileName: event.file.displayFileName,
    //     consultantID: null,
    //     consultants: [this.selectedConsultant]
    //   }
    //   this.resumeService.create(resume).subscribe(res => {
    //     this.resumes.push({
    //       resume: {
    //         resumeID: res.resumeID,
    //         fileID: res.fileID, 
    //         fileName: res.fileName, 
    //         displayFileName: res.displayFileName, 
    //         file: 
    //         res.file
    //       }, 
    //         consultantID: this.selectedConsultant
    //     });
    //   }, error => {
    //     console.log(error);
    //     this.dialogService.open(ErrorDialogComponent, {data: {message: 'Kunde ej ladda upp CV..'}});
    //   });
    }
    else {

    }
  }

  create() {

  }

  onSaveClicked() {
    this.resumeService.updateSharedResume(this.selectedSharedResumeId, this.sharedResume).subscribe(res => {
      console.log(res);
      this.showUpdatedToast();
      this.router.navigate(['/manage/resumes']);
    })
  }

  showCreatedToast() {
    this.messageService.add({
      key:'main',
      severity: 'success',
      detail: 'CV har skapats'
    })
  }
  canDeactivate(confirmationMessage: string): boolean | Observable<boolean> | Promise<boolean> {
    if (this.leavePageWarningEnabled) {
      if (this.form.dirty) {
        return window.confirm(confirmationMessage);
      }
    }
    return true;
  }

  showUpdatedToast() {
    this.messageService.add({
      key:'main',
      severity: 'success',
      detail: 'CV har uppdaterats'
    })
  }

}
