import { Injectable } from '@angular/core';
import jwtDecode from "jwt-decode";
import { catchError, map } from "rxjs/operators";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UserAuthenticateResponse } from "../models/user/user-authenticate-response.model";
import { UserToken } from '../models/user/user-token.model';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    endpointBaseURL = 'auth'

    private userTokenSubject: BehaviorSubject<any>;
    public userToken: Observable<any>;
    private isAuthenticatedSubject: BehaviorSubject<boolean>;
    public isAuthenticatedBool: Observable<boolean>;

    constructor(public http: HttpClient, private router: Router, private location: Location) {
        this.userTokenSubject = new BehaviorSubject<any>(JSON.parse(<string>localStorage.getItem('userToken')));
        this.userToken = this.userTokenSubject.asObservable();

        this.isAuthenticatedSubject = new BehaviorSubject<boolean>(this.isAuthenticated());
        this.isAuthenticatedBool = this.isAuthenticatedSubject.asObservable();
    }

    login(userName: string, password: string) {

        return this.http.post(`${this.endpointBaseURL}`, { identifier: userName, password: password }, { withCredentials: true })
            .pipe(map(userToken => {
                localStorage.setItem('userToken', JSON.stringify(userToken));
                this.setUserToStorage();
                this.userTokenSubject.next(userToken);
                this.isAuthenticatedSubject.next(true);

                return userToken;
            }),
                catchError(this.handleError));
    }

    logout() {
        if (localStorage.getItem("userToken")) {
            localStorage.removeItem("userToken");
        }
        if (localStorage.getItem("user")) {
            localStorage.removeItem("user");
        }
        if (localStorage.getItem("selectedOrganizationID")) {
            localStorage.removeItem("selectedOrganizationID");
        }

        localStorage.setItem("organizationTypeLabel", "Till Leverantör");

        if (this.isAuthenticatedSubject) {
            this.isAuthenticatedSubject.next(false);
        }
    }

    isAuthenticated(): boolean {
        let userToken: UserToken = this.getuserTokenFromStorage();

        if (userToken) {
            return this.isTokenValid(userToken.accessToken as string);
        }
        return false;
    }

    setUserToStorage(): void {
        const userToken: UserToken = this.getuserTokenFromStorage();
        const decodedToken: any = jwtDecode(userToken.accessToken);
        const user: UserAuthenticateResponse = {
            userID: decodedToken.ID,
            organizationID: decodedToken.organizationID,
            organizationTypeID: String(decodedToken.organizationTypeID == OrganizationType.customerSupplier ? OrganizationType.customer : decodedToken.organizationTypeID),
            organizationTypeIDJWT: decodedToken.organizationTypeID,
            email: decodedToken.email,
            name: decodedToken.name,
            role: decodedToken.role,
            phone: decodedToken.phone
        }

        localStorage.setItem('user', JSON.stringify(user));
    }

    getTokenFromStorage(): string {
        const userToken: UserToken = this.getuserTokenFromStorage();

        if (userToken) {
            return userToken.accessToken;
        }

        return userToken;
    }

    getUserFromStorage(): UserAuthenticateResponse {
        return JSON.parse(<string>localStorage.getItem("user"));
    }

    private getuserTokenFromStorage(): UserToken {
        return JSON.parse(<string>localStorage.getItem("userToken"));
    }

    private isTokenValid(token: string): boolean {
        if (!token) {
            this.logout();
            return false;
        }

        const decodedToken: any = jwtDecode(token);

        if (decodedToken.exp === undefined) {
            this.logout();
            return false;
        }

        const currentTimeInSeconds = new Date().getTime() / 1000;
        if (decodedToken.exp > currentTimeInSeconds) {
            return true;
        }
        this.logout();
        return false;
    }

    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error code: ${error.status}\nMessage: ${error.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
    checkIfUserIsAuthorizedForPage() {
        let locationPath = this.location.path();
        let processing = localStorage.getItem("processing") === 'true';
        if (!processing) {
            const queryParams = new URLSearchParams(locationPath.split('?')[1]);
            processing = queryParams.get('processing') === 'true';
        }

        if (this.isAuthenticated() && locationPath.includes('login') && !processing) {
            this.router.navigate(['/broker-call-off-list']);
        }
        else if (!this.isAuthenticated() && !locationPath.includes('login') && !processing) {
            this.router.navigate(['/login']);
        }
    }
    checkIfUserHasSupplierAccess(): boolean {
        let user = this.getUserFromStorage();
        return Number(user.organizationTypeIDJWT) == OrganizationType.supplier || Number(user.organizationTypeIDJWT) == OrganizationType.customerSupplier || Number(user.organizationTypeIDJWT) == OrganizationType.broker;
    }
    checkIfUserIsCustomerSupplierAndSetOrgType(): boolean {
        let user = this.getUserFromStorage();
        if (Number(user.organizationTypeIDJWT) == OrganizationType.customerSupplier) {
            user.organizationTypeID = OrganizationType.supplier + "";
            localStorage.setItem('organizationTypeLabel', 'Till Kund');
            localStorage.setItem('user', JSON.stringify(user));
            return true;
        }
        return false;
    }
}
