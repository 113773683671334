import { PasswordValidation } from '../models/user/user.model'

export function validatePassword(password: string, repeat: string): PasswordValidation {
  const errors = [];
  const p = password;

  if (!p) {
    return {valid: false};
  }

  if (p !== repeat) {
    errors.push('Upprepa lösenord måste vara identiskt med lösenord');
  }
  if (p.length < 8) {
      errors.push('Lösenord måste bestå av minst åtta tecken');
  }
  if (p.search(/[a-z]/i) < 0) {
      errors.push('Lösenord måste innehålla minst en bokstav');
  }
  if (p.search(/[0-9]/) < 0) {
      errors.push('Lösenord måste innehålla minst ett nummer');
  }
  if (errors.length > 0) {
      return {valid: false, errors};
  }
  return {valid: true};
}
