import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ConsultantService } from 'src/app/services/consultant.service';
import { CreateConsultantComponent } from '../../assignment-children/create-consultant/create-consultant.component';

@Component({
  selector: 'app-organization-consultant-info',
  templateUrl: './organization-consultant-info.component.html',
  styles: [
  ]
})
export class OrganizationConsultantInfoComponent implements OnInit {

  consultant: any;
  @Input() consultantID : number;
  processing = true;

  constructor(private consultantService : ConsultantService, private dialogService: DialogService) { }

  private getConsultantDetails(consultantId: number) {
    this.consultantService.getConsultantByConsultantId(consultantId).subscribe(res => {
      this.consultant = res;
      this.processing = false;
    });
  }

  openEditConsultant() {
    this.dialogService.open(CreateConsultantComponent, { "width": "1000px", "data": {
      
      consultant: this.consultant,
      consultantId: this.consultant.consultantID,
      isEditMode: true

    }}).onClose.subscribe((res) => {
      if(res === true) {
        this.getConsultantDetails(this.consultantID)
      }
    });
  }

  ngOnInit(): void {
    this.getConsultantDetails(this.consultantID)
  }

}
