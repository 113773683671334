<p-dialog header="Avtal" [(visible)]="display" [style]="{width: '80vw', height:'70vw'}">
  <div *ngIf="pdfSrc != null">
    <div class="pdf-test">
      <ngx-extended-pdf-viewer 
        [src]="pdfSrc"
        [useBrowserLocale]="true"
        [showToolbar]="true" 
        [showPrintButton]="state === 'broker'"
        [showDownloadButton]="state === 'broker'"
        >
      </ngx-extended-pdf-viewer>
    </div>
  </div>
  <div style="text-align:center">
      <button pButton class="p-button p-button-raised p-text-uppercase" label="OK" (click)="onOK()" ></button>
  </div>
</p-dialog>

<ng-container *ngIf="loaded">
  <p-card header="Avtalsinformation" [style]="{'margin-bottom': '25px'}">
    <div style="display: flex; justify-content: start; font-size: 16px;">
      <div style="width: 400px;">
        <p>Affärsnummer: <b>{{ contract.contractName }}</b></p>
        <p>Startdatum: <b>{{ getDatestring(contract.startDate) | date: 'dd/MM/yyyy' }} </b></p>
        <p>Uppdragsreferens: <b>{{ assignment.assignmentReference }}</b></p>
      </div>
      <div style="width: 400px;">
        <p>Avtalsdatum: <b>{{ getDatestring(contract.contractDate) | date: 'dd/MM/yyyy' }}</b></p>
        <p>Slutdatum: <b>{{ getDatestring(contract.endDate) | date: 'dd/MM/yyyy' }}</b></p>
        <p>Anbudsreferens kund <b>{{ contract.customerContractReference }}</b></p>
        <p *ngIf="this.state === 'broker'">Marginal: <b>{{ contract.margin }}</b></p>
      </div>
      <div>
        <p>Typ av uppdrag: <b>{{assignment.assignmentTypeName}}</b></p>
        <p>Kommentar: <b>{{ contract.priceComment }}</b></p>
      </div>
    </div>
  </p-card>
  
  <p-card *ngIf="state === 'broker' || state === 'customer'" header="Avtalsinformation - Kund" [style]="{'margin-bottom': '25px'}">
    <div style="display: flex; justify-content: start; font-size: 16px;">
      <div style="width: 400px;">
        <p>Avtalsnummer: <b>{{ contract.customerContractReference }}</b></p>
        <p>Typ av förfrågan: <b>{{ assignment.assignmentTypeName }}</b></p>
        <p>Ramavtalspris: <b>{{ contract.frameworkPrice }}</b></p>
        <p (click)="showFile(contract.customerFile)">Signerade avtal: <b *ngIf="contract.customerFile">{{ contract.customerFile.displayFileName }}</b></p>
        <button *ngIf="this.state === 'broker'" pButton label="Ladda upp avtal"  (click)="showFileUpload('customer')"></button>
        
      </div>
      <div>
        <p>Pris: <b>{{ contract.customerPrice }}</b></p>
        <p>Omfattning: <b>{{ assignment.assignmentEstimate }}</b></p>
        <br>
        <button pButton label="Visa avtal" (click)="showFile(contract.customerFile)" *ngIf="contract.customerFile"></button>
      </div>
      
    </div>
    <div style="width: 600px">
      <ng-container *ngIf="showCustomerFileUpload">
        <app-file-upload [header]="'Ladda upp avtal för Kund'" (uploadSuccess)="onCustomerUploadDone($event)">
        </app-file-upload>
      </ng-container>
    </div>
  </p-card>

  <p-card *ngIf="state === 'broker' || state === 'supplier'" header="Avtalsinformation - Leverantör" [style]="{'margin-bottom': '25px'}">
    <div style="display: flex; justify-content: start; font-size: 16px;">
      <div style="width: 400px;">
        <p>Avtalsnummer: <b>{{ contract.supplierContractReference }}</b></p>
        <p>Signerade avtal: <b *ngIf="contract.supplierFile">{{ contract.supplierFile.displayFileName }}</b></p>
        <button *ngIf="this.state === 'broker'" pButton label="Ladda upp avtal" (click)="showFileUpload('supplier')"></button>
        
      </div>
      <div>
        <p>Pris: <b>{{ contract.supplierPrice }}</b></p>
        <br>
        <button pButton label="Visa avtal" (click)="showFile(contract.supplierFile)" *ngIf="contract.supplierFile"></button>
      </div>
    </div>
    <div style="width: 600px">
      <ng-container *ngIf="showSupplierFileUpload">
        <app-file-upload [header]="'Ladda upp avtal för Leverantör'" (uploadSuccess)="onSupplierUploadDone($event)">
        </app-file-upload>
      </ng-container>
    </div>
  </p-card>

  <p-card header="Kontaktpersoner" [style]="{'margin-bottom': '25px'}">
    <p-tabView>
      <p-tabPanel header="Swedish Consulting Group">
        <p-table [value]="brokerUsers" responsiveLayout="scroll">
          <ng-template pTemplate="header">
              <tr>
                  <th>Namn</th>
                  <th>E-Post</th>
                  <th>Telefonnummer</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user>
              <tr>
                  <td>{{user.fullName}}</td>
                  <td>{{user.email}}</td>
                  <td>{{user.phoneNumber}}</td>
              </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>

      <p-tabPanel header="Kund">
        <p-table [value]="customerUsers" responsiveLayout="scroll">
          <ng-template pTemplate="header">
              <tr>
                  <th>Namn</th>
                  <th>E-Post</th>
                  <th>Telefonnummer</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user>
              <tr>
                  <td>{{user.fullName}}</td>
                  <td>{{user.email}}</td>
                  <td>{{user.phoneNumber}}</td>
              </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>

      <ng-container *ngIf="stateSupplierContactInfo === 'responsableConsultantManagerContactInfo'">
        <p-tabPanel header="Leverantör">
          <p-table [value]="consultants" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>Namn</th>
                    <th>E-Post</th>
                    <th>Telefonnummer</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-consultant>
                <tr>
                    <td>{{consultant.consultantManagerUser.fullName}}</td>
                    <td>{{consultant.consultantManagerUser.email}}</td>
                    <td>{{consultant.consultantManagerUser.phoneNumber}}</td>
                </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
      </ng-container>

      <ng-container *ngIf="stateSupplierContactInfo === 'supplierTenderContactInfo'">
        <p-tabPanel header="Leverantör">
          <p-table [value]="supplierTenderContactList" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>Namn</th>
                    <th>E-Post</th>
                    <th>Telefonnummer</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-supplierTenderContact>
                <tr>
                    <td>{{supplierTenderContact.fullName}}</td>
                    <td>{{supplierTenderContact.email}}</td>
                    <td>{{supplierTenderContact.phoneNumber}}</td>
                    <td><button pButton label="Ändra kontaktperson" (click)="showSupplierUsers()" *ngIf="state === 'broker'"></button></td>
                </tr>
            </ng-template>
          </p-table>
          <ng-container *ngIf="showSupplier">
            <div class="p-fluid p-grid">
              <div class="p-field p-col-2">
                <label for="supplierUserId">
                  Tillgängliga Kontaktpersoner
                  <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                    pTooltip="Välj en användare"></i>
                  </span>
                </label>
                <select id="supplierUserID" [(ngModel)]="selectedSupplierUser"
                  (change)="onSupplierUserChange($event)" class="p-inputtext"
                  style="appearance: auto">
                  <option *ngFor="let user of availableSupplierUsers" [ngValue]="user.userID">
                    {{user.firstName }} {{ user.lastName }}</option>
                </select>
                <button pButton style="margin-top: 10px;" type="button" (click)="onChangeSupplierUser()"
                iconPos="right" class="p-button p-button-raised p-text-uppercase" label="Spara" *ngIf="selectedSupplierUser"></button>
              </div>
            </div>
          </ng-container>
        </p-tabPanel> 
      </ng-container>

      <ng-container *ngIf="stateSupplierContactInfo === 'supplierOrganizationInfo'">
        <p-tabPanel header="Leverantör">
          <p style="padding: 6px 12px; display: inline-block;">Kunde inte hitta en ansvarig för konsult, kontaktinfo för leverantören visas istället</p>

          <p-table [value]="supplierOrganizations" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>Leverantör</th>
                    <th>E-Post</th>
                    <th>Telefonnummer</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-supplierOrganization>
                <tr>
                    <td>{{supplierOrganization.organizationName}}</td>
                    <td>{{supplierOrganization.email}}</td>
                    <td>{{supplierOrganization.phoneNumber}}</td>
                </tr>
            </ng-template>
          </p-table>
        </p-tabPanel> 
      </ng-container>
      
      <p-tabPanel header="Konsulter">
        <p-table [value]="consultants" responsiveLayout="scroll">
          <ng-template pTemplate="header">
              <tr>
                  <th>Namn</th>
                  <th>E-Post</th>
                  <th>Telefonnummer</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-consultant>
              <tr>
                  <td>{{consultant.firstName}} {{consultant.lastName}}</td>
                  <td>{{consultant.email}}</td>
                  <td>{{consultant.phoneNumber}}</td>
              </tr>
          </ng-template>
        </p-table>
     </p-tabPanel>

    </p-tabView>
  </p-card>

  <p-card header="Tillägg">
    <div style="text-align: right;">
      <button pButton *ngIf="state === 'customer'" label="Begär förlängning"(click)="onRequestExtension()" style="margin-bottom: 15px;"></button>
      <button pButton *ngIf="state === 'broker'" label="Skapa tillägg"(click)="onCreateExtension()" style="margin-bottom: 15px;"></button>
    </div>
    <p-table [value]="contract.contractExtensions" [lazy]="true" (onLazyLoad)="loadContractsExtensions($event)" [paginator]="true"
        [rows]="this.pageSize" [totalRecords]="pageTotal" [loading]="!loaded"
        dataKey="contractID"
        [globalFilterFields]="['contractName', 'customerContractReference', 'supplierContractReference','startDate', 'endDate']">
        <ng-template pTemplate="header">
            <tr>
              <th style="width:18%" pSortableColumn="">Aktiv

              </th>
                <th style="width: 18%" pSortableColumn="contractName">Namn <p-sortIcon field="contractExtensionName"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="customerContractReference">Avtalsnummer <p-sortIcon field="customerContractReference"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="customerContractReference">Avtalsnummer <p-sortIcon field="supplierContractReference"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="contractDate">Startdatum <p-sortIcon field="startDate"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="endDate">Slutdatum <p-sortIcon field="endDate"rowspan="2"></p-sortIcon>
                </th>
                <th style="width: 8rem" class="p-text-right"></th>
                <th></th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" type="text" field="contractExtensionName">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" type="text" field="customerContractReference">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" type="text" field="supplierContractReference">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" type="text" field="startDate">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" type="text" field="endDate"></p-columnFilter>
                </th>
                <th>

                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contract>
             <tr [pContextMenuRow]="contract" (click)="openContractExtensionPage(contract)" style="cursor: pointer;">
                <td>
                  <div *ngIf="!contract.isActive">
                    <i class="pi pi-circle-off"></i>
                  </div>
                  <div *ngIf="contract.isActive">
                    <i class="pi pi-check-circle"></i>
                  </div>
                </td>
                <td>{{contract.contractExtensionName}}</td>
                <td>{{contract.customerContractReference}}</td>
                <td>{{contract.supplierContractReference}}</td>
                <td>{{getDatestring(contract.startDate)  | date: 'dd/MM/yyyy'}}</td>
                <td>{{getDatestring(contract.endDate) | date: 'dd/MM/yyyy'}}</td>
                <td>
                  <button pButton *ngIf="state === 'broker' && !contract.isActive" label="Aktivera" (click)=" activateContractExtension($event, contract)" style="margin-bottom: 15px;"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    
  </p-card>
</ng-container>
<!-- <div *ngIf="viewContract">
<p-dialog [(visible)]="viewContract" [style]="{'margin-top':'50px'}">
  <p>Startdatum: {{viewContract.startDate}}</p>
  <p>Slutdatum: {{viewContract.endDate}}</p>
  <p>{{viewContract.endDate}}</p></p-dialog>
</div> -->