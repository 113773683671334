<label for="userDropdown" style="margin: 10px;">Användare som ska tilldelas objekt:</label>
  <p-dropdown
  id="userDropdown"
  placeholder="--Välj en användare--"
  [options]="userList"
  optionLabel="fullName"
  (onChange)="onUserSelected($event)"
  [filter]="true"
  appendTo="body"
  >
  </p-dropdown>

<div>
    <p style="margin: 10px;">{{message}}</p>
    <button pButton type="button" (click)="accept()" label="Ta bort användare" [disabled]="!selectedUser" style="margin:5px;"></button>
    <button pButton type="button" class="p-ml-2 " (click)="deny()" label="Avbryt" style="margin:5px;"></button>
</div>