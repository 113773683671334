import { EmailModalComponent } from './email-modal/email-modal.component';
import { PublishModalComponent } from './publish-modal/publish-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogModule, DialogService } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { PrimeModule } from 'src/app/prime-module.ts';

@NgModule({
  declarations: [
    EmailModalComponent,
    PublishModalComponent
  ],
  imports: [
    CommonModule,
    DynamicDialogModule,
    FormsModule,
    PrimeModule
  
    
  ],
  providers: [
    DialogService
  ],
})
export class EmailModalModule { }