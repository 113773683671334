import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Consultant, ConsultantListItem, CreateConsultant, UpdateConsultant } from "../models/consultant/consultant.model";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root",
  })
  
  export class ConsultantService extends BaseService<Consultant, CreateConsultant, ConsultantListItem, UpdateConsultant> {
    endpointBaseURL = "consultant";

    getConsultantsByOrganization(id: number): Observable<any> {
        let params = new HttpParams();
        params = params.append('supplierID', id.toString());
    
        return this.http.get<any>(
            `${this.endpointBaseURL}`,
            { params }
        );
    }
    getConsultantByConsultantId(consultantID: number): Observable<any> {
      return this.http.get(`${this.endpointBaseURL}/info/${consultantID}`);
    }
  }
  