<p-contextMenu #cm [model]="items"></p-contextMenu>
<p-card header="{{headerString}} ">
    <i *ngIf ="this.organizationTypeNbr == this.organizationTypeEnum.broker" pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
        pTooltip="Alla öppna avrop som har status 'Skapad annons' och framåt syns här. Dessa räknas som stängda status och syns inte: 'Avbrutet', 'Kasta' och 'Fullbordat'"></i>
    <p-table [value]="brokerCallOffs" sortMode="multiple" (onLazyLoad)="loadBrokerCallOffs($event)" [paginator]="true"
        [rows]="this.pageSize" [totalRecords]="pageTotal" [loading]="loading" [(contextMenuSelection)]="selectedCallOff"
        [contextMenu]="cm" dataKey="brokerCallOffID"
        [globalFilterFields]="['brokerUserName', 'customerName', 'assignmentReference','header', 'created']">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 18%" pSortableColumn="header">Rubrik <p-sortIcon field="header"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="assignmentReference">Referens <p-sortIcon field="assignmentReference"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="created">Publiceringsdatum <p-sortIcon field="created"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="customerName">Beställare <p-sortIcon field="customerName"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="brokerUserName">Ansvarig Swedish Consulting Group <p-sortIcon field="brokerUserName"rowspan="2"></p-sortIcon>
                </th>
                <th style="width: 8rem" class="p-text-right"></th>
                <th></th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="header">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="assignmentReference">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="created">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="customerName">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="brokerUserName"></p-columnFilter>
                </th>
                <th>

                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-brokerCallOff>
            <tr [pContextMenuRow]="brokerCallOff" (click)="onViewBrokerCallOffClicked(brokerCallOff)" style="cursor: pointer;">
                <td>{{brokerCallOff.header}}</td>
                <td>{{brokerCallOff.assignmentReference}}</td>
                <td>{{brokerCallOff.created}}</td>
                <td>{{brokerCallOff.customerName}}</td>
                <td>{{brokerCallOff.brokerUserName}}</td>
                <td>
                    <button
                        pButton
                        label="Redigera"
                        *ngIf="showAllCallOffs || usersOrganizationIsCustomer && brokerCallOff.statusID < maxCallOffStatus"
                        (click)="onEditCallOffClicked(brokerCallOff)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>