<div class="p-grid p-justify-center">
    <div class="p-col-12 p-lg-7">
        <p-card header="Skapa mall">
            <form [formGroup]="templateForm" (ngSubmit)="onCreateClicked()">
                <div class="p-mt-4">
                    <p-divider></p-divider>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col">
                            <label for="name">Namn *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Namn på mallen"></i>
                                </span>
                            </label>
                            <input id="name" formControlName="name" type="text" [disableControl]="processing"
                                pInputText>
                            <label for="event">Event
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Det event som mallen används för"></i>
                                </span>
                            </label>
                            <select id="eventID" formControlName="eventID" class="p-inputtext" style="appearance: auto">
                                <option *ngFor="let item of events" value={{item.eventID}}>{{item.eventKey}} -
                                    {{item.description}}</option>
                            </select>
                            <div class="p-field">
                                <label>
                                    Innehåll
                                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                            style="top: 8px" pTooltip="Innehållet för mallen"></i>
                                    </span>
                                </label>
                                <p-editor formControlName="contentString" [style]="{'height':'320px'}">
                                </p-editor>
                            </div>
                            <div class="p-grid p-mt-4">
                                <div class="p-col-6">
                                    <button pButton type="button" icon="pi pi-chevron-left"
                                        class="p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka"
                                        [disabled]="processing" (click)="onBackClicked()"></button>
                                </div>
                                <div class="p-col-6 p-text-right">
                                    <button pButton type="submit"  iconPos="right"
                                        class="p-button p-button-raised p-text-uppercase"
                                        [label]="selectedOrganizationTemplateId ? 'Spara' :'Skapa'"
                                        [disabled]="processing"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-mt-4" [hidden]="!processing">
                    <p-progressBar mode="indeterminate"></p-progressBar>
                </div>
            </form>
        </p-card>
    </div>
</div>