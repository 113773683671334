import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { mergeMap } from 'rxjs/operators';
import { Consultant } from 'src/app/models/consultant/consultant.model';
import { Contract, ContractExtensionReceiverDTO } from 'src/app/models/contract/contract.model';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConsultantService } from 'src/app/services/consultant.service';
import { ContractService } from 'src/app/services/contract.service';
import { FileService } from 'src/app/services/file-service';
import { convertToLocaleStringFromUTC, removeTimeFromDateString } from 'src/app/utils/date';

@Component({
  selector: 'app-contract-extension-modal',
  templateUrl: './contract-extension-modal.component.html',
  styles: [
  ]
})
export class ContractExtensionModalComponent implements OnInit {
contractConsultant: Consultant
showCustomerFileUpload : boolean = false;
showSupplierFileUpload : boolean = false;
loaded = false;
contract: any;
display: boolean;
pdfSrc: Blob;
user: UserAuthenticateResponse;
state: string;
customerReceiver: any;
supplierReceiver: any;
convertDate = convertToLocaleStringFromUTC;

  constructor(
    private consultantService: ConsultantService,
    private config: DynamicDialogConfig,
    private modalRef: DynamicDialogRef,
    private contractService: ContractService,
    private fileService: FileService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    this.user = this.authService.getUserFromStorage();
    if(Number(this.user.organizationTypeID) === OrganizationType.broker) {
      this.state = 'broker';
    }
    else if(Number(this.user.organizationTypeID) === OrganizationType.customer) {
      this.state = 'customer';
    }
    else if(Number(this.user.organizationTypeID) === OrganizationType.supplier) {
      this.state = 'supplier';
    }
    else {
      this.state = 'undefined'
    }

    // this.contract = this.config.data.contract;
    this.contractService.getContractExtension(this.config.data.contract.contractExtensionID).pipe(mergeMap(res => {
      this.contract = res;

      this.customerReceiver = this.contract?.contractExtensionReceivers?.find((cer: any) => cer.organizationType === 'customer');
      this.supplierReceiver = this.contract?.contractExtensionReceivers?.find((cer: any) => cer.organizationType === 'supplier');
      
      this.contract.startDate = res.startDate !== null ? this.convertDate(this.getDatestring(res.startDate)): res.startDate;
      this.contract.contractDate = res.contractDate !== null ? this.convertDate(this.getDatestring(res.contractDate)) : res.contractDate;
      this.contract.endDate = this.convertDate(this.getDatestring(res.endDate));
      return this.consultantService.get<Consultant>(res.consultantID)
    })).subscribe(res => {
      console.log(res);
      this.contractConsultant = res;
      this.loaded = true;
    })
  }

  onOK(): void {
    this.modalRef.close(true);
  }

  showFileUpload(type: string) {
    if (type === 'customer'){
      this.showCustomerFileUpload = true;
    }
    if (type === 'supplier'){
      this.showSupplierFileUpload = true;
    }
  }

  onSupplierUploadDone(event: any) {
    // console.log("TESTSUPP");
    if(event.success) {
      this.contractService.updateContractExtension(this.contract.contractExtensionID, {supplierFileId: event.file.fileID}).pipe(
        mergeMap( res => {
          
           return this.contractService.getContractExtension(this.contract.contractExtensionID)

        })).subscribe(contract => {
          if(contract) {
          this.contract = contract;
        }
        }, error => {
          console.log(error);
        }); 
        
        
      

      // this.contractService.update<UpdateContract>(this.contract.contractID, {supplierFileId: event.file.fileID}).subscribe(res => {
      //   console.log(res);
      // })
    }
    else {
      // do nothuing
    }
  }

  onCustomerUploadDone(event: any) {
    // console.log("TESTCUST");
    if(event.success) {
      this.contractService.updateContractExtension(this.contract.contractExtensionID, {customerFileId: event.file.fileID}).pipe(
        mergeMap( res => {
          
           return this.contractService.getContractExtension(this.contract.contractExtensionID)

        })).subscribe(contract => {
          if(contract) {
          this.contract = contract;
        }
        }, error => {
          console.log(error);
        }); 
    }
    else {
      // do nothuing
    }
  }

  showFile(file: any): void {
    console.log(file);
    this.fileService.getFileById(file.fileID).subscribe(res => {
      this.pdfSrc = res;
      console.log(this.pdfSrc);
      this.display = true;
     });
  }

  getDatestring(date: any) {
    return removeTimeFromDateString(date.toString());
  }

  getContractExtensionResponse(receiver: ContractExtensionReceiverDTO) {
    var response = '';
    if (receiver !== undefined){
      switch(receiver.isContractExtensionAccepted) { 
        case true: { 
          response = "Godkänd" 
          break; 
        } 
        case false: { 
          response = "Ej godkänd"
          break; 
        }
        case null: {
          response = "Obesvarad"
          break;
        } 
        default: { 
          "Status okänd" 
          break; 
        } 
    } 
  }
   return response;
  }

}
