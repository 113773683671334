<p-card header="Avprenumerera">
    <p [ngStyle]="{'color': isError ? 'red' : 'green'}">{{ userMessage }}</p>
    <div>
        <input type="email" [(ngModel)]="email" placeholder="Din e-postadress" class="p-inputtext p-component">
        <button pButton type="button" (click)="unsubscribeByEmail()" label="Avprenumerera" icon="pi pi-sign-out"
            class="p-button-danger p-button-raised p-text-uppercase"></button>
    </div>
    <button *ngIf="!authService.isAuthenticated()" pButton type="button" icon="pi pi-chevron-left"
            class="p-button-secondary p-button-raised p-text-uppercase" (click)="onShowLogin()" label="Logga in"></button>
    <button *ngIf="authService.isAuthenticated()" pButton type="button" icon="pi pi-chevron-left"
            class="p-button-secondary p-button-raised p-text-uppercase" (click)="onShowBrokerCalloffs()"
            label="Mina Avrop"></button>
</p-card>
