import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AssignmentChildren, AssignmentType } from '../models/assignment-children/assignment-children.model';

@Injectable({
    providedIn: 'root'
  })

  export class AssignmentChildrenService implements OnInit{
    endpointBaseURL  = ``;
    constructor(
      private http: HttpClient
    ) 
    { }

    ngOnInit(): void {
    }

    getResumesByTenderId(id: number, assignmentChildType: string): Observable<any> {
      if (assignmentChildType === 'SupplierTender') {
        return this.http.get(`resumetosupplierTender/${id}`);
      }
     
      return this.http.get(`resumetobrokerTender/${id}`);
    }

    getUserToAssignmentInfo(assignmentId: number): Observable<any> {
      return this.http.get(`assignment/${assignmentId}/UserToAssignment`)
    }

    getSupplierContact(supplierTenderId: number): Observable<any> {
      return this.http.get(`assignment/${supplierTenderId}/SupplierContact`)
    }

    getAssignmentResponsible(assignmentId: number): Observable<any> {
      return this.http.get(`assignment/${assignmentId}/ResponsibleUsers`);
    }

    getAssignmentTypes(): Observable<any> {
      return of(AssignmentType);
    }
  }