<div class="p-grid p-justify-center">
  <div class="p-col-12 p-lg-7">
    <p-card [header]="selectedSharedResumeId ? 'Redigera CV-länk' : 'Skapa CV-länk'">

      <form [formGroup]="form" (ngSubmit)="onCreateClicked()">
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="callOffNumber">
              Avropsnummer *
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="Identifikation för avropet"></i>
              </span>
            </label>
            <input id="callOffNumber" formControlName="callOffNumber" type="text" [disableControl]="processing" required
              pInputText>
          </div>
        </div>

        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="tenderReference">
              Anbudsreferens *
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px" required
                  pTooltip="Identifikation för anbudet"></i>
              </span>
            </label>
            <input id="tenderReference" formControlName="tenderReference" type="text" [disableControl]="processing" required
              pInputText>
          </div>
        </div>

        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="consultantName">
              Konsultens namn *
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="Namn på konsultens vars CV delas"></i>
              </span>
            </label>
            <input id="consultantName" formControlName="consultantName" type="text" [disableControl]="processing" required
              pInputText>
          </div>
        </div>

        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="supplierOrganizationName">
              Konsultbolag *
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="Namn på konsultbolaget som konsulten är anställd hos"></i>
              </span>
            </label>
            <input id="supplierOrganizationName" formControlName="supplierOrganizationName" type="text"
              [disableControl]="processing" required pInputText>
          </div>
        </div>


        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="messageToCustomer">
              Meddelande till kund
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="Meddelande i fritext från Swedish Consulting Group"></i>
              </span>
            </label>
            <p-editor formControlName="messageToCustomer" [style]="{'height':'320px'}"></p-editor>
            <!-- <input id="messageToCustomer" formControlName="messageToCustomer" type="text" [disableControl]="processing"
              pInputText> -->
          </div>
        </div>




        <ng-container *ngIf="!selectedFileId">
          <app-file-upload [header]="'Ladda upp fil'" (uploadSuccess)="onUploadDone($event)">
          </app-file-upload>
        </ng-container>

        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="isActive">
              Aktiv
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="Status för om delningsobjektet är aktivt"></i>
              </span>
            </label>
            <input type="checkbox" [formControlName]="'isActive'">
          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="isGDRP">
              PUB (Personuppgiftsbiträde)
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="Om konsulten har personuppgiftsbiträde"></i>
                <input type="checkbox" [formControlName]="'isGDPR'" >
              </span>
            </label>

          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="hasAcceptedPolicies">
              Följer Kunds Policys *
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="Intygande om att kunds policies efterföljs"></i>
              </span>
            </label>
            <input type="checkbox" [formControlName]="'hasAcceptedPolicies'">
          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="hasProtectedIdentity">
              Skyddad identitet
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="Om konsulten har skyddat identitet"></i>
              </span>
            </label>
            <input type="checkbox" [formControlName]="'hasProtectedIdentity'">
          </div>
        </div>

        <div class="p-grid p-mt-4">
          <div class="p-col-6">
            <p *ngIf="!selectedFileId" style="color: var(--pink-600);">Glöm inte ladda upp CV filen</p> 
            <p *ngIf="form.status ==='INVALID'" style="color: var(--pink-600);">Fyll i alla * markerade fälten</p> 
            <button pButton type="submit" iconPos="right" class="p-button p-button-raised p-text-uppercase"
              [label]="selectedSharedResumeId? 'Spara' : 'Skapa'" [disabled]="processing || (!selectedFileId || form.status === 'INVALID')"></button>
          </div>
        </div>

        <div class="p-mt-4" [hidden]="!processing">
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
      </form>
    </p-card>
  </div>
</div>