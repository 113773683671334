import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from "./login/login.component";
import {PrimeModule} from "../../prime-module.ts";


@NgModule({
  declarations: [
      LoginComponent
  ],
    imports: [
        CommonModule,
        PrimeModule
    ]
})
export class AuthModule { }
