export function getTemplateFooter(){
    return `
    <div style="text-align: center">
    <br>
    <br>
        <div style="text-align: center; display:block ruby">
            <p>Följ oss på våra sociala medier                    
            <a href="https://www.facebook.com/swcg1" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" data-safelink="true" data-linkindex="2">
                <img data-imagetype="External" src="https://swcg.com/wp-content/uploads/2021/04/facebook-white-3.png" alt="facebook" style="border:0px; height:auto; line-height:18px; outline:none; border-radius:1px" width="16" height="16">
            </a>
            <a href="https://www.linkedin.com/company/swedish-consulting-group/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" data-safelink="true" data-linkindex="1">
                <img data-imagetype="External" src="https://swcg.com/wp-content/uploads/2021/04/linkedin-white-2.png" alt="facebook" style="border:0px; height:auto; line-height:18px; outline:none; border-radius:1px" width="16" height="16"> 
            </a>   
            <a href="https://swcg.com/om-oss" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" data-safelink="true" data-linkindex="3">
            <span class="x_text-them-mode" style="font-size:10px"> <font class="x_text-them-mode" face="Arial">Kontakta oss</a>  |  <a href="https://brm.swcg.com/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" data-safelink="true" data-linkindex="4"> <font face="Arial"> Mina sidor</a>
            <br>
            Swedish Consulting Group</p>
        </div>
    </div>`
}

// <a href="https://brm.swcg.com/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" data-safelink="true" data-linkindex="4"> <font face="Arial">Mina sidor&nbsp;</font> </a>