<div class="p-grid p-justify-center" *ngIf="!processing">
    <div class="p-col-12 p-lg-7">
        <p-card>
          <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
              <div class="p-fluid p-grid">
                <div class="p-field p-col-6">
                  <label for="assignmentReference">
                    Referens 
                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                      pTooltip="Ange referens i fritext för identifikation"></i>
                    </span>
                  </label>
                  <input id="assignmentReference" formControlName="assignmentReference" type="text"
                    [disableControl]="processing" pInputText>
                </div>
              </div>
              <div class="p-fluid p-grid">
                <div class="p-field p-col-6">
                  <label for="header">
                    Titel
                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                      pTooltip="Ange en rubrik för avropet, exempelvis avropad roll och inriktning"></i>
                    </span>
                  </label>
                  <input id="header" formControlName="header" type="text" [disableControl]="processing" pInputText>
                </div>
              </div>
              <div class="p-fluid p-grid">
                <div class="p-field p-col-6">
                  <label for="assignmentTypeID">
                    Kategori
                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                      pTooltip="Vilken typ av genomförande för uppdraget"></i>
                    </span>
                  </label>
                  <select id="assignmentTypeID" formControlName="assignmentTypeID" [disableControl]="processing"
                    class="p-inputtext" style="appearance: auto">
                    <option></option>
                    <option [ngValue]="1">Timbank</option>
                    <option [ngValue]="2">Åtagande</option>
                    <option [ngValue]="3">Resurskonsult</option>
                  </select>
                </div>
              </div>
              <div class="p-fluid p-grid">
                <div class="p-field p-col-6">
                  <label for="priceTypeID">
                    Pristyp
                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                      pTooltip="Fast eller timbaserat arvode"></i>
                    </span>
                  </label>
                  <select id="priceTypeID" formControlName="priceTypeID" [disableControl]="processing" class="p-inputtext"
                    style="appearance: auto">
                    <option></option>
                    <option [ngValue]="1">Fastpris</option>
                    <option [ngValue]="2">Timpris</option>
                  </select>
                </div>
              </div>
              <div class="p-fluid p-grid">
                <div class="p-field p-col-6">
                  <div *ngIf="assignmentTypeID.value==1 || assignmentTypeID.value == 3 || assignmentTypeID.value==''">
                    <div *ngIf="!assignmentTypeID || assignmentTypeID.value==0">
                      <label for="assignmentEstimate">
                        Estimerad omfattning
                        <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                          pTooltip="Estimerad omfattning för avropet"></i>
                        </span>
                      </label>
                    </div>
                    <div *ngIf="assignmentTypeID.value==1">
                      <label for="assignmentEstimate">
                        Estimerad omfattning (Timmar)
                        <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                          pTooltip="En estimering av antal timmar för avropet"></i>
                        </span>
                      </label>
                    </div>
                    <div *ngIf="assignmentTypeID.value==3">
                      <label for="assignmentEstimate">
                        Estimerad omfattning (%)
                        <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                          pTooltip="Estimerad omfattning för avropet, angiven i procent"></i>
                        </span>
                      </label>
                    </div>
                  </div>
      
                  <div *ngIf="assignmentTypeID.value==1 || assignmentTypeID.value == 3 || assignmentTypeID.value==''">
                  <input id="assignmentEstimate" formControlName="assignmentEstimate" type="number"
                    [disableControl]="processing" pInputText>
                  </div>
                </div>
              </div>
              <label for="multipleResources" class="p-mr-4">Enskild leverans kan genomföras med flera resurser
                (frivilligt)</label>
              <div class="p-formgroup-inline p-mt-2">
                <div class="p-field-radiobutton ">
                  <p-radioButton label="Ja" id="multipleResources" formControlName="multipleResources" [value]="true">
                  </p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton label="Nej" id="multipleResources" [value]="false" formControlName="multipleResources">
                  </p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton label="Inget svar" checked id="multipleResources" [value]="null"
                    formControlName="multipleResources">
                  </p-radioButton>
                </div>
              </div>
              <div class="p-fluid p-grid">
                <div class="p-field p-col-6">
                  <label for="assignmentMaxPrice">
                    Takpris
                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                      pTooltip="Takpris för avropet som ej ska överstigas"></i>
                    </span>
                  </label>
                  <input id="assignmentMaxPrice" formControlName="assignmentMaxPrice" type="number"
                    [disableControl]="processing" pInputText>
                </div>
              </div>
              <div class="p-fluid p-grid p-formgrid">
                <div class="p-field p-col-12 p-md-4">
                  <label>
                    Startdatum
                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                      pTooltip="Förväntat startdatum för uppdraget"></i>
                    </span>
                  </label>
                  <p-calendar formControlName="startDate" id="startDate" dateFormat="yy/mm/dd"
                    placeholder="Startdatum">
                  </p-calendar>
                </div>
                <div class="p-field p-col-12 p-md-4">
                  <label>
                    Slutdatum
                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                      pTooltip="Förväntat slutdatum för uppdraget"></i>
                    </span>
                  </label>
                  <p-calendar formControlName="endDate" id="endDate" dateFormat="yy/mm/dd">
                  </p-calendar>
                </div>
              </div>
              <div class="p-field">
                <label>
                  Beskrivning
                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="Beskrivning av uppdraget"></i>
                </span>
              </label>
              <p-editor formControlName="description" [style]="{'height':'320px'}">
                <p-header>
                  <span class="ql-formats">
                    <select class="ql-size">
                      <option value="small">Petit</option>
                      <option selected></option>
                      <option value="large">Sous-titre</option>
                      <option value="huge">Titre</option>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-bold" aria-label="Bold"></button>
                    <button class="ql-italic" aria-label="Italic"></button>
                    <button class="ql-underline" aria-label="Underline"></button>
                    <button class="ql-strike" aria-label="Strike"></button>
                  </span>
                  <span class="ql-formats">
                    <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
                      <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
                      <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)"></option>
                    </select>
                    <span class="ql-format-separator"></span>
                    <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)">
                      <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
                      <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)"></option>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-list" title="List"></button>
                    <button class="ql-bullet" title="Bullet"></button>
                    <select title="Text Alignment" class="ql-align">
                      <option selected>Gauche</option>
                      <option value="center" label="Center"></option>
                      <option value="right" label="Right"></option>
                      <option value="justify" label="Justify"></option>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button aria-label="Link" class="ql-link"></button>
                    <button aria-label="Image" class="ql-image"></button>
                  </span>
                </p-header>
              </p-editor>
            </div>
              
              <div class="p-fluid p-grid">
                <div class="p-field p-col-6">
                  <label for="assignmentLocationTypeID">
                    Plats
                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                      pTooltip="Lokation för uppdraget"></i>
                    </span>
                  </label>
                  <select id="assignmentLocationTypeID" formControlName="assignmentLocationTypeID"
                    [disableControl]="processing" class="p-inputtext" style="appearance: auto">
                    <option></option>
                    <option [ngValue]="1">Förhandlingsbar</option>
                    <option [ngValue]="2">Flexibel</option>
                    <option [ngValue]="3">Remote</option>
                    <option [ngValue]="4">Onsite</option>
                  </select>
                </div>
              </div>
              <div class="p-fluid p-grid">
                <div class="p-field p-col-6">
                  <label for="assignmentLocation">
                    Uppdragsort
                    <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                      pTooltip="Orten där merparten av uppdraget kommer utföras"></i>
                    </span>
                  </label>
                  <input id="assignmentLocation" formControlName="assignmentLocation" type="text"
                    [disableControl]="processing" pInputText>
                </div>
              </div>
              <div class="p-mt-2">
                <small *ngIf="invalidForm" class="p-error">Misslyckades skapa annons. Kontrollera fälten</small>
              </div>
              <div class="p-grid p-mt-4">
                <div class="p-col-6">
                  <button pButton type="button" 
                    class="p-button p-button-raised p-text-uppercase" label="Avbryt" (click)="onCancel()"></button>
                </div>
                <div class="p-col-6">
                  <button pButton *ngIf="this.mode !== 'view'" type="submit"  id="create" iconPos="right" class="p-button p-button-raised p-text-uppercase" label="Skapa"></button>
                </div>
              </div>
            </form>
              
          </p-card>
      </div>
    </div>