<div class="p-grid p-justify-center">
  <div class="p-col-12 p-lg-7">
    <p-card [header]="selectedCallOffID ? 'Redigera Avrop' : 'Skapa Avrop'">
      <form [formGroup]="form" (ngSubmit)="onCreateClicked()">
        <div *ngIf="!isCustomer">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-6">
              <!-- <label for="customerID">Kund
                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="placeholder text"></i>
                </span>
              </label> -->
              <label for="organizationID">Välj kund</label>
              <p-dropdown
              placeholder="Välj en kund"
              [options]="customers"
              optionLabel="organizationName"
              inputId="organizationID"
              formControlName="organizationID"
              (onChange)="onChange($event)"
              optionValue="organizationID"
              [filter]="true">
              </p-dropdown>
              <!-- [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedOrganizationID" -->
              <!-- <select id="customerID" formControlName="customerID" [disableControl]="processing" class="p-inputtext"
                style="appearance: auto" (onchange)="onChange()">
                <option></option>
                <option *ngFor="let customer of customers" [ngValue]="customer.customerID">
                  {{customer.organizationName}}</option>
              </select> -->
            </div>
          </div>
      </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="assignmentReference">
              Referens 
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Ange referens i fritext för er identifikation"></i>
              </span>
            </label>
            <input id="assignmentReference" formControlName="assignmentReference" type="text"
              [disableControl]="processing" pInputText>
              <!-- <div *ngIf="assignmentReference.invalid" class="alert alert-danger">
              <div *ngIf="assignmenReference.errors?.['required']">
                Referens är obligatorisk.
              </div>
            </div> -->
          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="header">
              Titel
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Fyll i rubrik för avropet, exempelvis avropad roll och område"></i>
              </span>
            </label>
            <input id="header" formControlName="header" type="text" [disableControl]="processing" pInputText>
          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="assignmentTypeID">
              Kategori
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Välj uppdragstyp"></i>
              </span>
            </label>
            <select id="assignmentTypeID" formControlName="assignmentTypeID" [disableControl]="processing"
              class="p-inputtext" style="appearance: auto" (ngModelChange)="updateAssignmentTypeID($event)">
              <option></option>
              <option [ngValue]=1>Timbank</option>
              <option [ngValue]=2>Åtagande</option>
              <option [ngValue]=3>Resurskonsult</option>
            </select>
          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="priceTypeID">
              Pristyp
              <!-- <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Fast eller timbaserat arvode"></i>
              </span> -->
            </label>
            <div *ngIf="!assignmentTypeID">
              <select id="priceTypeID" formControlName="priceTypeID" [disableControl]="processing" class="p-inputtext"
                style="appearance: auto">
                <option></option>
                <option [ngValue]=1>Fastpris</option>
                <option [ngValue]=2>Timpris</option>
              </select>
            </div>
            <div *ngIf="assignmentTypeID">
              <div *ngIf="assignmentTypeID.value==1">
                <select id="priceTypeID" formControlName="priceTypeID" [disableControl]="processing" class="p-inputtext"
                  style="appearance: auto">
                  <option [ngValue]=2 default>Timpris</option>
                </select>
              </div>
              <div *ngIf="assignmentTypeID.value==2">
                <select id="priceTypeID" formControlName="priceTypeID" [disableControl]="processing" class="p-inputtext"
                  style="appearance: auto">
                  <option [ngValue]=1 default>Fastpris</option>
                </select>
              </div>
            </div>
            <div *ngIf="assignmentTypeID.value==3">
              <select id="priceTypeID" formControlName="priceTypeID" [disableControl]="processing" class="p-inputtext"
                style="appearance: auto">
                <option [ngValue]=2>Timpris</option>
              </select>
            </div>
          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <div *ngIf="assignmentTypeID.value==1 || assignmentTypeID.value == 3 || assignmentTypeID.value==''">
              <div *ngIf="!assignmentTypeID || assignmentTypeID.value==0">
                <label for="assignmentEstimate">
                  Estimerad omfattning
                  <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                    pTooltip="Fyll i omfattning för uppdraget"></i>
                  </span>
                </label>
              </div>
              <div *ngIf="assignmentTypeID.value==1">
                <label for="assignmentEstimate">
                  Estimerad omfattning (Timmar)
                  <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                    pTooltip="Fyll i antal timmar för uppdraget"></i>
                  </span>
                </label>
              </div>
              <div *ngIf="assignmentTypeID.value==3">
                <label for="assignmentEstimate">
                  Estimerad omfattning (%)
                  <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                    pTooltip="Fyll i omfattning för uppdraget"></i>
                  </span>
                </label>
              </div>
            </div>

            <div *ngIf="assignmentTypeID.value==1 || assignmentTypeID.value == 3 || assignmentTypeID.value==''">
            <input id="assignmentEstimate" formControlName="assignmentEstimate" type="number"
              [disableControl]="processing" pInputText>
            </div>
          </div>
        </div>
        <label for="multipleResources" class="p-mr-4">Enskild leverans kan genomföras med flera resurser
          (frivilligt)</label>
        <div class="p-formgroup-inline p-mt-2">
          <div class="p-field-radiobutton ">
            <p-radioButton label="Ja" id="multipleResources" formControlName="multipleResources" [value]="true">
            </p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton label="Nej" id="multipleResources" [value]="false" formControlName="multipleResources">
            </p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton label="Inget svar" checked id="multipleResources" [value]="null"
              formControlName="multipleResources">
            </p-radioButton>
          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="assignmentMaxPrice">
              Takpris
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Fyll i takpris för uppdraget"></i>
              </span>
            </label>
            <input id="assignmentMaxPrice" formControlName="assignmentMaxPrice" type="number"
              [disableControl]="processing" pInputText>
          </div>
        </div>
        <div class="p-fluid p-grid p-formgrid">
          <div class="p-field p-col-12 p-md-4">
            <label>
              Startdatum
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Välj önskat startdatum för uppdraget"></i>
              </span>
            </label>
            <p-calendar formControlName="startDate" id="startDate" dateFormat="yy/mm/dd"
              placeholder="Startdatum">
            </p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label>
              Slutdatum
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Välj slutdatum för uppdraget"></i>
              </span>
            </label>
            <p-calendar formControlName="endDate" id="endDate" dateFormat="yy/mm/dd">
            </p-calendar>
          </div>
        </div>
        <div class="p-field">
          <label>
            Beskrivning
          <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
            pTooltip="Fyll i uppdragsinformation"></i>
          </span>
        </label>
        <p-editor formControlName="description" [style]="{'height':'320px'}">
          <p-header>
            <span class="ql-formats">
              <select class="ql-size">
                <option value="small">Petit</option>
                <option selected></option>
                <option value="large">Sous-titre</option>
                <option value="huge">Titre</option>
              </select>
            </span>
            <span class="ql-formats">
              <button class="ql-bold" aria-label="Bold"></button>
              <button class="ql-italic" aria-label="Italic"></button>
              <button class="ql-underline" aria-label="Underline"></button>
              <button class="ql-strike" aria-label="Strike"></button>
            </span>
            <span class="ql-formats">
              <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
                <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
                <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)"></option>
              </select>
              <span class="ql-format-separator"></span>
              <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)">
                <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
                <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)"></option>
              </select>
            </span>
            <span class="ql-formats">
              <button class="ql-list" title="List"></button>
              <button class="ql-bullet" title="Bullet"></button>
              <select title="Text Alignment" class="ql-align">
                <option selected>Gauche</option>
                <option value="center" label="Center"></option>
                <option value="right" label="Right"></option>
                <option value="justify" label="Justify"></option>
              </select>
            </span>
            <span class="ql-formats">
              <button aria-label="Link" class="ql-link"></button>
              <button aria-label="Image" class="ql-image"></button>
            </span>
          </p-header>
        </p-editor>
      </div>
      <div class="p-fluid p-grid">
        <div class="p-field p-col-6">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12">
              <label for="assignmentLocationTypeID">
                Plats
                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="Välj plats"></i>
                </span>
              </label>
              <select id="assignmentLocationTypeID" formControlName="assignmentLocationTypeID"
              [disableControl]="processing" class="p-inputtext" style="appearance: auto">
              <option></option>
              <option [ngValue]=1>Förhandlingsbar</option>
              <option [ngValue]=2>Flexibel</option>
              <option [ngValue]=3>Remote</option>
              <option [ngValue]=4>Onsite</option>
            </select>
          </div>
          <div class="p-field p-col-12">
            <label for="assignmentLocation">
              Uppdragsort
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Fyll i var uppdraget kommer utföras"></i>
              </span>
            </label>
            <input id="assignmentLocation" formControlName="assignmentLocation" type="text"
            [disableControl]="processing" pInputText>
          </div>
          <div class="p-field p-col-12">
            <button pButton type="button" iconPos="right"
                  class="p-button p-button-raised p-text-uppercase" label="Välj Beställare" (click)="showUserList()"></button>
          </div>
          <div class="p-col-6 p-card p-ml-2 p-pl-4 p-py-2">
            <div  style="cursor: default;
            display: flex;
            align-items: center;
            justify-content: space-evenly;">Visa ansvariga användare <i (mouseover)="showHoverList = true" (mouseout)="showHoverList = false" style="font-size: 2rem;" class="pi pi-info-circle"></i></div>
          </div>
        </div>
      </div>
          <div *ngIf="showHoverList && picked.length > 0" class="p-field p-col-5 p-card p-ml-3">
            <p-table [value]="this.picked">
              <ng-template pTemplate="header">
                <tr>
                  <th>Namn</th>
                  <th>Kontaktperson</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr>
                  <td>{{item.fullName}}</td>
                  <td><i *ngIf="item.isResponsible" class="pi pi-check-circle p-ml-6"></i></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
      </div>
        

        <div class="p-mt-2">
          <small *ngIf="invalidForm" class="p-error">Misslyckades skapa avrop. Kontrollera fälten</small>
        </div>
        <div class="p-grid p-mt-4">
          <div class="p-col-6">
            <button pButton
                    type="button"
                    icon="pi pi-chevron-left"
                    class="p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka"
                    [disabled]="processing"
                    (click)="onShowBrokerCalloffs()"></button>
          </div>
          <div class="p-col-6 p-text-right">
            <button pButton type="submit"  iconPos="right"
              class="p-button p-button-raised p-text-uppercase" [label]="selectedCallOffID ? 'Spara' : 'Skapa'" [disabled]="processing"></button>
          </div>
        </div>
        <div class="p-mt-4" [hidden]="!processing">
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
      </form>
    </p-card>
  </div>
</div>
