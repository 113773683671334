import { CreateUserToAssignmentDTO } from './../models/assignment/create-assignment.model';
import { CreateBrokerTenderCommentDTO } from 'src/app/models/assignment-children/broker-tender.model';
import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {Assignment, AssignmentDTO} from "../models/assignment/assignment.model";
import {AssignmentList} from "../models/assignment/assignment-list.model";
import {CreateAssignment} from "../models/assignment/create-assignment.model";
import {UpdateAssignment} from "../models/assignment/update-assignment.model";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AssignmentService extends BaseService<Assignment, AssignmentList, CreateAssignment, UpdateAssignment> {
    endpointBaseURL = 'assignment'

    getAssignmentSupplier(assignmentId:number):Observable<AssignmentDTO> {
        return this.http.get<AssignmentDTO>(`${this.endpointBaseURL}/supplier/${assignmentId}`);
    }

    updateAssignmentWithResponsible(userToAssignment: CreateUserToAssignmentDTO): Observable<any> {
        return this.http.post(`${this.endpointBaseURL}/UserToAssignment`, userToAssignment) 
    };

    archive(assignmentID: Number) : Observable<Boolean>{
        return this.http.delete<Boolean>(`${this.endpointBaseURL}/${assignmentID}/archive`);
    } 

    getResponsibleUsers(assignmentID: number): Observable<any> {{assignmentID}
        return this.http.get(`${this.endpointBaseURL}/${assignmentID}/ResponsibleUsers`);
    }

    getUsers(assignmentID: number): Observable<any> {
        return this.http.get(`${this.endpointBaseURL}/${assignmentID}/UserToAssignment`);
    }

    updateAssignment<updateAssignment>(assignmentID: number, entity: updateAssignment): Observable<boolean>{
        return this.http.put<boolean>(`${this.endpointBaseURL}/${assignmentID}`, entity)
    }
    getSupplierContact(supplierTenderID: number): Observable<any> {
        return this.http.get(`${this.endpointBaseURL}/${supplierTenderID}/SupplierContact`)
    }
}