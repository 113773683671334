import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Event, CreateEvent, EventList, UpdateEvent } from '../models/event/event.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseService<Event, EventList, CreateEvent, UpdateEvent> {
endpointBaseURL = 'event'
  
getAllEvents(): Observable<any> {
return this.http.get(`${this.endpointBaseURL}`);
}

}
