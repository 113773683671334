import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { UserToAssignmentComponent } from './user-to-assignment/user-to-assignment.component';
import { FormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { CardModule } from 'primeng/card';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TableModule } from 'primeng/table';
import {ButtonModule} from 'primeng/button';



@NgModule({
  declarations: [
    UserToAssignmentComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DynamicDialogModule,
    FormsModule,
    DropdownModule,
    CardModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    TableModule,
    ButtonModule
  ],
  providers: [
    DialogService,
  ]
})
export class UserToAssignmentModule { }
