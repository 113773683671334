import { Component, Input, OnInit } from '@angular/core';
import { MenuItem, LazyLoadEvent, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { BrokerCallOffListItem } from 'src/app/models/assignment-children/broker-call-off.model';
import { BrokerCallOffService } from 'src/app/services/broker-call-off.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { OrganizationService } from 'src/app/services/organization.service';
import { AssignmentChildrenStatus } from 'src/app/models/status/status.enum';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { convertToLocaleStringFromUTC } from 'src/app/utils/date';

@Component({
  selector: 'app-broker-call-off-list',
  templateUrl: './broker-call-off-list.component.html',
  styles: [
  ]
})
export class BrokerCallOffListComponent implements OnInit {
  @Input() assignmentRef: string;
  tableSettings: any = environment.applicationSettings.table;
  brokerCallOffs: BrokerCallOffListItem[];
  restrictedBrokerCallOffs: BrokerCallOffListItem[];
  selectedCallOff: BrokerCallOffListItem | any;
  items: MenuItem[];
  pageIndex: number = 1;
  pageSize: number = this.tableSettings.defaultPageSize;
  pageTotal: number;
  loading: boolean;
  user: UserAuthenticateResponse;
  showAllCallOffs = false;
  usersOrganizationName: string;
  maxCallOffStatus: number = AssignmentChildrenStatus.CustomerCallOffSent;
  usersOrganizationIsCustomer: boolean;
  state: string;
  headerString: string;
  convertDate = convertToLocaleStringFromUTC;
  organizationTypeEnum = OrganizationType;
  organizationTypeNbr: Number;

  constructor(private router: Router, private brokerCallOffService: BrokerCallOffService, private authService: AuthService, private organizationService: OrganizationService) { }

  ngOnInit(): void {
    this.user = this.authService.getUserFromStorage();
    this.organizationTypeNbr = Number(this.user.organizationTypeID);
    if (this.organizationTypeNbr === OrganizationType.broker) {
      this.showAllCallOffs = true;
      this.state = 'broker';
      this.headerString = 'Mäklaravrop';
    }
    else if (this.organizationTypeNbr === OrganizationType.supplier){
      this.state = 'supplier';
      this.headerString = 'Publicerade uppdrag';
    }
    else if (this.organizationTypeNbr === OrganizationType.customer)
    {
      this.state = 'customer';
      this.headerString = 'Mina avrop';
    }
    else {
      this.state = 'undefined';
    }
    this.usersOrganizationIsCustomer = this.organizationTypeNbr === OrganizationType.customer;
    this.organizationService.get(Number(this.user.organizationID)).subscribe(
      (response: any) => {
        this.usersOrganizationName = response.organizationName;
      },
      (error) => {
        console.error(error);
      }
    );
    this.getBCO();
  }

  loadBrokerCallOffs(event: LazyLoadEvent) {
    const { first, filters, sortField, sortOrder } = event;
    this.pageIndex = (<number>first / 10) + 1;
  }

  private getBCO() {
    this.brokerCallOffService.getBrokerCallOffs(Number(this.user.organizationTypeID)).subscribe(res => {
      this.brokerCallOffs = res['brokerCallOffs'];
      this.brokerCallOffs.forEach(item => {
        item.created = this.convertDate(item.created);
      })
      this.brokerCallOffs.reverse();
    })
  }

  onViewBrokerCallOffClicked(brokerCallOff: BrokerCallOffListItem) {
    if(this.organizationTypeNbr === OrganizationType.supplier) {
      //öppna specifik annons - läsa annons och ansöka
      this.router.navigate([`/broker-call-off/${brokerCallOff.brokerCallOffID}`]);
    }
    else { 
    this.router.navigate([`/broker-tender-board/${brokerCallOff.brokerCallOffID}`]);
  }
  }

  onEditCallOffClicked(brokerCallOff: BrokerCallOffListItem) {
    this.router.navigate([`/call-off/editCallOff/${brokerCallOff.brokerCallOffID}`]);
  }
}
