<form [formGroup]="commentForm" (ngSubmit)="onSendComment()">
    <div class="p-field p-col-4">
        <label for="comment">{{ title }}</label>
        <textarea rows="10" cols="60 " id="comment" formControlName="comment" type="textbox" pInputTextarea></textarea>
    </div>

    <div class="p-col-4 p-text-right">
        <button pButton type="submit" iconPos="right"
            class="p-button p-button-raised p-text-uppercase" label="Skicka kommentar" [disabled]=""></button>
    </div>
</form>