<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div *ngIf="showError" class="error-message">
    <p *ngIf="sendEmail && this.eventID==eventEnum.Publish" style="color: red;">Ämne, Beskrivning och region är obligatoriska.</p>
    <p *ngIf="sendEmail && this.eventID==eventEnum.Unpublish" style="color: red;">Ämne och Beskrivning är obligatoriska.</p>
    <p *ngIf="!sendEmail" style="color: red;">Region är obligatorisk.</p>
  </div>

  <label *ngIf="this.eventID==eventEnum.Publish" for="region">Region</label>
  <div *ngIf="this.eventID==eventEnum.Publish"  class="p-field">
    <select formControlName="selectedRegionID" class="p-inputtext" id="region">
      <option [ngValue]="null">Välj en region</option>
      <option [ngValue]="2">Väst</option>
      <option [ngValue]="3">Öst</option>
      <option [ngValue]="4">Mitt</option>
      <option [ngValue]="5">Norr</option>
      <option [ngValue]="6">Syd</option>
      <option [ngValue]="1">Övrigt</option>
    </select>
  </div>

  <div class="p-field-checkbox">
    <p-checkbox formControlName="sendEmail" [binary]="true" [label]="sendEmailValue ? 'Skicka detta e-mail till kund?' : 'Skicka e-mail till kund?'"></p-checkbox>
  </div>
  <div class="p-field-checkbox">
  <p-checkbox *ngIf="this.eventID==eventEnum.Publish" formControlName="sendPublishEmailToSubscribers" [binary]="true" label="Skicka e-mail om publicering till prenumeranter?"></p-checkbox>
  </div>

  <div *ngIf="sendEmailValue">
    <label for="notificationSubject">Ämne</label>
    <div class="p-field">
      <div class="p-field">
        <input type="text" id="notificationSubject" formControlName="notificationSubject" pInputText class="full-width-input">
      </div>
    </div>

    <div class="p-field">
      <label>Beskrivning</label>
      <p-editor formControlName="notificationContent" [style]="{'height':'320px'}"></p-editor>
    </div>
  </div>

  <button *ngIf="this.eventID==eventEnum.Publish" pButton type="submit" [label]="sendEmailValue || sendEmailToSubscribersValue ? 'Publicera och Skicka e-post' : 'Publicera'" class="p-button p-button-raised p-text-uppercase" style="margin-right: 10px"></button>
  <button *ngIf="this.eventID==eventEnum.Unpublish" pButton type="submit" [label]="sendEmailValue ? 'Avpublicera och Skicka e-post' : 'Avpublicera'" class="p-button p-button-raised p-text-uppercase" style="margin-right: 10px"></button>
  <button type="button" pButton label="Avbryt" class="p-button-secondary p-button-raised p-text-uppercase" (click)="onCancel()"></button>


</form>
