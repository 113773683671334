import { Component, OnInit } from '@angular/core';
import {LazyLoadEvent, MenuItem} from "primeng/api";
import {RoleService} from "../../../services/role.service";
import {Role} from "../../../models/role/role.model";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styles: [
    ]
})
export class RolesComponent implements OnInit {

    tableSettings: any = environment.applicationSettings.table;
    roles: Role[];
    selectedRole: Role | any;
    items: MenuItem[];

    pageIndex: number = 1;
    pageSize: number = this.tableSettings.defaultPageSize;
    pageTotal: number;

    loading: boolean;

    constructor(private router: Router, private roleService: RoleService) { }

    ngOnInit() {
        this.fetchFilteredRoles({});
        this.items = [
            {label: 'Visa', icon: 'pi pi-fw pi-search', command: () => this.onViewRoleClicked(this.selectedRole)},
            {label: 'Redigera', icon: 'pi pi-fw pi-user-edit', command: () => this.onEditRoleClicked(this.selectedRole)}
        ];
    }

    loadRoles(event: LazyLoadEvent) {
        const {first, filters, sortField, sortOrder} = event;

        this.pageIndex = (<number>first / 10) + 1;

        this.fetchFilteredRoles({filters, sortField, sortOrder});
    }

    private fetchFilteredRoles(filters: any) {
        if(this.loading) {
            return;
        }

        this.loading = true;

        this.roleService.getAllFiltered(filters, this.pageIndex, this.pageSize)
            .toPromise()
            .then((roleList: any) => {
                this.roles = roleList.roles;
                this.pageTotal = roleList.pageTotal;
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
            });
    }

    onViewRoleClicked(role: Role) {
        this.router.navigate([`/role/${role.id}`])
    }

    onEditRoleClicked(role: Role) {
        this.router.navigate([`/role/edit/${role.id}`])
    }

    onCreateRoleClicked() {
        this.router.navigate([`/role/create`])
    }
}
