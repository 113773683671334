import { AssignmentTemplatesComponent } from './../assignment-templates/assignment-templates/assignment-templates.component';
import { DialogService } from 'primeng/dynamicdialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PrimeModule} from "../../prime-module.ts";
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ResumeModalComponent } from './resumes/resume-modal/resume-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TenderBoardComponent } from './tender-board/tender-board.component';
import { ReceiverItemComponent } from './receiver-item/receiver-item.component';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { TenderCommentsModalComponent } from './tender-comments/tender-comments-modal';
import { CallOffBoardComponent } from './call-off-board/call-off-board.component';
import { TenderArchiveComponent } from './tender-archive/tender-archive.component';

@NgModule({
  declarations: [
    ResumeModalComponent,
    TenderBoardComponent,
    ReceiverItemComponent,
    TenderCommentsModalComponent,
    CallOffBoardComponent,
    AssignmentTemplatesComponent,
    TenderArchiveComponent,
  ],
  imports: [
    CommonModule,
    PrimeModule,
    DynamicDialogModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [
    DialogService,
    SharedComponentsModule
  ],
  exports: [TenderBoardComponent]
})
export class AssignmentBoardModule { }