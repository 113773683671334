import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { OrganizationTemplate } from 'src/app/models/organization/organization-template.model';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-organization-template',
  templateUrl: './organization-template.component.html',
  styles: [
  ]
})
export class OrganizationTemplateComponent implements OnInit {
  user: UserAuthenticateResponse
  token: string;
  organizationTemplateList: OrganizationTemplate[];
  items: MenuItem[];
  pageSize: number = environment.applicationSettings.table.defaultPageSize;
  options = environment.applicationSettings.table.defaultTextMatchMode;
  records: number;
  pageIndex: number = 1;
  loading: boolean;
  events: [];
  selectedTemplate: OrganizationTemplate;
  constructor(
    private authService: AuthService,
    private orgService: OrganizationService,
    private router: Router) { }

  ngOnInit(): void {
    this.token = this.authService.getTokenFromStorage();
    this.user = this.authService.getUserFromStorage();
    this.getAll();
    // this.getOrganizationTemplates();
  }

  loadTemplates(event: LazyLoadEvent) {
    const{first, filters, sortField, sortOrder} = event;
    this.pageIndex = (<number>first/10)+1;
  }

  onCreateOrganizationTemplateClicked(){
    this.router.navigate(['/organization-template-create'])
  }

  onEditOrganizationClicked(organizationTemplate: OrganizationTemplate) {
    this.router.navigate([`/organization-template-create/${organizationTemplate.organizationTemplateID}`])
  }

  getAll(){
    this.orgService.getAllTemplates().subscribe(res => {
      // console.log(res);
      this.organizationTemplateList = res['templates'];
      console.log(this.organizationTemplateList);
    })
  }
  onOrganizationTemplateClicked(organizationTemplate: OrganizationTemplate){
    console.log("grej");
  }

   getOrganizationTemplates(){
    this.orgService.getOrganizationTemplates(1, 1).subscribe(res => {
      this.organizationTemplateList = res;
      console.log(res);
    })
   }

}
