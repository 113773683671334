<div class="p-grid p-justify-center">
    <div class="p-col-12 p-lg-7">

        <!-- <p-panel header="Min Organisation"> -->
        <p-card header="Min Organisation">
    
            <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>
            <ng-container *ngIf="state === 'editOrg'">
                <app-create-organization [passedOrganizationId]="organizationId"></app-create-organization>

            </ng-container>
            <ng-container *ngIf="state === 'settings'">
                <app-organization-settings></app-organization-settings> 

            </ng-container>
            <ng-container *ngIf="state === 'competencies'">
                <app-organization-competencies></app-organization-competencies>

            </ng-container>
        </p-card>

        <!-- </p-panel> -->
    </div>
</div>