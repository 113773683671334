<p-contextMenu #cm [model]="items"></p-contextMenu>
<p-card header="Roller">
    <p-toolbar styleClass="p-mb-4">
        <ng-template styleClass="p-mb-4" pTemplate="left">
            <button
                    (click)="onCreateRoleClicked()"
                    pButton
                    pTooltip="Skapa ny roll"
                    tooltipPosition="top"
                    
                    class="p-button-raised p-button p-text-uppercase"
                    label="Skapa"></button>
        </ng-template>
    </p-toolbar>
    <p-table #rolesData
             styleClass="p-datatable-m"
             [value]="roles"
             [lazy]="true"
             (onLazyLoad)="loadRoles($event)"
             [paginator]="true"
             [rows]="this.pageSize"
             [totalRecords]="pageTotal"
             [loading]="loading"
             [(contextMenuSelection)]="selectedRole" [contextMenu]="cm" dataKey="id"
             [globalFilterFields]="['id', 'name','created','lastModified', 'createdByUser', 'lastModifiedByUser']">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 11rem" pSortableColumn="id">Id <p-sortIcon field="id"></p-sortIcon></th>
                <th style="width: 24rem" pSortableColumn="name">Namn <p-sortIcon field="name"></p-sortIcon></th>
                <th style="width: 20rem" pSortableColumn="created">Skapad <p-sortIcon field="created"></p-sortIcon></th>
                <th style="width: 20rem" pSortableColumn="lastModified">Uppdaterad <p-sortIcon field="lastModified"></p-sortIcon></th>
                <th style="width: 20rem" pSortableColumn="createdBy">Skapad av <p-sortIcon field="createdByUser"></p-sortIcon></th>
                <th style="width: 20rem" pSortableColumn="lastModifiedByUser">Uppdaterad av <p-sortIcon field="lastModifiedByUser"></p-sortIcon></th>
                <th style="width: 11rem" class="p-text-center"></th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter
                            [matchModeOptions]="tableSettings.defaultNumericMatchMode"
                            type="text"
                            field="id">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter
                            [matchModeOptions]="tableSettings.defaultTextMatchMode"
                            type="text"
                            field="name">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter
                            [matchModeOptions]="tableSettings.defaultDateMatchMode"
                            type="date"
                            field="created">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter
                            [matchModeOptions]="tableSettings.defaultDateMatchMode"
                            type="date"
                            field="lastModified">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter
                            [matchModeOptions]="tableSettings.defaultTextMatchMode"
                            type="text"
                            field="createdByUser">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter
                            [matchModeOptions]="tableSettings.defaultTextMatchMode"
                            type="text"
                            field="lastModifiedByUser">
                    </p-columnFilter>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-role>
            <tr [pContextMenuRow]="role">
                <td>{{role.id}}</td>
                <td>{{role.name}}</td>
                <td>{{role.created}}</td>
                <td>{{role.lastModified}}</td>
                <td>{{role.createdByUser}}</td>
                <td>{{role.lastModifiedByUser}}</td>
                <td class="p-text-right p-pr-4">
                    <button
                            (click)="onViewRoleClicked(role)"
                            pButton
                            pRipple
                            pTooltip="Visa rolll"
                            tooltipPosition="top"
                            type="button"
                            icon="pi pi-search"
                            class="p-button-rounded p-button-text p-button-info"></button>
                    <button
                            (click)="onEditRoleClicked(role)"
                            pButton
                            pRipple
                            pTooltip="Redigera roll"
                            tooltipPosition="top"
                            type="button"
                            icon="pi pi-user-edit"
                            class="p-button-rounded p-button-text p-button-info"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>