import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Receiver } from 'src/app/models/receiver/receiver.model';

@Component({
  selector: 'app-receiver-item',
  templateUrl: './receiver-item.component.html',
  styles: [
  ]
})
export class ReceiverItemComponent implements OnInit {
  @Input() receiver: Receiver;
  @Output() onDeleteReceiver: EventEmitter<Receiver> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onDelete(receiver: Receiver){
    this.onDeleteReceiver.emit(receiver);
  }

}
