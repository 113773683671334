import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PrimeModule } from "src/app/prime-module.ts";
import { ContractExtensionComponent } from "./contract-extension/contract-extension.component";
import { ContractListComponent } from "./contract-list/contract-list.component";
import { CreateContractComponent } from "./create-contract/create-contract.component";
import { ContractComponent } from './contract/contract.component';
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { SharedComponentsModule } from "../shared/shared-components.module";
import { DialogService, DynamicDialogModule } from "primeng/dynamicdialog";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DropdownModule } from "primeng/dropdown";
import { FormsModule } from "@angular/forms";
import { CardModule } from "primeng/card";
import { ProgressBarModule } from "primeng/progressbar";
import { ContractEmailComponent } from './contract-email/contract-email.component';
import { ContractExtensionCreateComponent } from './contract-extension-create/contract-extension-create.component';
import { ContractExtensionModalComponent } from './contract-extension-modal/contract-extension-modal.component';
import { ContractArchiveComponent } from './contract-archive/contract-archive.component';
import { SignContractExtensionComponent } from './sign-contract-extension/sign-contract-extension.component';

@NgModule({
    declarations: [
        CreateContractComponent,
        ContractListComponent,
        ContractExtensionComponent,
        ContractComponent,
        ContractEmailComponent,
        ContractExtensionCreateComponent,
        ContractExtensionModalComponent,
        ContractArchiveComponent,
        SignContractExtensionComponent
  ],
    imports: [
       PrimeModule,
       CommonModule,
       SharedComponentsModule,
       NgxExtendedPdfViewerModule
    ],
    providers:  [
        
    ],
    exports: [
       
    ]
})

export class ContractModule {}