import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Contract, ContractExtensionReceiverResponseDTO, ContractList, CreateContract, CreateContractExtension, UpdateContract, UpdateContractExtension } from "../models/contract/contract.model";
import { OrganizationList } from "../models/organization/organization-list.model";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})

export class ContractService extends BaseService<Contract, ContractList, CreateContract, UpdateContract> {
  
  endpointBaseURL = 'contract';

  getContracts(organizationId: number): Observable<any> {
    return this.http.get<any>(
      `contract`
    );
  }

  getAllContracts(organizationId: number): Observable<any> {
    return this.http.get<any>(`contract/?organizationID=${organizationId}`);
  }

  getAllSupplierContracts(organizationId: number): Observable<any> {
    return this.http.get<any>(`contract/supplier?organizationID=${organizationId}`);
  }

  getAllArchivedContracts(organizationId: number): Observable<any> {
    return this.http.get<any>(`contract/archive/?organizationID=${organizationId}`);
  }

  activateContract(contractId: number, updateContract: UpdateContract): Observable<any> {
    return this.http.put<any>(`contract/${contractId}`, updateContract);
  }

  createContractExtension(contractExtenstion: CreateContractExtension): Observable<any> {
    return this.http.post<any>(`contractExtension`, contractExtenstion);
  }

  updateContractExtension(contractExtensionId: number, updateContractExtenstion: UpdateContractExtension): Observable<any> {
    return this.http.put<any>(`contractExtension/${contractExtensionId}`, updateContractExtenstion);
  }

  getContractExtension(contractExtensionId: number): Observable<any> {
    return this.http.get<any>(
      `contractextension/${contractExtensionId}`
    );
  }

  getContractExtensions(organizationId: number): Observable<any> {
    return this.http.get<any>(
      `contractextension`
    );
  }

  getContractExtensionAndConsultantByToken(token: string): Observable<any> {
    return this.http.get(`contractExtensionReceiver/token/${token}`);
  }

  activateContractExtension(contractExtensionId: number, updateContractExtension: UpdateContractExtension): Observable<any> {
    return this.http.put<any>(`contractextension/activate/${contractExtensionId}`, updateContractExtension);
  }

  updateContractExtensionReceiverResponse(token: string, dto: ContractExtensionReceiverResponseDTO): Observable<any> {
    return this.http.put<any>(`contractExtensionReceiver/token/${token}`, dto);
  }
}