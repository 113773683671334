import { Component, OnInit } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

@Component({
  selector: "app-error-dialog",
  templateUrl: "./error-dialog.component.html",
})
export class ErrorDialogComponent implements OnInit {
  message: String;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.message = this.config.data.message;
  }

  closeMessage() {
    this.ref.close();
  }
}
