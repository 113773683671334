<div class="p-grid p-justify-center">
  <div class="p-col-12 p-lg-7">
    <p-card header="">
      <form [formGroup]="form" (ngSubmit)="onCreateClicked(comment.value)">
        <div class="p-mt-4">
          <p class="p-text-bold">Skriv Kommentar</p>
          <p-divider></p-divider>
        </div>
        
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
        <textarea id="comment" formControlName="comment" rows="4" cols="35" pInputTextarea [autoResize]="true" [disableControl]="processing" #comment></textarea>
      </div>
    </div>
        <div class="p-mt-2">
          <small *ngIf="invalidForm" class="p-error">Misslyckades skapa kommentar</small>
        </div>
        <div class="p-grid p-mt-4">
          <div class="p-col-4 p-text-right">
            <button pButton type="submit" iconPos="right"
              class="p-button p-button-raised p-text-uppercase" label="Skapa" [disabled]="processing"></button>
          </div>
        </div>
        <div class="p-mt-4" [hidden]="!processing">
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
      </form>
    </p-card>
  </div>
</div>

  <p class="p-text-bold">Kommentarer</p>
<ul class="comments" style="list-style:none">
  <li *ngFor="let comment of tenderComments">
<p>{{comment.created | date: 'yyyy/MM/dd'}}</p>
<div class="cv-button" style="display: flex; align-items: center;">
  <p style="margin: 10px;font-size: 18px;"><b>{{comment.createdByUser}}</b>: {{comment.comment}}</p>
</div>
