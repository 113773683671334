import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/services/auth.service';
import { BrokerCallOffService } from 'src/app/services/broker-call-off.service';
import { convertToLocaleStringFromUTC } from 'src/app/utils/date';

@Component({
  selector: 'app-view-broker-call-off-supplier-modal',
  templateUrl: './view-broker-call-off-supplier-modal.component.html',
  styles: [
  ]
})

export class ViewBrokerCalloffSupplierModalComponent implements OnInit {
  supplierTenderID: number;
  processing :boolean;
  brokerCallOffObject: any;
  estimate: string;
  convertDate = convertToLocaleStringFromUTC;

  constructor(
    public authService: AuthService,
    public config: DynamicDialogConfig,
    private brokerCallOffService: BrokerCallOffService,
    public modalRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    this.processing = true;
    this.supplierTenderID = this.config.data.supplierTenderId;

    this.brokerCallOffService.getBrokerCallOffWithSupplierTenderId(this.supplierTenderID).subscribe(res => {

      this.brokerCallOffObject = res;
      this.checkEstimate();

      if (res.startDate !== null) {
        this.brokerCallOffObject.startDate = this.convertDate(res.startDate);
        this.brokerCallOffObject.startDate = this.brokerCallOffObject.startDate.replace(',', '');
      }

      if (res.endDate !== null) {
        this.brokerCallOffObject.endDate = this.convertDate(res.endDate);
        this.brokerCallOffObject.endDate = this.brokerCallOffObject.endDate.replace(',', '');
      }
      this.processing = false;
    });
  }

  checkEstimate() {
    if (Number(this.brokerCallOffObject.assignmentEstimate) > 0)
      this.estimate = this.brokerCallOffObject.assignmentEstimate;
    else {
      this.estimate = '-';
    }
  }

  onCancel(): void {
    this.modalRef.close(false);
  }
}