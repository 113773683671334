import { ReturnStatement } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Event } from "src/app/models/event/event.model";
import { OrganizationTemplate } from "src/app/models/organization/organization-template.model";
import { UserAuthenticateResponse } from "src/app/models/user/user-authenticate-response.model";
import { AuthService } from "src/app/services/auth.service";
import { EventService } from "src/app/services/event.service";
import { OrganizationService } from "src/app/services/organization.service";

@Component({
    selector: 'app-organization-template-create',
    templateUrl: './organization-template-create.component.html',
    styles: [
    ]
  })
  export class OrganizationTemplateCreateComponent implements OnInit {
    token: string
    user: UserAuthenticateResponse
    templateForm: FormGroup;
    processing: boolean;
    orgTemplate: OrganizationTemplate;
    events: Event[]
    selectedOrganizationTemplateId: number;

    constructor(
        private authService: AuthService,
        private orgService: OrganizationService,
        private eventService: EventService,
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.token = this.authService.getTokenFromStorage();
        this.user = this.authService.getUserFromStorage();
        this.route.params.subscribe(params => {
          if (params?.organizationTemplateId) {
            this.selectedOrganizationTemplateId = Number(params.organizationTemplateId);
            this.fetchOrganizationTemplate(Number(params.organizationTemplateId));
          }
        });

        this.getEvents();

        this.templateForm = this.formBuilder.group({
            organizationID: [this.user.organizationID],
            name: [""],
            contentString: [""],
            eventID: [null],
      })
    }

      fetchOrganizationTemplate(organizationTemplateId: number):void {
        this.orgService.getOrganizationTemplate(organizationTemplateId).subscribe(res => {
          this.templateForm.get('eventID')?.setValue(res.eventID);
          this.templateForm.get('contentString')?.setValue(res.contentString);
          this.templateForm.get('name')?.setValue(res.name);
        })
      }

      getEvents() {
        this.eventService.getAllEvents().subscribe(res =>{
          this.events = res['events'];
        })
      }

      onCreateClicked(){
        this.processing = true;
        this.orgTemplate = this.templateForm.value;
        
        if(this.selectedOrganizationTemplateId) {
          this.onSaveClicked();
          return;
        }

        this.orgService.createOrganizationTemplate(this.orgTemplate).subscribe(res=>{
          this.processing = false;
          this.router.navigate(["/organization-template"])
        },
        error => {
          this.processing = false;
        })
      }

      onSaveClicked() {
        this.orgService.updateOrganizationTemplate(this.selectedOrganizationTemplateId, this.orgTemplate).subscribe(res => {
          this.processing = false;
          this.router.navigate(["/organization-template"]);
        },
        error => {
          this.processing = false;
        });
      }

      onBackClicked() {
        if (this.processing) {
          return;
        }
        this.router.navigate(["/organization-template"]);
      };  
  }