<p-card [style]="{'margin-bottom': '25px'}" header="Kompetenser">
  <div *ngIf="!processing">
    <div style="display: flex; justify-content: start; font-size: 16px; margin-bottom: 25px;">
      <ul *ngFor="let comp of competencies">
        <li><b>{{comp.compName}}:</b> {{comp.compLvlName}}</li>
      </ul>
    </div>
    <button pButton label="Redigera (WIP)"></button>
  </div>

  <div *ngIf="processing">
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>
  </p-card>