
<ng-container *ngIf="loaded && sharedResume.isActive">

  <p-card header="KonsultCV" [style]="{'margin-bottom': '25px'}">
    <div style="display: flex; justify-content: start; font-size: 16px;">
      <div style="width: 400px;">
        <p>Avropsnummer: <b>{{ sharedResume.callOffNumber }}</b></p>
        <p>Anbudsreferens: <b>{{ sharedResume.tenderReference }} </b></p>

        <p>Utgångsdatum: <b>{{ sharedResume.validUntil | date:'dd/MM/yyyy' }}</b></p>
      </div>
      <div style="width: 400px;">
        <p>Konsultnamn: <b>{{ sharedResume.consultantName }}</b></p>
        <p>Konsultbolag: <b>{{ sharedResume.supplierOrganizationName }}</b></p>
        <p>Meddelande från Swedish Consulting Group: <b [innerHTML]="sharedResume.messageToCustomer"></b></p>
      </div>
      <div style="width: 400px; margin-left: 80px;">
        <p>Accepterat policies : 
          <ng-container *ngIf="sharedResume.hasAcceptedPolicies">
            <i class="pi pi-check-circle"></i>
          </ng-container>
          <ng-container *ngIf="!sharedResume.hasAcceptedPolicies">
            <i class="pi pi-circle-off"></i>
          </ng-container>
        </p>
        <p>Skyddad identitet: 
          <ng-container *ngIf="sharedResume.hasProtectedIdentity">
            <i class="pi pi-check-circle"></i>
          </ng-container>
          <ng-container *ngIf="!sharedResume.hasProtectedIdentity">
            <i class="pi pi-circle-off"></i>
          </ng-container>
        </p>
        <p>PUB:
          <ng-container *ngIf="sharedResume.isGDPR">
            <i class="pi pi-check-circle"></i>
          </ng-container>
          <ng-container *ngIf="!sharedResume.isGDPR">
            <i class="pi pi-circle-off"></i>
          </ng-container>
        </p>
      </div>
    </div>
  </p-card>



  <div *ngIf="pdfSrc != null">
    <div class="pdf-test">
      <ngx-extended-pdf-viewer 
        [src]="pdfSrc"
        [useBrowserLocale]="true"
        [showToolbar]="true"
        [showPrintButton]="state === 'broker'"
        [showDownloadButton]="state === 'broker'">
      </ngx-extended-pdf-viewer>
    </div>
  </div>
</ng-container>
<div *ngIf="!sharedResume.isActive">
  <p-card header="Det delade konsultCV:t är inte aktivt"></p-card>
</div>