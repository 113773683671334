import { Component, Input, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../services/user.service";
import {passwordMatchValidator} from "../../../validators/password-match.validator";
import {environment} from "../../../../environments/environment";
import { OrganizationChildListItem } from 'src/app/models/organization/organization.model';
import { OrganizationService } from 'src/app/services/organization.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal/error-modal.component';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styles: [
  ]
})
export class CreateUserComponent implements OnInit {

  processing: boolean;
  leavePageWarningEnabled: boolean = true;
  invalidForm: boolean;

  userForm: FormGroup;
  errors: string[];

  loading: boolean;

  user = this.authService.getUserFromStorage();
  isBroker : boolean;

  organizations: OrganizationChildListItem[];
  selectedOrganizationID: Number;
  selectedUserId: number;
  state: string;

  @Input() inputUserId: number;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private authService: AuthService,
              private organization: OrganizationService,
              private messageService: MessageService,
              private dialogService: DialogService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
this.state = 'create';
   this.selectedUserId = Number(this.route.snapshot.paramMap.get("userId"));

    if(Number(this.user.organizationTypeID) === 1){
      this.isBroker = true
    }
    this.userForm = this.formBuilder.group({
      organizationID: [this.user.organizationID],
      firstName: ["", [Validators.required, Validators.minLength(2)]],
      lastName: ["", [Validators.required, Validators.minLength(2)],],
      title: [""],
      email: ["", [
        Validators.required,
        Validators.pattern(environment.applicationSettings.validation.emailValidationRegex)]],
      password: ["", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(environment.applicationSettings.validation.passwordValidationRegex)]],
      passwordConfirm: ["", [
        Validators.required,
        Validators.minLength(2)],
        Validators.pattern(environment.applicationSettings.validation.passwordValidationRegex)],
      phoneNumber: [""],
      permissionID: [1], //TODO: keep the 1 (full permission) until there is a proper onboarding for new Organizations and admin functionality for permission levels
      isActivated:[true]
    }, {validator: passwordMatchValidator});

    if(this.selectedUserId != 0){
      this.state = 'edit';
      this.fetchUser(Number(this.selectedUserId));
    }
  }

  fetchUser(userId: number): void {
    this.userService.get(userId).subscribe((res: any) =>{
      this.userForm.get('firstName')?.setValue(res.firstName);
      this.userForm.get('lastName')?.setValue(res.lastName);
      this.userForm.get('title')?.setValue(res.title);
      this.userForm.get('email')?.setValue(res.email);
      this.userForm.get('phoneNumber')?.setValue(res.phoneNumber);
      this.userForm.get('isActivated')?.setValue(res.isActivated);
    })
  }

  onSelectedType(event: any){
    this.organization.getOrganizationsByType(event.target.value)
    .subscribe((response: any) => {
      this.organizations = response.organizations;
      this.organizations = sortAlphabetically(this.organizations, 'organizationName');
      this.selectedOrganizationID = this.organizations[0].organizationID;
      console.log(this.selectedOrganizationID)
    })
  }

  onChange(event: any): void {
    this.selectedOrganizationID = event.target.value;
  }

  onCreateClicked() {
    this.invalidForm = false;

    if(this.processing) {
      return;
    }

    this.processing = true;
    this.leavePageWarningEnabled = false;

    if(this.selectedUserId) {
      const updateUser = this.userForm.value;
      this.onSaveClicked(updateUser);
      return
    }

    if(this.userForm.invalid) {
      this.invalidForm = true;
      this.processing = false;
      return;
    }

    const { passwordConfirm, ...createUser } = this.userForm.value;

    if(this.isBroker){
      createUser.organizationID = this.selectedOrganizationID;
    }
    createUser.permissionID = 1;
    this.userService.create(createUser)
        .subscribe(user => {
          this.processing = false;
          this.showCreatedToast();
          this.router.navigate(['/organizations/users'])
          this.userForm.reset();
        }, error => {
          this.processing = false;
          this.leavePageWarningEnabled = true;
          let errorHeader = "Skapandet av användaren misslyckades";
          let errorMessage = "Vänligen kontrollera formuläret och försök igen";
          if (error == "Conflict") {
            errorHeader = "Email adressen finns redan";
            errorMessage = "Det finns redan en användare registrerad med den email-adressen";
          }
          this.dialogService.open(ErrorModalComponent, 
            {
              header: errorHeader,
              data: {
                message: errorMessage
              }
            })
        });  
  }

  onSaveClicked(updateUser: any) {
    updateUser.password = null;
    this.userService.update(this.selectedUserId, updateUser).subscribe(res => {
      this.processing = false;
      this.showUpdatedToast();
    },
    (error) => {
      this.processing = false;
      this.leavePageWarningEnabled = true;
      this.dialogService.open(ErrorModalComponent,
        {
          header: "Något gick fel",
          data: {
            message: "Misslyckades att uppdatera användaren. Vänligen försök igen."
          }
        })
    }
    )
  }

  onBackClicked() {
    if(this.processing) {
      return;
    }
    this.processing = true;
    this.router.navigate(['/users']);
    this.processing = false;
  }

  showCreatedToast() {
    this.messageService.add({
      key:'main',
      severity:'success',
      detail: 'Användaren har skapats'
    })
  }
  canDeactivate(confirmationMessage: string): boolean  {
    if(this.leavePageWarningEnabled)
    {
      if (this.userForm.dirty) {
        return window.confirm(confirmationMessage);
      }
    }
    return true;
  }

  showUpdatedToast() {
    this.messageService.add({
      key:'main',
      severity:'success',
      detail:'Användaren har uppdaterats'
    })
  }
}
