import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AssignmentChildren } from "src/app/models/assignment-children/assignment-children.model";

@Component({
  selector: "app-supplier-tender-info-modal",
  templateUrl: "./supplier-tender-info-modal.component.html",
  styles: [],
})
export class SupplierTenderInfoModalComponent implements OnInit {

  assignmentChild: AssignmentChildren;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.assignmentChild = this.config.data.assignmentChild;
  }
}
