<div class="p-grid p-justify-center">
    <div class="p-col-12 p-lg-12">
      <p-card header="Hantera CV">
        <p-toolbar styleClass="p-mb-4">
            <ng-template styleClass="p-mb-4" pTemplate="left">
                <button (click)="onCreateSharedResumeClicked()" pButton pTooltip="Skapa nytt CV"
                    tooltipPosition="top"  class="p-button-raised p-button p-text-uppercase"
                    label="Nytt CV"></button>
            </ng-template>
        </p-toolbar>
        <p-contextMenu #cm [model]="items"></p-contextMenu>
        <p-table [value]="sharedResumeList" [lazy]="true" (onLazyLoad)="loadBrokerCallOffs($event)"
        
        [(contextMenuSelection)]="selectedSharedResume" [contextMenu]="cm" dataKey="linkUrl"
        >
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 18%" pSortableColumn="header">Aktiv
                </th>
                <th style="width: 18%" pSortableColumn="header">Avropsnr
                </th>
                <th style="width: 18%" pSortableColumn="header">Anbudsref
                </th>
                <th style="width: 18%" pSortableColumn="header">Skyddad identitet
                </th>
                <th style="width: 18%" pSortableColumn="header">Konsultnamn
                </th>
                <th style="width: 18%" pSortableColumn="header">Konsultbolag
                </th>
                <th style="width: 18%" pSortableColumn="header">PUB
                </th>
                <th style="width: 18%" pSortableColumn="assignmentReference">Följer Kunds Policys
                </th>
                <th style="width: 18%" pSortableColumn="startDate">Meddelande till kund
                </th>
                <th style="width: 18%" pSortableColumn="customerName">Utgångsdatum
                </th>
                <th style="width: 18%" pSortableColumn="brokerUserName">Länk
                </th>
                <th style="width: 18%" pSortableColumn="brokerUserName">Action
                </th>
                <th style="width: 8rem" class="p-text-right"></th>
                <th></th>
            </tr>

        </ng-template>
        <ng-template pTemplate="body" let-sharedResume>
            <tr [pContextMenuRow]="sharedResume" >
                
                <td>
                    <div *ngIf="sharedResume.isActive">
                        <i class="pi pi-check-circle"></i>
                    </div>
                    <!-- {{sharedResume.isActive}} -->
                    <div *ngIf="!sharedResume.isActive">
                        <i class="pi pi-circle-off"></i>
                    </div>
                </td>
                <td>
                    {{sharedResume.callOffNumber}}
                </td>
                <td>
                    {{sharedResume.tenderReference}}
                </td>
                <td>
                    <div *ngIf="sharedResume.hasProtectedIdentity">
                        <i class="pi pi-check-circle"></i>
                    </div>
                    <!-- {{sharedResume.isActive}} -->
                    <div *ngIf="!sharedResume.hasProtectedIdentity">
                        <i class="pi pi-circle-off"></i>
                    </div>
                    <!-- {{sharedResume.hasProtectedIdentity}} -->
                </td>
                <td>
                    {{sharedResume.consultantName}}
                </td>
                <td>
                    {{sharedResume.supplierOrganizationName}}
                </td>
                <td>
                    <div *ngIf="sharedResume.isGDPR">
                        <i class="pi pi-check-circle"></i>
                    </div>
                    <!-- {{sharedResume.isActive}} -->
                    <div *ngIf="!sharedResume.isGDPR">
                        <i class="pi pi-circle-off"></i>
                    </div>
                    <!-- {{sharedResume.isGDPR}} -->
                </td>
                <td>
                    <div *ngIf="sharedResume.hasAcceptedPolicies">
                        <i class="pi pi-check-circle"></i>
                    </div>
                    <!-- {{sharedResume.isActive}} -->
                    <div *ngIf="!sharedResume.hasAcceptedPolicies">
                        <i class="pi pi-circle-off"></i>
                    </div>
                    <!-- {{sharedResume.hasAcceptedPolicies}} -->
                </td>
                <td [innerHTML]="sharedResume.messageToCustomer">
                   <!-- {{sharedResume.messageToCustomer}} -->
                </td>
                <td>
                    {{sharedResume.validUntil | date:'dd/MM/yyyy'}}
                </td>
                <td>
                    <!-- <div> -->
                        <!-- <p-contextMenu> -->
                            <a href="{{sharedResume.linkUrl}}"><p-contextMenu></p-contextMenu>länk till CV</a>
                        <!-- </p-contextMenu> -->
                    <!-- </div> -->
                </td>
                <td>
                    <div #myDiv></div><span>
                    <button
                      pButton
                      type="icon"
                      class="kebab-icon p-button-rounded p-button-text p-button-plain"
                      icon="pi pi-ellipsis-v"
                      (click)="menu.toggle($event); selectedSharedResume = sharedResume;"
                    ></button>
                
                    <p-menu styleClass="resume-kebab-menu" class="kebab-menu"#menu [popup]="true" [model]="kebabItems" [appendTo]="myDiv" 
                    
                    ></p-menu>
                  </span></td>
                <td>
                    <!-- <button
                        pButton
                        label="Redigera"
                        *ngIf="showAllCallOffs || usersOrganizationIsCustomer && brokerCallOff.statusID < maxCallOffStatus"
                        (click)="onEditCallOffClicked(sharedResume)"
                    ></button> -->
                </td>
            </tr>
        </ng-template>
    </p-table>
      </p-card>
    </div>
  </div>