import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { Contract, UpdateContract } from 'src/app/models/contract/contract.model';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { ContractService } from 'src/app/services/contract.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { calculateDiff } from 'src/app/utils/date';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contract-archive',
  templateUrl: './contract-archive.component.html',
  styles: [
  ]
})
export class ContractArchiveComponent implements OnInit {

  user: UserAuthenticateResponse
  token: string;
  tableSettings: any = environment.applicationSettings.table;
  organizationId: number;
  selectedOrganizationID: number;
  contracts: Contract[];
  selectedContract: Contract | any;
  items: MenuItem[];
  pageIndex: number = 1;  
  pageSize: number = this.tableSettings.defaultPageSize;
  pageTotal: number;
  loading: boolean;
  isBrokerUser: boolean = false;
  customers: any[];

  constructor(
    private contractService: ContractService,
    private authService: AuthService,
    private router: Router,
    private organizationService: OrganizationService
  ) { }

  ngOnInit(): void {
    const storedOrganizationID = localStorage.getItem('selectedCustomerOrganizationID');
    if (storedOrganizationID) {
      this.selectedOrganizationID = +storedOrganizationID;
    } else {
      this.selectedOrganizationID = 0;
    }
    this.token = this.authService.getTokenFromStorage();
    this.user = this.authService.getUserFromStorage();
    this.getAllContracts();
  }

  getAllContracts() {
     //TODO: If Customer = Use Own OrgID
    //TODO: If Broker = Select Customers OrgID from Drop Down
    //TODO: If Supplier = Create new Endpoint in backend
    if(Number(this.user.organizationTypeID) === OrganizationType.customer) {
      this.organizationId = Number(this.user.organizationID);
      this.contractService.getAllArchivedContracts(this.selectedOrganizationID).subscribe(res =>{
        this.contracts = res['contracts'];
        this.contracts.forEach(contract => {
          contract.contractExpiresInDays = calculateDiff(new Date(contract.endDate));
        })
      })
    }
   else if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
    this.isBrokerUser = true;
    
    this.organizationService.getOrganizationsByType(OrganizationType.customer).subscribe(
      (response: any) => {
        this.customers = response.organizations;
        this.customers = sortAlphabetically(this.customers, 'organizationName');
        this.selectedOrganizationID = this.selectedOrganizationID === 0 ? this.customers[0].organizationID : this.selectedOrganizationID;
        this.onChange();
      },
      (error) => {
      }
    );
   }
    
  }

  onChange() {
    localStorage.setItem('selectedCustomerOrganizationID', this.selectedOrganizationID.toString());
    this.contractService.getAllArchivedContracts(this.selectedOrganizationID).subscribe(res => {
      this.contracts = res['contracts'];
      this.contracts.forEach(contract => {
        contract.contractExpiresInDays = calculateDiff(new Date(contract.endDate));
      })
    })
  }

  loadContracts(event: LazyLoadEvent) {
    const {first, filters, sortField, sortOrder} = event;
    this.pageIndex = (<number>first / 10) +1;
  }

  onRestoreContractClicked(event: any, contract: Contract) {
    event.stopPropagation();
     var updateContract: UpdateContract  = {
      isActive : true
    };
    this.contractService.activateContract(contract.contractID, updateContract).subscribe(res =>{
      console.log(res);
      if(res) {
        this.onChange();
      }
    })
  }

  openContractPage(contract: Contract): void {
    this.router.navigate([`/contract/${contract.contractID}`]);
  }

  colourBackground(value: number): string {
    if(value < 29 && value >= 1)
    return 'red';
    else if(value > 29 && value < 60)
    return 'coral';
    else if(value > 59)
    return 'green';
    else return 'black';
    
  }

  colourText(value: number): string {
    if(value < 29 && value >= 1)
    return 'black';
    else if(value > 29 && value < 60)
    return 'black';
    else if(value > 59)
    return 'black';
    else return 'white';
    
  }

}
